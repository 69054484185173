import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import VPNServicesTableV20 from './vpnServicesTableV2.0';
import { vpnServicesFilterMenu, vpnServiceDataForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import VPNServiceAddV20 from './vpnServiceAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';

const SERVICE_NAME = openStackServices.networkService

const VPNServicesWrapperV20 = (props) => {
	const theme = useTheme()
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const { currentTab, setCurrentTab, serviceMenu, serviceSubmenu, changeMenuActiveTab } = props
	const [vpnServicesData, setVPNServicesData] = useState([])
	const [vpnServices, setVPNServices] = useState([])
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [vpnServiceDeleteConfirmDialogOpen, setVPNServiceDeleteConfirmDialogOpen] = useState(false);
	const [selectedVPNServices, setSelectedVPNServices] = useState([])
	
	const [vpnServiceFilterQueryParams, setVPNServiceFilterQueryParams] = useState("")
	const [selectedVPNServiceFilter, setSelectedVPNServiceFilter] = useState(vpnServicesFilterMenu[0].value)
	const [selectedVPNServiceFilterValue, setSelectedVPNServiceFilterValue] = useState("")
	const [routers, setRouters] = useState([]);
	const [subnets, setSubnets] = useState([]);

	const [updateDataOptions, setUpdateDataOptions] = useState({});
	const [updateFormData, setUpdateFormData] = useState({});
	const [vpnServiceUpdateDialogOpen, setVPNServiceUpdateDialogOpen] = useState(false)
	const [selectedFieldKey, setSelectedFieldKey] = useState("")
	const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const vpnServicesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnServicesUrl)[0].url)
	const routersUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleVPNServiceFilteredSearch = () => {
			if (selectedVPNServiceFilter && selectedVPNServiceFilterValue) {
					setVPNServiceFilterQueryParams(`${selectedVPNServiceFilter}=${selectedVPNServiceFilterValue}`)
			} else {
					setVPNServiceFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleVPNServicesDataFormatting = useCallback(() => {
			const formatted_data = vpnServicesData.map((item) => {
					let new_item = {...item}
					
					const router = routers.filter(r => r.id === item.router_id)
					if (router.length > 0) {
							new_item.router_id = router[0].name
					} else {
							new_item.router_id = "-"
					}
					return new_item
			})
			setVPNServices(formatted_data)
	},[
			vpnServicesData,
			routers
	])

	const handleVPNServiceFilterReset = () => {
			setSelectedVPNServiceFilter(vpnServicesFilterMenu[0].value)
			setSelectedVPNServiceFilterValue("")
			setVPNServiceFilterQueryParams("")
			handleDataFetch()
	}


	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFormDataChange = (event,field_key) => {
			setUpdateDataOptions({})
			let new_form_data = {...updateFormData}
			if (vpnServiceDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (vpnServiceDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUpdateFormData(new_form_data)
	}

	const getDataForm = () => {
			let form = vpnServiceDataForm.filter(i => i.field_key === selectedFieldKey)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {...updateDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									return (
											getFormFieldComponent(
													field,
													updateFormData,
													handleFormDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleUpdateDataValidation = (field_key, data) => {
			if (data[field_key].length === 0) {
					let options = {}
					options[field_key] = {}
					options[field_key]["error"] = true
					options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
					setUpdateDataOptions(options)
					return false
			} else {
					return true
			}
	}

	const handleVPNServiceUpdate = async (eg_id,field_key,data) => {
			const data_is_valid = handleUpdateDataValidation(field_key,data)
			let update_data = {}
			update_data[field_key] = data[field_key]
			if (data_is_valid) {
					handleVPNServiceUpdateDialogClose()
					
					const method = "PUT"
					const vpnGroup_response = await openstackRequest({
							url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}/${eg_id}`, 
							method: method, 
							data: {vpnservice: update_data},
							token,
					})
					if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
							return null
					} else {
							return vpnGroup_response.error
					}
					
			} 
	}

	const onVPNServiceUpdate = async () => {
			let err = []
			for (let n in selectedVPNServices) {
					const resp = await handleVPNServiceUpdate(
							selectedVPNServices[n].id,
							selectedFieldKey,
							updateFormData)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			setUpdateDataOptions({})
			setUpdateFormData({})
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onVPNServiceUpdateNameDialogOpen = useCallback((n_list) => {
			const selected_n_list = vpnServicesData.filter(n => 
					n_list.includes(n.id))
			setSelectedVPNServices([...selected_n_list])
			setSelectedFieldKey("name")
			setSelectedFieldTitleText(defaultTexts.updateVPNServiceNameTitle)
			setVPNServiceUpdateDialogOpen(true)
	},[vpnServicesData, defaultTexts])
	
	const onVPNServiceUpdateDescriptionDialogOpen = useCallback((n_list) => {
			const selected_n_list = vpnServicesData.filter(n => 
					n_list.includes(n.id))
					setSelectedVPNServices([...selected_n_list])
			setSelectedFieldKey("description")
			setSelectedFieldTitleText(defaultTexts.updateVPNServiceDescriptionTitle)
			setVPNServiceUpdateDialogOpen(true)
	},[vpnServicesData, defaultTexts])

	const onVPNServiceUpdateAdminUpDialogOpen = useCallback((n_list) => {
			const selected_n_list = vpnServicesData.filter(n => 
					n_list.includes(n.id))
					setSelectedVPNServices([...selected_n_list])
			setSelectedFieldKey("admin_state_up")
			setSelectedFieldTitleText(defaultTexts.updateVPNServiceAdminUpTitle)
			setVPNServiceUpdateDialogOpen(true)
	},[vpnServicesData, defaultTexts])

const handleVPNServiceUpdateDialogClose = () => {
			setVPNServiceUpdateDialogOpen(false)
	}

	const handleVPNServiceDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onVPNServiceDelete = async () => {
			handleVPNServiceDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedVPNServices) {
					const resp = await handleVPNServiceDelete(selectedVPNServices[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}


	const onVPNServiceDeleteConfirm = useCallback((n_list) => {
			const selected_n_list = vpnServicesData.filter(n => 
					n_list.includes(n.id))
			setSelectedVPNServices([...selected_n_list])
			setVPNServiceDeleteConfirmDialogOpen(true)
	},[vpnServicesData])

	const handleVPNServiceDeleteConfirmDialogClose = () => {
			setVPNServiceDeleteConfirmDialogOpen(false)
	}

	const getVPNServicesActionsList = useCallback(() => {
			let vpnService_actions = []
			let new_action = {}
			new_action["value"] = "vpn_service_update_name"
			new_action["action"] = onVPNServiceUpdateNameDialogOpen
			new_action["keyword"] = "vpnServiceUpdateNameActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			vpnService_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "vpn_service_update_description"
			new_action["action"] = onVPNServiceUpdateDescriptionDialogOpen
			new_action["keyword"] = "vpnServiceUpdateDescriptionActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			vpnService_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "vpn_service_update_admin_up"
			new_action["action"] = onVPNServiceUpdateAdminUpDialogOpen
			new_action["keyword"] = "vpnServiceUpdateAdminUpActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			vpnService_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "vpnService_delete"
			new_action["action"] = onVPNServiceDeleteConfirm
			new_action["keyword"] = "vpnServiceDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			vpnService_actions.push({...new_action})
			
			return vpnService_actions
	},[
			onVPNServiceDeleteConfirm,
			onVPNServiceUpdateNameDialogOpen,
			onVPNServiceUpdateDescriptionDialogOpen,
			onVPNServiceUpdateAdminUpDialogOpen
	])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}?${vpnServiceFilterQueryParams}`
							const method = "GET"
							const vpnService_response = await openstackRequest({url:url, method:method, token, })
							if (vpnService_response.status_code === vpnUrlResponses.get.success_response.status_code) {
								setVPNServicesData(vpnService_response.data.vpnservices)
							} else {
								setVPNServicesData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnServicesUrl, 
			vpnServiceFilterQueryParams,
			dataFetchingRequired,
			token
	]);


	useEffect(() => {
			(async () => {
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}`
				const method = "GET"
				const routers_response = await openstackRequest({url:url, method:method, token, })
				if (routers_response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setRouters(routers_response.data.routers)
				}
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			routersUrl, 
			token
	]);

	useEffect(() => {
			(async () => {

				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
				const method = "GET"
				const subnets_response = await openstackRequest({url:url, method:method, token, })
				if (subnets_response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setSubnets(subnets_response.data.subnets)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			subnetsUrl, 
			token
	]);

	useEffect(() => {
			if (vpnServicesData.length > 0) {
					handleVPNServicesDataFormatting()
			} else {
					setVPNServices([])
			}
	},[
			vpnServicesData,
			handleVPNServicesDataFormatting
	])

	useEffect(() => {
			if (Object.keys(updateFormData).length === 0) {
					let new_form_data = {}
					for (const n in vpnServiceDataForm) {
							if (
									vpnServiceDataForm[n].field_type === "string" || 
									vpnServiceDataForm[n].field_type === "select"
									) {
									new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
									vpnServiceDataForm[n].default_value : 
									""
							} else if (vpnServiceDataForm[n].field_type === "integer") {
									new_form_data[vpnServiceDataForm[n].field_key] = 3600
							} else if (vpnServiceDataForm[n].field_type === "bool") {
									new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
									vpnServiceDataForm[n].default_value : 
									false
							}
					}
					setUpdateFormData(new_form_data)
			}
	},[
			updateFormData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-vpnaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getVPNServicesActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedVPNServices([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getVPNServicesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<VPNServiceAddV20
					handleDataFetch={handleDataFetch}
					routers={routers}
					subnets={subnets}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						label={defaultTexts[vpnServicesFilterMenu[0].keyword]}
						currentValue={selectedVPNServiceFilterValue}
						setCurrentValue={e => setSelectedVPNServiceFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleVPNServiceFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleVPNServiceFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>
				
				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-vpnaas'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={vpnServicesData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}
				
				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.vpnServicesTabButtonText} ({selectedIds.length}/{vpnServicesData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<VPNServicesTableV20 
				vpnServicesData={vpnServices}
				setVPNServicesData={setVPNServices}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getVPNServicesActionsList()}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	return (
		<>
			{ isLoading && <CustomBackdrop open={isLoading} /> }

			{ getNetworksHeader() }

			{ !isLoading && getNetworksTable() }
			
			<CustomDialog
					open={vpnServiceDeleteConfirmDialogOpen}
					onClose={handleVPNServiceDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.vpnServiceDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.vpnServiceDeleteConfirmText}`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onVPNServiceDelete, 
							sx: {color: 'primary.main'}}]}
			/>
			<CustomDialog
					open={vpnServiceUpdateDialogOpen}
					onClose={handleVPNServiceUpdateDialogClose}
					dialogTitle={{
							title: selectedFieldTitleText, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVPNServiceUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm()}
			</CustomDialog>
			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
}

export default VPNServicesWrapperV20