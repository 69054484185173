import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SecurityGroupsWrapperV20 from './security_groups/securityGroupsWrapperV2.0';
import AddressGroupsWrapperV20 from './address_groups/addressGroupsWrapperV2.0';

const L3NetworkingWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, selectedSubmenu } = props
    
    const getSelectedSubmenu = () => {
		const submenu = ['security_groups', 'address_groups']
		return submenu.includes(selectedSubmenu) ? selectedSubmenu : submenu[0]
	}

	const [currentTab, setCurrentTab] = useState(getSelectedSubmenu())

	return (
		<Box>
				{currentTab === "security_groups" && 
						<SecurityGroupsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "address_groups" && 
						<AddressGroupsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default L3NetworkingWrapperV20