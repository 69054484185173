import React, { useState } from 'react';
import Box from '@mui/material/Box';
import NetworksWrapperV20 from './networks/networksWrapperV2.0';
import PortsWrapperV20 from './ports/portsWrapperV2.0';
import SegmentsWrapperV20 from './segments/segmentsWrapperV2.0';
import TrunksWrapperV20 from './trunks/trunksWrapperV2.0';

const L2NetworkingWrapperV20 = (props) => {
	const { navigate, location, serviceMenu, serviceSubmenu, selectedSubmenu, changeMenuActiveTab, segmentsSupported, trunksSupported } = props

	const getSelectedSubmenu = () => {
		const submenu = ['networks', 'ports', 'segments', 'trunks']
		return submenu.includes(selectedSubmenu) ? selectedSubmenu : submenu[0]
	}

	const [currentTab, setCurrentTab] = useState(getSelectedSubmenu())
	
	return (
		<Box>
			{currentTab === "networks" && 
					<NetworksWrapperV20 
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{currentTab === "ports" && 
					<PortsWrapperV20
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{currentTab === "segments" && segmentsSupported &&
					<SegmentsWrapperV20
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{currentTab === "trunks" && trunksSupported &&
					<TrunksWrapperV20 
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
		</Box>
	)
}

export default L2NetworkingWrapperV20