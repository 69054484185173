import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomDialog from '../../../../_common/CustomDialog';
import { FormHelperText, Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import Box from '@mui/material/Box';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';
import { projectsUrl as projectsUrlResponses} from '../../../../../_api_responses/openstack/identity/projects/v3';
import { flavorsUrl as flavorsUrlResponses } from '../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import { snapshotsUrl as snapshotUrlResponses } from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import { networksUrl as networkUrlResponses } from '../../../../../_api_responses/openstack/neutron/networks/v2.0';
import Chip from '@mui/material/Chip';
import { openstackRequest, computeNovaRequest, volumeCinderRequest } from '../../../../../_network/openstack_request';
import {
    openStackServices,
    computeNovaConstants,
    imagesGlanceConstants,
    blockStorageCinderConstants,
    networkNeutronConstants,
} from '../../../../../config/openStackConstants';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTransferList from '../../../../_common/custom_transfer_list/CustomTransferList';
import useWindowDimensions from '../../../../_common/WindowDimensions';

const SERVICE_NAME = openStackServices.computeService
const IMAGE_SERVICE_NAME = openStackServices.imageService
const VOLUME_SERVICE_NAME = openStackServices.volumeService
const NETWORK_SERVICE_NAME = openStackServices.networkService
const flavors_default_url_query = "?is_public=none"
const MAX_ALLOWEDDATA_SIZE = 65535
const RESOURCE_NAME = "OS::Nova::Server"

const sideBoxStyle = { 
	m: 1, 
	borderRadius: 1, 
	width: "100%", 
	border: 0.5, 
	borderColor: "darkGray",
	padding: '10px',
	boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
	position: 'relative',
	transition: 'all 0.3s',
	zIndex: '101',
}

const sectionTitleStyle = {
    color: "customBlue",
    fontSize: 18,
    fontWeight: "600"
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const ComputeServerLunchDialogV21 = (props) => {
	const theme = useTheme()
	const { width, height } = useWindowDimensions()
	const { open, handleClose, handleDataFetch, handleServerStatusChange } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const token = useSelector(state => state.profile.x_auth_token)
	const mode = useSelector(state => state.settings.uiMode)
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [imageOptions, setImageOptions] = useState([]);
	const [snapshotOptions, setSnapshotOptions] = useState([]);
	const [volumeOptions, setVolumeOptions] = useState([]);
	const [volumeSnapshotOptions, setVolumeSnapshotOptions] = useState([]);
	const [flavors, setFlavors] = useState([])
	const [flavorOptions, setFlavorOptions] = useState([])
	// const [serverGroups, setServerGroups] = useState([])
	const [images, setImages] = useState([]);
	const [volumes, setVolumes] = useState([]);
	const [volumeSnapshots, setVolumeSnapshots] = useState([]);
	const [volumeTypes, setVolumeTypes] = useState([]);
	const [networks, setNetworks] = useState([]);
	const [subnets, setSubnets] = useState([]);
	const [ports, setPorts] = useState([]);
	const [securityGroups, setSecurityGroups] = useState([]);
	const [keypairs, setKeypairs] = useState([]);
	const [availabilityZones, setAvailabilityZones] = useState([]);
	const [selectedName, setSelectedName] = useState("");
	const [selectedServerCount, setSelectedServerCount] = useState(1);
	const [selectedBootSource, setSelectedBootSource] = useState("image");
	const [selectedImage, setSelectedImage] = useState(null)
	const [selectedVolume, setSelectedVolume] = useState(null)
	const [selectedVolumeSnapshot, setSelectedVolumeSnapshot] = useState(null);
	const [selectedInstanceSnapshot, setSelectedInstanceSnapshot] = useState(null);
	const [selectedFlavor, setSelectedFlavor] = useState(null)
	const [selectedNetworks, setSelectedNetworks] = useState([]);
	const [selectedPorts, setSelectedPorts] = useState([]);
	const [selectedSecurityGroups, setSelectedSecurityGroups] = useState([]);
	const [selectedKeypair, setSelectedKeypair] = useState("")
	const [selectedAvailabilityZone, setSelectedAvailabilityZone] = useState("")
	const [selectedDescription, setSelectedDescription] = useState("");
	const [selectedUserData, setSelectedUserData] = useState("");
	const [userDataSize, setUserDataSize] = useState(0)
	const [selectedDiskPartition, setSelectedDiskPartition] = useState("AUTO")
	// const [selectedServerGroup, setSelectedServerGroup] = useState("");
	const [createNewVolume, setCreateNewVolume] = useState(true);
	const [createNewSecGroup, setCreateNewSecGroup] = useState(false);
	const [searchedImage, setSearchedImage] = useState("");
	const [searchedSnapshot, setSearchedSnapshot] = useState("");
	const [searchedVolume, setSearchedVolume] = useState("");
	const [searchedVolumeSnapshot, setSearchedVolumeSnapshot] = useState("");
	const [searchedFlavor, setSearchedFlavor] = useState("");
	const [newSystemVolume, setNewSystemVolume] = useState({})
	const [newSecurityGroup, setNewSecurityGroup] = useState({})
	const [selectedVolumeData, setSelectedVolumeData] = useState({})
	const [selectedVolumeSnapshotData, setSelectedVolumeSnapshotData] = useState({})
	const [moreVolumes, setMoreVolumes] = useState([]);
	const [networkAllocationType, setNetworkAllocationType] = useState("auto")
	const [networkOptions, setNetworkOptions] = useState([])
	const [portOptions, setPortOptions] = useState([])
	const [securityGroupOptions, setSecurityGroupOptions] = useState([])
	const [newPublicKey, setNewPublicKey] = useState({})
	const [createNewKeypair, setCreateNewKeypair] = useState(true);
	const [newKeypairOptions, setNewKeypairOptions] = useState({})
	const [keypairFetchRequired, setKeypairFetchRequired] = useState(true)
	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
	const [adminPassword, setAdminPassword] = useState("")
	const [adminPasswordConfirm, setAdminPasswordConfirm] = useState("")
	const [showPassword, setShowPassword] = useState(false)
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
	const [adminPasswordError, setAdminPasswordError] = useState(false)
	const [adminPasswordConfirmError, setAdminPasswordConfirmError] = useState(false)
	const [adminPasswordRequired, setAdminPasswordRequired] = useState(false)
	const [metadataNamespaces, setMetadataNamespaces] = useState([])
	const [metadataCatalog, setMetadataCatalog] = useState([])
	const [serverMetadataDialogOpen, setServerMetadataDialogOpen] = useState(false);
	const [updatedMetadata, setUpdatedMetadata] = useState({})
	const [selectedMetadata, setSelectedMetadata] = useState({})
	const [selectedNameError, setSelectedNameError] = useState(false);
	const [selectedImageError, setSelectedImageError] = useState(false);
	const [selectedInstanceSnapshotError, setSelectedInstanceSnapshotError] = useState(false);
	const [selectedVolumeError, setSelectedVolumeError] = useState(false);
	const [selectedVolumeSnapshotError, setSelectedVolumeSnapshotError] = useState(false);
	const [selectedFlavorError, setSelectedFlavorError] = useState(false)
	const [newSecurityGroupOptions, setNewSecurityGroupOptions] = useState({})
	const [selectedNetworksError, setSelectedNetworksError] = useState(false)

	const [serverLimits, setServerLimits] = useState({})
	const [volumeLimits, setVolumeLimits] = useState({})
	const [selectedCpus, setSelectedCpus] = useState(0)
	const [selectedRam, setSelectedRam] = useState(0)
	const [selectedVolumeCount, setSelectedVolumeCount] = useState(0)
	const [selectedVolumeCapacity, setSelectedVolumeCapacity] = useState(0)
	const [limitsError, setLimitsError] = useState(false)
	const [quotasExpanded, setQuotasExpanded] = useState(width >=900 ? true : false)
	const [summaryExpanded, setSummaryExpanded] = useState(width >=900 ? true : false)
	const [summaryWidth, setSummaryWidth] = useState(360)
	const [summaryHeight, setSummaryHeight] = useState(400)

	const summaryLabelStyle = {
		fontSize: '16px',
		textAlign: 'center',
		fontWeight: '600',
		cursor: 'pointer',
	}

	const summaryCategoryLabelStyle = {
		fontSize: '15px',
		fontWeight: '600',
		marginTop: '20px',
		paddingBottom: '3px',
	}

	const summaryItemStyle = {
		marginTop: '10px',
		paddingBottom: '3px',
		fontSize: '14px',
	}

	const summaryValueStyle = {
		paddingLeft: '10px',
		textAlign: 'right',
		wordWrap: 'break-word',
		wordBreak: 'break-all',
	}

	const quotasItemStyle = {
		marginTop: '10px',
		paddingBottom: '5px',
		fontSize: '14px',
	}

	const quotasGraphBlockStyle = {
    height: '12px',
    background: theme.palette.customGray,
		borderRadius: '4px',
  }

	const quotasGraphUsedStyle = {
		float: 'left',
    height: '12px',
    background: theme.palette.primary.main,
		borderRadius: '4px',
		marginRight: '-4px',
  }

	const quotasGraphSelectedStyle = {
		float: 'left',
    height: '12px',
    background: theme.palette.success.main,
		borderRadius: '4px',
  }

	const expandButtonStyle = {
		position: 'absolute',
		top: '1px',
		right: '1px',
	}

	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	// const serverGroupsUrl = useSelector(
	// 		state => state.computeNova.computeNovaApiUrls.filter(
	// 				version => version.api_version === "v2.1")[0].urls.filter(
	// 						url => url.keyword === computeNovaConstants.serverGroupsUrl)[0].url)
	const flavorsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
	const keypairsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)
	const imageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
	const imageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)
	const imagesUrl = useSelector(
			state => state.imageGlance.imageGlanceApiUrls.filter(
					version => version.api_version === "v2")[0].urls.filter(
							url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)
	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === VOLUME_SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === VOLUME_SERVICE_NAME)[0].config_params.api_version)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	const volumeTypesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
	const snapshotsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
	const portsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
	const securityGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)
	const availabilityZonesUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.availabilityZonesUrl)[0].url)
	const computeLimitsUrl = useSelector(
		state => state.computeNova.computeNovaApiUrls.filter(
				version => version.api_version === "v2.1")[0].urls.filter(
						url => url.keyword === computeNovaConstants.limitsUrl)[0].url)
	const cinderLimitsUrl = useSelector(
		state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
				version => version.api_version === "v3")[0].urls.filter(
						url => url.keyword === blockStorageCinderConstants.limitsUrl)[0].url)

	const bootSourceList = [
			{
					keyword: "imageBootSourceFormFieldLabel",
					value: "image",
					default: true
			},
			{
					keyword: "instanceSnapshotBootSourceFormFieldLabel",
					value: "instance_snapshot",
					default: false
			},
			{
					keyword: "volumeBootSourceFormFieldLabel",
					value: "volume",
					default: false
			},
			{
					keyword: "volumeSnapshotBootSourceFormFieldLabel",
					value: "volume_snapshot",
					default: false
			}
	]

	const ipAllocationOptions = [
			{
					keyword: "autoIPAllocationOptionLabel",
					value: "auto",
					default: true
			},
			{
					keyword: "manualIPAllocationOptionLabel",
					value: "manual",
					default: false
			}
	]

	const diskPartitions = [
			{
					keyword: "automaticDiskPartitionOptionLabel",
					value: "AUTO",
					default: true
			},
			{
					keyword: "manualDiskPartitionOptionLabel",
					value: "MANUAL",
					default: false
			}
	]

	const getKeywordFromValue = (val, list) => {
		const findEl = list.find(item => item.value === val)
		return findEl ? defaultTexts[findEl.keyword] : ''
	}

	const getLabelFromValue = (val, list) => {
		const findEl = list.find(item => item.value === val)
		return findEl ? findEl.keyword : ''
	}
	
	const handleClick = () => {
			handleClose();
	};

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleServerMetadataDialogOpen = () => {
			setServerMetadataDialogOpen(true)
	}

	const handleServerMetadataSelectionDialogClose = () => {
			setUpdatedMetadata([])
			setServerMetadataDialogOpen(false)
	}

	const onServerMetadataUpdate = () => {
			handleServerMetadataSelectionDialogClose()
			setSelectedMetadata({...updatedMetadata})
	}

	const handleClickShowPassword = () => {
			setShowPassword(!showPassword)
	};
	
	const handleMouseDownPassword = (event) => {
			event.preventDefault();
	};

	const handleClickShowPasswordConfirm = () => {
			setShowPasswordConfirm(!showPasswordConfirm)
	};
	
	const handleMouseDownPasswordConfirm = (event) => {
			event.preventDefault();
	};

	const handleAdminPasswordChange = (event) => {
			setAdminPasswordError(false)
			setAdminPassword(event.target.value)
	}

	const handleAdminPasswordConfirmChange = (event) => {
			setAdminPasswordConfirmError(false)
			setAdminPasswordConfirm(event.target.value)
	}

	const handlePublicKeyUpload = async (event) => {
			setNewKeypairOptions({})
			const content = await event.target.files[0].text()
			let update_data = {...newPublicKey}
			update_data.public_key = content
			setNewPublicKey(update_data)
	}

	const handleFlavorChange = (newValue) => {
			setSelectedFlavorError(false)
			setSelectedFlavor(newValue)
	}

	const handleSelectedImageChange = (newValue) => {
			setSelectedImageError(false)
			setSelectedImage(newValue)
	}

	const handleSelectedInstanceSnapshotChange = (newValue) => {
			setSelectedInstanceSnapshotError(false)
			setSelectedInstanceSnapshot(newValue)
	}

	const handleSelectedVolumeChange = (newValue) => {
			setSelectedVolumeError(false)
			setSelectedVolume(newValue)
	}

	const handleSelectedVolumeSnapshotChange = (newValue) => {
			setSelectedVolumeSnapshotError(false)
			setSelectedVolumeSnapshot(newValue)
	}

	const handleUserDataUpload = async (event) => {
			const size = event.target.files[0].size
			setUserDataSize(size)
			const content = await event.target.files[0].text()
			setSelectedUserData(content)
	}

	const handlePublicKeyDataChange = (event,field_key) => {
			setNewKeypairOptions({})
			let update_data = {...newPublicKey}
			update_data[field_key] = event.target.value
			setNewPublicKey(update_data)
	}

	const handleServerCreate = async () => {
			let is_valid = true

			if (limitsError) {
				is_valid = false
			}

			if (selectedName.length === 0) {
					is_valid = false
					setSelectedNameError(true)
			}
			
			if (selectedBootSource === "image" && !selectedImage) {
					is_valid = false
					setSelectedImageError(true)
			}

			if (selectedBootSource === "volume" && !selectedVolume) {
					is_valid = false
					setSelectedVolumeError(true)
			}

			if (selectedBootSource === "instance_snapshot" && !selectedInstanceSnapshot) {
					is_valid = false
					setSelectedInstanceSnapshotError(true)
			}

			if (selectedBootSource === "volume_snapshot" && !selectedVolumeSnapshot) {
					is_valid = false
					setSelectedVolumeSnapshotError(true)
			}

			if (!selectedFlavor) {
					is_valid = false
					setSelectedFlavorError(true)
			}

			//if (createNewSecGroup && newSecurityGroup.name.length === 0) {
			//    is_valid = false
			//    let updated_options = {...newSecurityGroupOptions}
			//    updated_options["name"] = {}
			//    updated_options["name"]["error"] = true
			//    setNewSecurityGroupOptions(updated_options)
			//}

			if (adminPasswordRequired && (adminPassword.length === 0 || adminPasswordConfirm.length === 0)) {
					is_valid = false

					if (adminPassword.length === 0) {
							setAdminPasswordError(true)
					}

					if (adminPasswordConfirm.length === 0) {
							setAdminPasswordConfirmError(true)
					}
			}

			if (networkAllocationType === "manual" && selectedNetworks.length === 0 && selectedPorts.length === 0) {
					is_valid = false
					setSelectedNetworksError(true)
			}

			if (networkAllocationType === "manual" && selectedNetworks.length > 0) {
					let updated_data = [...selectedNetworks]
					for (let net in selectedNetworks) {
							if (selectedNetworks[net].uuid.length === 0) {
									is_valid = false
									updated_data[net]["error"] = true
							}
					}
					setSelectedNetworks(updated_data)
			}

			if (createNewKeypair && 
					(newPublicKey.name.length === 0 || 
							newPublicKey.public_key.length === 0)) {
					is_valid = false
					let updated_options = {...newKeypairOptions}
					if (newPublicKey.name.length === 0) {
							updated_options["name"] = {}
							updated_options["name"]["error"] = true
					}
					if (newPublicKey.public_key.length === 0) {
							updated_options["public_key"] = {}
							updated_options["public_key"]["error"] = true
					}
					setNewKeypairOptions(updated_options)
			}

			if (adminPasswordRequired && adminPassword !== adminPasswordConfirm) {
					is_valid = false
					setAdminPasswordError(true)
					setAdminPasswordConfirmError(true)
			}

			if (!is_valid) {
					return false
			}

			let data = {}
			data["name"] = selectedName
			data["min_count"] = selectedServerCount
			data["flavorRef"] = selectedFlavor.id
			data["block_device_mapping_v2"] = []

			if (selectedBootSource === "image") {
					if (createNewVolume) {
							let new_system_disk = {}
							new_system_disk["uuid"] = selectedImage.id
							new_system_disk["source_type"] = "image"
							new_system_disk["boot_index"] = 0
							new_system_disk["destination_type"] = "volume"
							new_system_disk["volume_size"] = newSystemVolume.size
							new_system_disk["volume_type"] = newSystemVolume.volume_type
							new_system_disk["delete_on_termination"] = newSystemVolume.delete_on_terminate
							data["block_device_mapping_v2"]= [...data["block_device_mapping_v2"], new_system_disk]
					} else {
							data["imageRef"] = selectedImage.id
					}
			} else if (selectedBootSource === "instance_snapshot") {
					const snapshot = images.filter(im => im.id === selectedInstanceSnapshot.id)[0]
					const mapping_list = JSON.parse(snapshot.block_device_mapping)
					let mapping = []
					for (let i in mapping_list) {
							const fields = Object.keys(mapping_list[i]).filter(f => mapping_list[i][f] !== null)
							let new_item = {}
							for (let f in fields) {
									if (mapping_list[i][fields[f]] !== null) {
											if (fields[f] === "snapshot_id") {
													new_item["uuid"] = mapping_list[i][fields[f]]
											} else {
													new_item[fields[f]] = mapping_list[i][fields[f]]
											}
											
									}
							}
							mapping = [...mapping, new_item]
					}
					data["block_device_mapping_v2"] = [...data["block_device_mapping_v2"],...mapping]
			} else if (selectedBootSource === "volume") {
					let new_volume_disk = {}
					new_volume_disk["uuid"] = selectedVolume.id
					new_volume_disk["boot_index"] = 0
					new_volume_disk["source_type"] = "volume"
					new_volume_disk["destination_type"] = "volume"
					new_volume_disk["delete_on_termination"] = selectedVolumeData.delete_on_terminate
					data["block_device_mapping_v2"]= [...data["block_device_mapping_v2"], new_volume_disk]
			} else if (selectedBootSource === "volume_snapshot") {
					let new_volume_snap_disk = {}
					new_volume_snap_disk["uuid"] = selectedVolumeSnapshot.id
					new_volume_snap_disk["boot_index"] = 0
					new_volume_snap_disk["source_type"] = "snapshot"
					new_volume_snap_disk["destination_type"] = "volume"
					new_volume_snap_disk["delete_on_termination"] = selectedVolumeSnapshotData.delete_on_terminate
					data["block_device_mapping_v2"] = [...data["block_device_mapping_v2"], new_volume_snap_disk]
			}

			if (moreVolumes.length > 0) {
					for (let i in moreVolumes) {
							let new_item = {}
							new_item["volume_size"] = moreVolumes[i].size
							new_item["volume_type"] = moreVolumes[i].volume_type
							new_item["delete_on_termination"] = moreVolumes[i].delete_on_termination
							new_item["destination_type"] = "volume"
							new_item["source_type"] = "blank"
							if (i === "0" && data["block_device_mapping_v2"].length === 0) {
									new_item["boot_index"] = 0
							}
							data["block_device_mapping_v2"] = [...data["block_device_mapping_v2"], new_item]
					}
			}

			if (data["block_device_mapping_v2"].length === 0) {
					delete data["block_device_mapping_v2"]
			}

			if (networkAllocationType === "auto") {
					data["networks"] = "auto"
			} else if (networkAllocationType === "none") {
					data["networks"] = "none"
			} else {
					data["networks"] = []
					for (let n in selectedNetworks) {
							let new_net = {}
							new_net["uuid"] = selectedNetworks[n].uuid
							if (selectedNetworks[n].fixed_ip) {
									new_net["fixed_ip"] = selectedNetworks[n].fixed_ip
							}
							data["networks"] = [...data["networks"], new_net]
					}
					if (selectedPorts.length > 0) {
							for (let p in selectedPorts) {
									let new_item = {}
									new_item["port"] = selectedPorts[p]
									data["networks"] = [...data["networks"], new_item]
							}
					}
			}

			data["security_groups"] = selectedSecurityGroups.map(item => {
					return {name: item}
			})

			if (selectedKeypair.length > 0) {
					data["key_name"] = selectedKeypair
			}

			if (adminPasswordRequired) {
					data["adminPass"] = adminPassword
			}

			if (selectedAvailabilityZone.length > 0) {
					data["availability_zone"] = selectedAvailabilityZone
			}

			data["description"] = selectedDescription
			data["OS-DCF:diskConfig"] = selectedDiskPartition

			if (selectedUserData.length > 0) {
					data["user_data"] = btoa(selectedUserData)
			}

			if (Object.keys(selectedMetadata).length > 0) {
					data["metadata"] = selectedMetadata
			}
			let send_data = {server: data}
			
			// if (selectedServerGroup.length > 0) {
			// 		send_data["OS-SCH-HNT:scheduler_hints"] = {}
			// 		send_data["OS-SCH-HNT:scheduler_hints"]["group"] = selectedServerGroup
			// }

			const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}`
			const method = "POST"

			const server_response = await computeNovaRequest({
					url:url, 
					method:method, 
					data: send_data, 
					token,
			})

			if (server_response.status_code < 400) {
					handleServerStatusChange("ACTIVE", server_response.data.server.id)
					handleDataFetch()
					handleClick()
			} else {
					setError({
							error_title: "",
							error_message: "",
							error_details: server_response.error})
					return false
			}        

	}

	const handleCreateNewKeypair = async () => {
			let is_valid = true
			let updated_options = {...newKeypairOptions}
			if (newPublicKey.name.length === 0) {
					is_valid = false
					updated_options["name"] = {}
					updated_options["name"]["error"] = true
			}

			if (newPublicKey.public_key.length === 0) {
					is_valid = false
					updated_options["public_key"] = {}
					updated_options["public_key"]["error"] = true
			}

			if (!is_valid) {
					setNewKeypairOptions(updated_options)
					return false
			}

			let data = {}
			data["keypair"] = {...newPublicKey}

			const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
			const method = "POST"

			const keypairs_response = await computeNovaRequest({url:url, method:method, data: data, token, })
			if (keypairs_response.status_code < 400) {
					setSelectedKeypair(newPublicKey.name)
					setKeypairFetchRequired(true)
					setCreateNewKeypair(false)
					setNewPublicKey({})
			} else {
					setError({
							error_title: "",
							error_message: "",
							error_details: keypairs_response.error})
					return false
			}			
	}

	const handleMoreVolumesChange = (event,field_key,index) => {
			let new_form_data = [...moreVolumes]
			if (field_key === "delete_on_termination") {
					new_form_data[index][field_key] = event.target.checked
			} else if (field_key === "volume_type") {
					new_form_data[index][field_key] = event.trim()
			} else {
					new_form_data[index][field_key] = event.target.value.trim()
			}
			setMoreVolumes(new_form_data)
	}

	const handleSelectedNetworksChange = (event,field_key,index) => {
			let new_form_data = [...selectedNetworks]
			let new_item = {...new_form_data[index]}
			if (field_key === "uuid" || field_key === "ip_allocation" || field_key === "subnet_id") {
					new_item[field_key] = event.trim()
			} else {
					new_item[field_key] = event.target.value.trim()
			}
			delete new_item["error"]
			new_form_data[index] = new_item
			setSelectedNetworks(new_form_data)
	}

	const handleMoreVolumesAdd = () => {
			let new_form_data = {}
			new_form_data["volume_type"] = ""
			new_form_data["size"] = 10
			new_form_data["delete_on_termination"] = true
			const updated_data = [...moreVolumes]
			updated_data.push(new_form_data)
			setMoreVolumes(updated_data)
	}

	const handleMoreVolumesRemove = () => {
			let new_data = [...moreVolumes]
			new_data.pop()
			setMoreVolumes(new_data)
	}

	const handleSelectedNetworksAdd = () => {
			setSelectedNetworksError(false)
			let new_form_data = {}
			new_form_data["uuid"] = ""
			new_form_data["ip_allocation"] = "auto"
			new_form_data["fixed_ip"] = ""
			const updated_data = [...selectedNetworks]
			updated_data.push(new_form_data)
			setSelectedNetworks(updated_data)
	}

	const handleSelectedNetworksRemove = () => {
			let new_data = [...selectedNetworks]
			new_data.pop()
			setSelectedNetworks(new_data)
	}

	const handleSelectNewVolumeCreate = (choice) => {
			setCreateNewVolume(choice)
	}

	const handleSelectNewSecGroupCreate = (choice) => {
			setCreateNewSecGroup(choice)
	}

	const handleSelectNewKeypairCreate = (choice) => {
			setCreateNewKeypair(choice)
	}

	const handleNewSystemVolumeChange = (event,field_key) => {
			let new_data = {...newSystemVolume}
			if (field_key === "size") {
					new_data[field_key] = event.target.value
			} else if (field_key === "type") {
					new_data[field_key] = event
			} else if (field_key === "delete_on_terminate") {
					new_data[field_key] = event.target.checked
			}
			
			setNewSystemVolume(new_data)
	}

	const handleNewSecurityGroupChange = (event,field_key) => {
			setNewSecurityGroupOptions({})
			let new_data = {...newSecurityGroup}
			if (field_key === "name") {
					new_data[field_key] = event.target.value
			} else if (field_key === "allow_ssh" ||
					field_key === "allow_http" ||
					field_key === "allow_https"
			) {
					new_data[field_key] = event.target.checked
			}
			
			setNewSecurityGroup(new_data)
	}

	const handleSelectedVolumeDataChange = (event, field_key) => {
			let new_data = {...selectedVolumeData}
			if (field_key === "delete_on_termination") {
					new_data["delete_on_termination"] = event.target.checked
					setSelectedVolumeData(new_data)
			}
	}

	const handleSelectedVolumeSnapshotDataChange = (event, field_key) => {
			let new_data = {...selectedVolumeSnapshotData}
			if (field_key === "delete_on_termination") {
					new_data["delete_on_termination"] = event.target.checked
					setSelectedVolumeSnapshotData(new_data)
			}
	}

	const handleSelectedNameChange = (event) => {
			setSelectedNameError(false)
			setSelectedName(event.target.value)
	}

	const handleSelectedServerCountChange = (event) => {
			setSelectedServerCount(event.target.value)
	}

	const handleOptionCheck = (option,value) => {  
			return option.id === value.id
	}


	useEffect(() => {
			if (Object.keys(newSystemVolume).length === 0) {
					let new_data = {}
					new_data["type"] = ""
					new_data["size"] = 10
					new_data["delete_on_terminate"] = true

					setNewSystemVolume(new_data)
			} else if (selectedImage) {
					const selected_image = images.filter(i => i.id === selectedImage.id)
					let new_data = {...newSystemVolume}
					if (selected_image[0].min_disk > newSystemVolume.size) {
							new_data.size = selected_image[0].min_disk
							setNewSystemVolume(new_data)
					}
			}
	},[
			newSystemVolume,
			selectedImage,
			images

	]);

	useEffect(() => {
			if (Object.keys(newSecurityGroup).length === 0) {
					let new_data = {}
					new_data["name"] = selectedName ? `${selectedName}_security_group` : ""
					new_data["allow_ssh"] = true
					new_data["allow_http"] = false
					new_data["allow_https"] = false

					setNewSecurityGroup(new_data)
			}
	},[
			newSecurityGroup,
			selectedName
	]);

	useEffect(() => {
			if (Object.keys(newPublicKey).length === 0) {
					let new_data = {}
					new_data["name"] = ""
					new_data["public_key"] = ""

					setNewPublicKey(new_data)
			}
	},[
			newPublicKey
	]);

	useEffect(() => {
			if (Object.keys(selectedVolumeData).length === 0) {
					let new_data = {}
					new_data["type"] = ""
					new_data["size"] = 0
					new_data["delete_on_terminate"] = true

					setSelectedVolumeData(new_data)
			} else if (selectedVolume) {
					const selected_volume = volumes.filter(i => i.id === selectedVolume.id)
					if (selected_volume[0].volume_type !== selectedVolumeData.volume_type || 
							selected_volume[0].size !== selectedVolumeData.size
					) {
							let new_data = {...selectedVolumeData}
							new_data.size = selected_volume[0].size
							new_data.volume_type = selected_volume[0].volume_type
							setSelectedVolumeData(new_data)
					}
			}
	},[
			selectedVolume,
			selectedVolumeData,
			volumes

	]);

	useEffect(() => {
			if (Object.keys(selectedVolumeSnapshotData).length === 0) {
					let new_data = {}
					new_data["size"] = 0
					new_data["delete_on_terminate"] = true

					setSelectedVolumeSnapshotData(new_data)
			} else if (selectedVolumeSnapshot) {
					const selected_vol_snap = volumeSnapshots.filter(i => i.id === selectedVolumeSnapshot.id)
					if (selected_vol_snap[0].size !== selectedVolumeSnapshotData.size) {
							let new_data = {...selectedVolumeSnapshotData}
							new_data.size = selected_vol_snap[0].size
							setSelectedVolumeSnapshotData(new_data)
					}
			}
	},[
			selectedVolumeSnapshot,
			selectedVolumeSnapshotData,
			volumeSnapshots

	]);
	
	useEffect(() => {
			(async () => {
					const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${flavors_default_url_query}`
					const method = "GET"

					const flavors_response = await openstackRequest({url:url, method:method})
					if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
							setFlavors([...flavors_response.data.flavors])
					}
			})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			flavorsUrl
	]);

	// useEffect(() => {
	// 		(async () => {
	// 				const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}`
	// 				const method = "GET"
					
	// 				const server_groups_response = await computeNovaRequest({
	// 						url:url, 
	// 						method:method, 
	// 						token,
	// 				})
					
	// 				if (server_groups_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
	// 						const server_group_filter = server_groups_response.data.server_groups.map(sg => {
	// 								return {keyword: sg.name, value: sg.id, default: false}
	// 						})
	// 						setServerGroups(server_group_filter)
	// 				}
					
	// 		})();
	// },[
	// 		computeServiceDomain,
	// 		computeServiceVersion,
	// 		serverGroupsUrl,
	// 		token
	// ]);


	useEffect(() => {
			(async () => {
					const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
					const method = "GET"

					const images_response = await openstackRequest({url:url, method:method, token, })
					if (images_response.status_code === imagesUrlResponses.get.success_response.status_code) {
							setImages(images_response.data.images)
					}
					
			})();
	},[
			imageServiceDomain, 
			imageServiceVersion, 
			imagesUrl, 
			token
	]);

	useEffect(() => {
					(async () => {
							
						let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail`
						const method = "GET"
						const volume_response = await volumeCinderRequest({url:url, method:method, token, })
						if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
								setVolumes(volume_response.data.volumes)
						}
							
					})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumesUrl, 
			defaultAdminProject,
			token
	]);

	useEffect(() => {
			(async () => {
					
				let all_volume_types = []
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
				const method = "GET"

				const volume_types_response = await volumeCinderRequest({url:url, method:method, token, })
				if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
						all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
				}
				
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
				const response = await volumeCinderRequest({url:url, method:method, token, })
				if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
						const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
						all_volume_types = [...private_vt, ...all_volume_types]
				}
				const volumeType_filter = all_volume_types.map(vt => {
						return {keyword: vt.name, value: vt.id, default: false}
				})
				setVolumeTypes(volumeType_filter)
					
			})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumeTypesUrl, 
			defaultAdminProject,
			token
	]);

	useEffect(() => {
					(async () => {
							
						let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail`
						const method = "GET"
						const snapshots_response = await volumeCinderRequest({url:url, method:method, token, })
						if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
								setVolumeSnapshots(snapshots_response.data.snapshots)
						}
							
					})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			snapshotsUrl, 
			defaultAdminProject,
			token
	]);

	useEffect(() => {
					(async () => {
							
						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
						const method = "GET"
						const network_response = await openstackRequest({url:url, method:method, token, })
						if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
								setNetworks(network_response.data.networks)
						}
							
					})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl, 
			token
	]);

	useEffect(() => {
					(async () => {
							
						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
						const method = "GET"
						const subnet_response = await openstackRequest({url:url, method:method, token, })
						if (subnet_response.status_code === networkUrlResponses.get.success_response.status_code) {
								setSubnets(subnet_response.data.subnets)
						}
							
					})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			subnetsUrl, 
			token
	]);

	useEffect(() => {
					(async () => {
							
						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}`
						const method = "GET"
						const securityGroup_response = await openstackRequest({url:url, method:method, token, })
						if (securityGroup_response.status_code === networkUrlResponses.get.success_response.status_code) {
								setSecurityGroups(securityGroup_response.data.security_groups)
						}
							
					})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			securityGroupsUrl, 
			token
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
				const method = "GET"
				const ports_response = await openstackRequest({url:url, method:method, token, })
				if (ports_response.status_code === networkUrlResponses.get.success_response.status_code) {
						setPorts(ports_response.data.ports)
				}
					
			})();
},[
	neutronServiceDomain, 
	neutronServiceVersion, 
	portsUrl, 
	token
]);


	useEffect(() => {
			if (keypairFetchRequired) {
					(async () => {
							
						const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
						const method = "GET"

						const keypairs_response = await openstackRequest({url:url, method:method, token, })
						if (keypairs_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
								const keypair_filter = keypairs_response.data.keypairs.map(k => {
										return {keyword: `${k.keypair.name} (${k.keypair.fingerprint})`, value: k.keypair.name, default: false}
								})
								setKeypairs(keypair_filter)
						}
							
					})();
					setKeypairFetchRequired(false)
			}
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			keypairsUrl, 
			keypairFetchRequired,
			token
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${computeServiceDomain}/${computeServiceVersion}/${availabilityZonesUrl}`
				const method = "GET"
				const az_response = await computeNovaRequest({url:url, method:method, token, })
				if (az_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
						const az_filter = az_response.data.availabilityZoneInfo.map(az => {
								return {keyword: az.zoneName, value: az.zoneName, default: false}
						})
						setAvailabilityZones(az_filter)
				}
					
			})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			availabilityZonesUrl, 
			token
	]);

	useEffect(() => {
			let data_to_update = [...images]
			if (images.length > 0) {
					const handleImagesDataFormatting = (data) => {
							const updated_data = data.map(item => {
									let new_item = {...item}
									
									if (item.size < 1024) {
											new_item.size = item.size
									} else if (item.size < 1048576) {
											new_item.size = `${parseInt(item.size / 1024)}KB`
									} else if (item.size < 1073741824) {
											new_item.size = `${parseInt(item.size / (1024 * 1024))}MB`
									} else {
											new_item.size = `${parseInt(item.size / (1024 * 1024 * 1024))}GB`
									}
									new_item.status = item.status.charAt(0).toUpperCase() + item.status.slice(1)
									new_item.visibility = item.visibility.charAt(0).toUpperCase() + item.visibility.slice(1)
									if (Object.keys(item).includes("block_device_mapping") && item.block_device_mapping.length > 0) {
											const mapping = JSON.parse(item.block_device_mapping)
											new_item.type = mapping[0].source_type === "snapshot" ? 
											mapping[0].source_type.toUpperCase() :
											"IMAGE"
									} else {
											new_item.type = "IMAGE"
									}
									return new_item
							})
							return updated_data
					}
					data_to_update = handleImagesDataFormatting(data_to_update)

					const images_list = data_to_update.filter(i => i.type === "IMAGE" && i.status === "Active")
					const snapshots_list = data_to_update.filter(i => i.type === "SNAPSHOT" && i.status === "Active")

					const images_options = images_list.map(i => {
							return {
									label: `${i.name} | ${i.os_distro ? i.os_distro : ""} ${i.os_version ? i.os_version : ""} | ${i.visibility} | Min Disk: ${i.min_disk}GB | Min RAM: ${i.min_ram}MB | Size: ${i.size}`,
									id: i.id
							}
					})

					const snapshots_options = snapshots_list.map(i => {
							return {
									label: `${i.name} | ${i.os_distro ? i.os_distro : ""} ${i.os_version ? i.os_version : ""} | ${i.visibility} | Min Disk: ${i.min_disk}GB | Min RAM: ${i.min_ram}MB | Size: ${i.size}`,
									id: i.id
							}
					})
					setImageOptions(images_options)
					setSnapshotOptions(snapshots_options)
			}
			
	},[
			images,
			defaultTexts
	]);

	useEffect(() => {
			let data_to_update = volumes.filter(v => v.status === "available" && v.bootable === "true")

			if (volumes.length > 0) {
					const handleVolumeDataFormatting = (data) => {
							const updated_data = data.map(item => {
									let new_item = {...item}									
									
									return new_item
							})
							return updated_data
					}
					data_to_update = handleVolumeDataFormatting(data_to_update)

					const volumes_options = data_to_update.map(i => {
							return {
									label: `${i.name} | Volume Type: ${i.volume_type} | Size: ${i.size}GB`,
									id: i.id
							}
					})

					
					setVolumeOptions(volumes_options)
			}
			
	},[
			volumes,
			defaultTexts
	]);

	useEffect(() => {
			const bootable_volumes = volumes.filter(v => v.bootable === "true").map(v => v.id)
			let data_to_update = volumeSnapshots.filter(v => v.status === "available" && bootable_volumes.includes(v.volume_id))

			if (volumes.length > 0) {
					const handleVolumeDataFormatting = (data) => {
							const updated_data = data.map(item => {
									let new_item = {...item}
									
									return new_item
							})
							return updated_data
					}
					data_to_update = handleVolumeDataFormatting(data_to_update)

					const snapshot_options = data_to_update.map(i => {
							return {
									label: `${i.name} | Size: ${i.size}GB`,
									id: i.id
							}
					})

					
					setVolumeSnapshotOptions(snapshot_options)
			}
			
	},[
			volumeSnapshots,
			volumes,
			defaultTexts
	]);

	useEffect(() => {
			let data_to_update = flavors.filter(f => !f["OS-FLV-DISABLED:disabled"] )
			if (selectedBootSource === "image" && selectedImage) {
					const selected_image = images.filter(i => i.id === selectedImage.id)
					if (selected_image.length > 0) {
							data_to_update = data_to_update.filter(
									f => f.ram >= selected_image[0].min_ram && (f.disk >= selected_image[0].min_disk || f.disk === 0) 
							)
					}
			} else if (selectedBootSource === "instance_snapshot" && selectedInstanceSnapshot) {
					const selected_snapshot = images.filter(i => i.id === selectedInstanceSnapshot.id)
					if (selected_snapshot.length > 0) {
							data_to_update = data_to_update.filter(
									f => f.ram >= selected_snapshot[0].min_ram && (f.disk >= selected_snapshot[0].min_disk || f.disk === 0) 
							)
					}
			} else if (selectedBootSource === "volume" && selectedVolume) {
					const selected_volume = volumes.filter(i => i.id === selectedVolume.id)
					if (selected_volume.length > 0) {
							data_to_update = data_to_update.filter(f =>
									f.disk >= selected_volume[0].size || f.disk === 0
							)
					}
			} else if (selectedBootSource === "volume_snapshot" && selectedVolumeSnapshot) {
					const selected_vol_snapshot = volumeSnapshots.filter(i => i.id === selectedVolumeSnapshot.id)
					if (selected_vol_snapshot.length > 0) {
							data_to_update = data_to_update.filter(f =>
									f.disk >= selected_vol_snapshot[0].size || f.disk === 0
							)
					}
			}
			const flavor_options = data_to_update.map(f => {
					const is_piblic = f["os-flavor-access:is_public"]
					const ephemeral = f["OS-FLV-EXT-DATA:ephemeral"]
					return {
							label: `${f.name} | Public: ${is_piblic ? "Yes" : "No"} | vCPUs: ${f.vcpus} | RAM: ${f.ram}MB | Disk: ${f.disk}GB | SWAP: ${f.swap}MB | Ephemeral Disk: ${ephemeral}GB`,
							id: f.id
					}
			})

			setFlavorOptions(flavor_options)
	},[
			flavors,
			images,
			selectedBootSource,
			selectedInstanceSnapshot,
			selectedImage,
			selectedVolume,
			volumeSnapshots,
			selectedVolumeSnapshot,
			volumes,
			defaultTexts
	]);

	useEffect(() => {
			if (selectedFlavor) {
					const flavor_in_options = flavorOptions.filter(fo => fo.id === selectedFlavor.id)
					if (flavor_in_options.length === 0) {
							setSelectedFlavor(null)
					}
			}

	},[
			selectedFlavor,
			flavorOptions
	]);

	useEffect(() => {
			const network_options = networks.map(n => {
					return {
							keyword: `${n.name} | External: ${n.external ? "Yes" : "No"} | Status: ${n.status}`,
							value: n.id,
							default: false
					}
			})
					
			setNetworkOptions(network_options)
			
	},[
			networks,
	]);
	
	useEffect(() => {
			let data_to_update = ports.filter(p => p.status.toLowerCase() === "down")
			const port_options = data_to_update.map(n => {
					return {
							keyword: `${n.name} | Address: ${n.fixed_ips[0].ip_address} | Status: ${n.status}`,
							value: n.id,
							default: false
					}
			})
					
			setPortOptions(port_options)
			
	},[
			ports,
	]);

	useEffect(() => {
			let data_to_update = securityGroups

			const handleSecGroupDataFormatting = (data) => {
					const updated_data = data.map(item => {
							let new_item = {...item}							
							
							return new_item
					})
					return updated_data
			}
			data_to_update = handleSecGroupDataFormatting(data_to_update)
			
			const sec_group_options = data_to_update.map(n => {
					return {
							keyword: `${n.name} | Stateful: ${n.stateful ? "Yes" : "No"} | Rules: ${n.security_group_rules.length}`,
							value: n.id,
							default: false
					}
			})
					
			setSecurityGroupOptions(sec_group_options)
			
	},[
			securityGroups,
	]);

	const handleMetadataFormatting = useCallback((objs,props) => {
			const metadata_catalog = metadataNamespaces.map(ns => {
					let new_item = {...ns}
					const resource_type_association = ns.resource_type_associations.filter(rs => rs.name === RESOURCE_NAME)[0]
					new_item["value"] = ns.namespace
					new_item["title"] = ns.display_name
					const prefix = resource_type_association.prefix ? resource_type_association.prefix : ""
					new_item["prefix"] = prefix
					new_item["objects"] = objs[ns.namespace].map((obj,index) => {
							let new_object = {...obj}
							new_object["title"] = obj.name
							new_object["value"] = `${ns.namespace}_${prefix}object${index}`
							new_object["properties"] = []
							const obj_props = Object.keys(obj.properties).map(key => {
									let new_prop = {}
									new_prop["value"] = `${prefix}${key}`
									for (const [k,v] of Object.entries(obj.properties[key])) {
											if (Array.isArray(v)) {
													new_prop[k] = [...v]
											} else if (v instanceof Object) {
													new_prop[k] = {...v}
											} else {
													new_prop[k] = v
											}
									}
									return new_prop
							})
							new_object["properties"] = obj_props
							return new_object
					})
					new_item["properties"] = Object.keys(props[ns.namespace]).map(pr_key => {
							let new_pr = {}
							new_pr["value"] = `${prefix}${pr_key}`
							for (let k in props[ns.namespace][pr_key]) {
									if (k === "items") {
											new_pr["enum"] = props[ns.namespace][pr_key][k]["enum"]
									} else if (Array.isArray(props[ns.namespace][pr_key][k])) {
											new_pr[k] = [...props[ns.namespace][pr_key][k]]
									} else if (props[ns.namespace][pr_key][k] instanceof Object) {
											new_pr[k] = {...props[ns.namespace][pr_key][k]}
									} else {
											new_pr[k] = props[ns.namespace][pr_key][k]
									}
							}
							return  new_pr
					})
					return new_item
			})
			setMetadataCatalog(metadata_catalog)
	},[metadataNamespaces])

	useEffect(() => {
			(async () => {
					const url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces`
					const method = "GET"
					
					let namespace_list = []
					const metadata_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})
					if (metadata_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
							const namespaces = metadata_response.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
									item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
							)
							namespace_list = [...namespace_list, ...namespaces]
							if (Object.keys(metadata_response.data).includes("next")) {
									let next_url = `${imageServiceDomain}${metadata_response.data.next}`
									while (next_url) {
											const next_request = await openstackRequest({
													url:next_url, 
													method:method, 
													token,
											})
											const next_namespaces = next_request.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
													item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
											)
											namespace_list = [...namespace_list, ...next_namespaces]
											if (Object.keys(next_request.data).includes("next")) {
													next_url = `${imageServiceDomain}${next_request.data.next}`
											} else {
													next_url = null
											}
									}
							}
							const compare = ( a, b ) => {
									if ( a["display_name"].toLowerCase() < b["display_name"].toLowerCase() ){
										return -1;
									}
									if ( a["display_name"].toLowerCase() > b["display_name"].toLowerCase() ){
										return 1;
									}
									return 0;
							}
							namespace_list.sort(compare)
							setMetadataNamespaces(namespace_list)
					} else {
							setMetadataNamespaces([])
					}
					
			})();
	},[
			imageServiceDomain,
			imageServiceVersion,
			token
	]);

	useEffect(() => {
			if (metadataNamespaces.length > 0) {
					(async () => {
							let total_objects = {}
							let total_properties = {}
							
							const method = "GET"
							for (let i in metadataNamespaces) {
									let namespace_objects = []
									let namespace_properties = {}

									const object_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/objects`
									const object_response = await openstackRequest({
											url:object_url, 
											method:method, 
											token,
									})
									if (object_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
											namespace_objects = [...namespace_objects, ...object_response.data.objects]
									}
									const props_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/properties`
									const props_response = await openstackRequest({
											url:props_url, 
											method:method, 
											token,
									})
									if (props_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
											namespace_properties = {...namespace_properties, ...props_response.data.properties}
									}
									
									total_objects[metadataNamespaces[i].namespace] = namespace_objects
									total_properties[metadataNamespaces[i].namespace] = namespace_properties
							}
							handleMetadataFormatting(total_objects, total_properties)
					})();
			}
	},[
			metadataNamespaces,
			imageServiceDomain,
			imageServiceVersion,
			handleMetadataFormatting,
			token
	]);

	useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${computeServiceDomain}/${computeServiceVersion}/${computeLimitsUrl}`
        const method = "GET"
        const limits_response = await openstackRequest({url:url, method:method, token, })

        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.limits && limits_response.data.limits.absolute) {
          const absoulteItem = limits_response.data.limits.absolute

          updated_data.cpu_cores = { limit: absoulteItem.maxTotalCores, used: absoulteItem.totalCoresUsed, reserved: 0, }
          updated_data.ram = { limit: absoulteItem.maxTotalRAMSize, used: absoulteItem.totalRAMUsed, reserved: 0, }
        }

        setServerLimits(updated_data)        
    })()
  },[
    computeServiceDomain,
    computeServiceVersion,
    computeLimitsUrl,
    defaultAdminProject,
    token,
  ])

	useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${cinderLimitsUrl}/${defaultAdminProject}?usage=true`
        const method = "GET"
        const limits_response = await volumeCinderRequest({url:url, method:method, token, })
        
        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.quota_set) {
          updated_data = limits_response.data.quota_set
        }
        
        setVolumeLimits(updated_data)        
    })()
  },[
    cinderServiceDomain,
    cinderServiceVersion,
    cinderLimitsUrl,
    defaultAdminProject,
    token,
  ])

	useEffect(() => {
		let selected_cpus = 0

		if (flavors.length > 0 && selectedFlavor) {
			const findFlavor = flavors.find(item => item.id === selectedFlavor.id)
			if (findFlavor) {
				selected_cpus = findFlavor.vcpus
			}
		}

		setSelectedCpus(selected_cpus * selectedServerCount)
	}, [flavors, selectedFlavor, selectedServerCount])

	useEffect(() => {
		let selected_ram = 0

		if (flavors.length > 0 && selectedFlavor) {
			const findFlavor = flavors.find(item => item.id === selectedFlavor.id)
			if (findFlavor) {
				selected_ram = findFlavor.ram
			}
		}

		setSelectedRam(selected_ram * selectedServerCount)
	}, [flavors, selectedFlavor, selectedServerCount])

	useEffect(() => {
		let selected_volume_count = 0
		let selected_volume_capacity = 0
		
		moreVolumes.forEach(item => {
			if (item.volume_type) {
				selected_volume_count ++
				selected_volume_capacity += parseInt(item.size)
			}
		})

		if (selectedBootSource === 'image' && createNewVolume && newSystemVolume.type) {
			selected_volume_count ++
			selected_volume_capacity += parseInt(newSystemVolume.size)
		}

		if (selectedBootSource === 'instance_snapshot' && selectedInstanceSnapshot) {
			selected_volume_count ++
			const findImage = images.find(item => item.id === selectedInstanceSnapshot.id)
			if (findImage && findImage.block_device_mapping) {
				const block_device_mapping = JSON.parse(findImage.block_device_mapping)
				block_device_mapping.forEach(item => selected_volume_capacity += item.volume_size)
			}
		}

		if (selectedBootSource === 'volume' && selectedVolume) {
			selected_volume_count ++
			const findVolume = volumes.find(item => item.id === selectedVolume.id)
			if (findVolume) {
				selected_volume_capacity += parseInt(findVolume.size)
			}
		}

		if (selectedBootSource === 'volume_snapshot' && selectedVolumeSnapshot) {
			selected_volume_count ++
			const findVolumeSnapshot = volumeSnapshots.find(item => item.id === selectedVolumeSnapshot.id)
			if (findVolumeSnapshot) {
				selected_volume_capacity += parseInt(findVolumeSnapshot.size)
			}
		}

		setSelectedVolumeCount(selected_volume_count * selectedServerCount)
		setSelectedVolumeCapacity(selected_volume_capacity * selectedServerCount)
	}, [
		moreVolumes,
		selectedBootSource,
		createNewVolume,
		newSystemVolume,
		selectedInstanceSnapshot,
		selectedVolume,
		selectedVolumeSnapshot,
		images,
		volumes,
		volumeSnapshots,
		selectedServerCount,
	])

	useEffect(() => {
		let limits_error = false

		if (serverLimits && serverLimits.cpu_cores && serverLimits.ram && volumeLimits && volumeLimits.volumes && volumeLimits.gigabytes) {
			if (serverLimits.cpu_cores.used + selectedCpus >= serverLimits.cpu_cores.limit || serverLimits.ram.used + selectedRam >= serverLimits.ram.limit || volumeLimits.volumes.in_use + selectedVolumeCount >= volumeLimits.volumes.limit || volumeLimits.gigabytes.in_use + selectedVolumeCapacity >= volumeLimits.gigabytes.limit) {
				limits_error = true
			}
		}

		setLimitsError(limits_error)
	}, [serverLimits, volumeLimits, selectedCpus, selectedRam, selectedVolumeCount, selectedVolumeCapacity])

	useEffect(() => {
		let summary_height = height - 300

		if (quotasExpanded) {
			summary_height -= 250
		}

		if (summary_height < 100) {
			setSummaryExpanded(false)
		}

		setSummaryHeight(summary_height)
	}, [height, quotasExpanded])

	useEffect(() => {
		if (summaryHeight < 10 && quotasExpanded && summaryExpanded) {
			setQuotasExpanded(false)
		}
	}, [summaryHeight, quotasExpanded, summaryExpanded])

	useEffect(() => {
		if (width > 1920) {
			setSummaryWidth(360)
		} else if (width < 900) {
			setSummaryWidth(width * 0.9)
		} else {
			setSummaryWidth(width * 0.1875)
		}
	}, [width])
	
	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);
	
	return (
			<CustomDialog
					open={open}
					onClose={handleClick}
					maxWidth="xl"
			dialogTitle={{
					title: defaultTexts.serverCreateTitle, 
					sx: {color: 'primary.main'}}}
			dialogBody={{
					text: "", 
					sx: {color: 'text.primary'}}}
			actionButtons={[{
					title: defaultTexts.submitButtonText, 
					onClick: handleServerCreate,
					variant: "contained",
					sx: {color: 'white'}}]}
			>
							
					<Grid container spacing={2} sx={{ marginTop: width < 900 ? '40px' : '0px' }}>
							<Grid item md={9}>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.generalInfoSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}}/>
									<Grid container spacing={3} sx={{my: 1, mb: 5}}>
											<Grid item sx={{flexGrow: 1}}>
													<CustomTextField 
															currentValue={selectedName}
															setCurrentValue={handleSelectedNameChange}
															label={defaultTexts.nameFormFieldLabel}
															size="large"
															required={true}
															error={selectedNameError}
													/>
											</Grid>
											<Grid item sx={{flexGrow: 1}}>
													<CustomTextField 
															currentValue={selectedServerCount}
															setCurrentValue={handleSelectedServerCountChange}
															label={defaultTexts.instanceCountFormFieldLabel}
															size="large"
															type="number"
															min={1}
													/>
											</Grid>
									</Grid>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.bootSourceSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Grid container spacing={2} sx={{my: 1, mb: 5}}>
											<Grid item>
													<CustomSelectField 
															currentValue={selectedBootSource}
															setCurrentValue={setSelectedBootSource}
															items={bootSourceList}
															self_item_titles={false}
															item_titles={defaultTexts}
															label={defaultTexts.bootSourceSectionTitle}
															size="large"
															empty={true}
															required={true}
													/>
											</Grid>
											<Grid item sx={{flexGrow: 1}}>
													{selectedBootSource === "image" && 
																	<Autocomplete
																			isOptionEqualToValue={handleOptionCheck}
																			value={selectedImage}
																			onChange={(event, newValue) => {
																					handleSelectedImageChange(newValue);
																			}}
																			inputValue={searchedImage}
																			onInputChange={(event, newInputValue) => {
																			setSearchedImage(newInputValue);
																			}}
																			id="controllable-states-demo"
																			options={imageOptions}
																			renderInput={(params) => 
																					<TextField 
																							{...params} 
																							required={true} 
																							label={defaultTexts.imageFormFieldLabel}
																							error={selectedImageError}
																					/>}
																	/>
													}
													{selectedBootSource === "instance_snapshot" && 
															<Autocomplete
																	isOptionEqualToValue={handleOptionCheck}
																	value={selectedInstanceSnapshot}
																	onChange={(event, newValue) => {
																			handleSelectedInstanceSnapshotChange(newValue);
																	}}
																	inputValue={searchedSnapshot}
																	onInputChange={(event, newInputValue) => {
																	setSearchedSnapshot(newInputValue);
																	}}
																	id="controllable-states-demo"
																	options={snapshotOptions}
																	renderInput={(params) => 
																			<TextField 
																					{...params} 
																					required={true} 
																					label={defaultTexts.snapshotFormFieldLabel} 
																					error={selectedInstanceSnapshotError}
																			/>}
															/>
													}
													{selectedBootSource === "volume" && 
															<Autocomplete
																	isOptionEqualToValue={handleOptionCheck}
																	value={selectedVolume}
																	onChange={(event, newValue) => {
																			handleSelectedVolumeChange(newValue);
																	}}
																	inputValue={searchedVolume}
																	onInputChange={(event, newInputValue) => {
																	setSearchedVolume(newInputValue);
																	}}
																	id="controllable-states-demo"
																	options={volumeOptions}
																	renderInput={(params) => 
																			<TextField 
																					{...params} 
																					required={true} 
																					label={defaultTexts.volumeFormFieldLabel} 
																					error={selectedVolumeError}
																			/>}
															/>
													}
													{selectedBootSource === "volume_snapshot" && 
															<Autocomplete
																	isOptionEqualToValue={handleOptionCheck}
																	value={selectedVolumeSnapshot}
																	onChange={(event, newValue) => {
																			handleSelectedVolumeSnapshotChange(newValue);
																	}}
																	inputValue={searchedVolumeSnapshot}
																	onInputChange={(event, newInputValue) => {
																	setSearchedVolumeSnapshot(newInputValue);
																	}}
																	id="controllable-states-demo"
																	options={volumeSnapshotOptions}
																	renderInput={(params) => 
																			<TextField 
																					{...params} 
																					required={true} 
																					label={defaultTexts.volumeSnapshotFormFieldLabel} 
																					error={selectedVolumeSnapshotError}
																			/>}
															/>
													}
											</Grid>
									</Grid>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.flavorSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Autocomplete
											sx={{my: 5}}
											isOptionEqualToValue={handleOptionCheck}
											value={selectedFlavor}
											onChange={(event, newValue) => {
													handleFlavorChange(newValue);
											}}
											inputValue={searchedFlavor}
											onInputChange={(event, newInputValue) => {
											setSearchedFlavor(newInputValue);
											}}
											id="controllable-states-demo"
											options={flavorOptions}
											renderInput={(params) => 
													<TextField 
															{...params} 
															label={defaultTexts.flavorFormFieldLabel} 
															required={true} 
															error={selectedFlavorError}
													/>}
									/>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.storageSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Grid container spacing={2} sx={{my: 1, mb: 5}} alignItems="center">
									{selectedBootSource === "image" && 
											<Grid item xs={12}>
											<Stack
													direction="row"
													spacing={2}
													alignItems="center"
											>
													<CustomText>
															{defaultTexts.createNewVolumeFormFieldLabel}
													</CustomText>
													<ButtonGroup
															disableElevation
															aria-label="Disabled button group"
															size="large"
															color="customBlueColor"
															>
															<Button 
																	variant={createNewVolume ? "contained" : "outlined"}
																	onClick={() => handleSelectNewVolumeCreate(true)}
															>
																	{defaultTexts.formValueYes}
															</Button>
															<Button 
																	variant={createNewVolume ? "outlined" : "contained"}
																	onClick={() => handleSelectNewVolumeCreate(false)}
															>
																	{defaultTexts.formValueNo}
															</Button>
													</ButtonGroup>
											</Stack>
											</Grid>}
											{selectedBootSource === "image" && createNewVolume && 
													<Grid container spacing={2} sx={{m: 1}} alignItems="center">
													<Grid item>
													<CustomTextField
															currentValue={newSystemVolume.size}
															setCurrentValue={handleNewSystemVolumeChange}
															field_key="size"
															label={defaultTexts.sizeInGBFormFieldLabel}
															size="large"
															type="number"
															min={1}
															
													/>
											</Grid>
											<Grid item  sx={{flexGrow: 1}}>
													<CustomSelectField
															currentValue={newSystemVolume.type}
															setCurrentValue={handleNewSystemVolumeChange}
															field_key="type"
															items={volumeTypes}
															self_item_titles={true}
															label={defaultTexts.volumeTypeFormFieldLabel}
															size="large"
															empty={false}
															required={false}
															sx={{width: "100%"}}
													/>
											</Grid>
											<Grid item  sx={{flexGrow: 1}}>
													<CustomCheckboxField
															currentValue={newSystemVolume.delete_on_terminate}
															setCurrentValue={handleNewSystemVolumeChange}
															field_key="delete_on_terminate"
															label={defaultTexts.deleteOnTerminateFormFieldLabel}
															required={false}
															sx={{width: "100%"}}
													/>
											</Grid>
											</Grid>}
											{selectedBootSource === "instance_snapshot" && 
													selectedInstanceSnapshot && 
											<Grid item sx={{flexGrow: 1}}>
													{JSON.parse(images.filter(i => i.id === selectedInstanceSnapshot.id)[0].block_device_mapping).map((item,index) => {
															const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
															const volume_size = `${defaultTexts.sizeFormFieldLabel} ${item.volume_size}GB`
															const volume_type = `${defaultTexts.volumeTypeFormFieldLabel}: ${item.volume_type ? item.volume_type : 
															defaultTexts.defaultVolumeTypeFormFieldLabel}`
															const delete_on_terminate = item.delete_on_termination ? defaultTexts.deleteOnTerminateFormFieldLabel : 
															defaultTexts.doNotDeleteOnTerminateFormFieldLabel
															return (
																	<Grid container spacing={1} key={index}>
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${disk_title}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${volume_size}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${volume_type}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{flexGrow: 1, m:1}}>
																			<CustomText>
																					{`${delete_on_terminate}`}
																			</CustomText>
																			</Grid>
																	</Grid>
															)
													})}
											</Grid>}
											{selectedBootSource === "volume" && 
													selectedVolume && 
											<Grid item sx={{flexGrow: 1}}>
													{volumes.filter(v => v.id === selectedVolume.id).map((item,index) => {
															const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
															const volume_size = `${defaultTexts.sizeFormFieldLabel} ${item.size}GB`
															const volume_type = `${defaultTexts.volumeTypeFormFieldLabel}: ${item.volume_type ? item.volume_type : 
															defaultTexts.defaultVolumeTypeFormFieldLabel}`
															return (
																	<Grid container spacing={1} key={index} alignItems="center">
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${disk_title}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${volume_size}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{flexGrow: 1, m:1}}>
																			<CustomText>
																					{`${volume_type}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{flexGrow: 1, m:1}}>
																			<CustomCheckboxField
																					currentValue={selectedVolumeData.delete_on_termination}
																					setCurrentValue={handleSelectedVolumeDataChange}
																					field_key="delete_on_termination"
																					label={defaultTexts.deleteOnTerminateFormFieldLabel}
																					required={false}
																			/>
																			</Grid>
																	</Grid>
															)
													})}
											</Grid>}
											{selectedBootSource === "volume_snapshot" && 
													selectedVolumeSnapshot && 
											<Grid item sx={{flexGrow: 1}}>
													{volumeSnapshots.filter(v => v.id === selectedVolumeSnapshot.id).map((item,index) => {
															const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
															const volume_size = `${defaultTexts.sizeFormFieldLabel} ${item.size}GB`
															return (
																	<Grid container spacing={1} key={index} alignItems="center">
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${disk_title}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{width: 200, m:1}}>
																			<CustomText>
																					{`${volume_size}`}
																			</CustomText>
																			</Grid>
																			<Grid item sx={{flexGrow: 1, m:1}}>
																			<CustomCheckboxField
																					currentValue={selectedVolumeSnapshotData.delete_on_termination}
																					setCurrentValue={handleSelectedVolumeSnapshotDataChange}
																					field_key="delete_on_termination"
																					label={defaultTexts.deleteOnTerminateFormFieldLabel}
																					required={false}
																			/>
																			</Grid>
																	</Grid>
															)
													})}
											</Grid>}
															{moreVolumes.map((vol,index) => {
																	return (
																			<Grid key={index} container spacing={2} sx={{m: 1}} alignItems="center">
																					<Grid item>
																					<CustomTextField
																							currentValue={vol.size}
																							setCurrentValue={handleMoreVolumesChange}
																							field_key="size"
																							index={index}
																							label={defaultTexts.sizeInGBFormFieldLabel}
																							size="large"
																							type="number"
																							min={1}
																					/>
																					</Grid>
																					<Grid item sx={{flexGrow: 1}}>
																							<CustomSelectField
																									currentValue={vol.type}
																									setCurrentValue={handleMoreVolumesChange}
																									field_key="volume_type"
																									index={index}
																									items={volumeTypes}
																									self_item_titles={true}
																									label={defaultTexts.volumeTypeFormFieldLabel}
																									size="large"
																									empty={false}
																									required={false}
																									sx={{width: "100%"}}
																							/>
																					</Grid>
																					<Grid item sx={{flexGrow: 1}}>
																							<CustomCheckboxField
																									currentValue={vol.delete_on_termination}
																									setCurrentValue={handleMoreVolumesChange}
																									field_key="delete_on_termination"
																									index={index}
																									label={defaultTexts.deleteOnTerminateFormFieldLabel}
																									required={false}
																									sx={{width: "100%"}}
																							/>
																					</Grid>
																					</Grid>
																			)})
																	}
																	<Grid item sx={{flexGrow: 1}}>
																	{moreVolumes.length > 0 && <MinusButton 
																			style={{ transform: 'scale(0.7)' }}
																			sx={{height: 5}}
																			onClick={handleMoreVolumesRemove}
																	/>}
																	{<Stack 
																			direction="row" 
																			spacing={2} 
																			alignItems="center"
																			onClick={handleMoreVolumesAdd}
																			sx={{cursor: "pointer"}}
																	>
																			<PlusButton 
																					style={{ transform: 'scale(0.7)' }}
																					sx={{height: 5}}
																					onClick={handleMoreVolumesAdd}
																			/>
																			<CustomText>
																					{defaultTexts.addMoreDataVolumeFormFieldLabel}
																			</CustomText>
																	</Stack>}
																	</Grid>
									</Grid>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.networkSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Grid container spacing={2}  sx={{my: 1, mb: 5}}>
											<Grid item sx={{flexGrow: 1}}>
													<Stack
															direction="row"
															spacing={2}
															alignItems="center"
													>
															<CustomText>
																	{defaultTexts.networkAllocationOptionsLabel}
															</CustomText>
															<ButtonGroup
																	disableElevation
																	aria-label="Disabled button group"
																	size="large"
																	color="customBlueColor"
																	>
																	<Button 
																			variant={networkAllocationType === "auto" ? "contained" : "outlined"}
																			onClick={() => setNetworkAllocationType("auto")}
																	>
																			{defaultTexts.autoNetworkAllocationTypeTitle}
																	</Button>
																	<Button 
																			variant={networkAllocationType === "none" ? "contained" : "outlined"}
																			onClick={() => setNetworkAllocationType("none")}
																	>
																			{defaultTexts.noneNetworkAllocationTypeTitle}
																	</Button>
																	<Button 
																			variant={networkAllocationType === "manual" ? "contained" : "outlined"}
																			onClick={() => setNetworkAllocationType("manual")}
																	>
																			{defaultTexts.manualNetworkAllocationTypeTitle}
																	</Button>
															</ButtonGroup>
													</Stack>
													{networkAllocationType === "manual" && 
																	<div>
																			{selectedNetworks.map((net,index) => {
																			let subs = subnets.filter(n => n.network_id === net.uuid)
																			const subnet_options = subs.map(n => {
																					return {
																							keyword: `${n.name} | ${n.cidr}`,
																							value: n.id,
																							default: false
																					}
																			})
																			return (
																					<Grid container spacing={2}  key={index} sx={{my: 3}} alignItems="center">
																					<Grid item>
																							<CustomSelectField
																									currentValue={net.uuid}
																									setCurrentValue={handleSelectedNetworksChange}
																									field_key="uuid"
																									index={index}
																									items={networkOptions}
																									self_item_titles={true}
																									label={defaultTexts.networkFormFieldLabel}
																									size="large"
																									empty={false}
																									required={true}
																									menuProps={{width: undefined}}
																									error={net.error}
																							/>
																							</Grid>
																							<Grid item>
																							<CustomSelectField
																									currentValue={net.ip_allocation}
																									setCurrentValue={handleSelectedNetworksChange}
																									field_key="ip_allocation"
																									index={index}
																									items={ipAllocationOptions}
																									self_item_titles={false}
																									item_titles={defaultTexts}
																									label={defaultTexts.ipAllocationTypeFormFieldLabel}
																									size="large"
																									default_value="auto"
																									empty={false}
																									required={false}
																							/>
																							</Grid>
																							{net.uuid.length > 0 && net.ip_allocation === "auto" &&
																									<Grid item>
																											<CustomText>{defaultTexts.allocationPoolsFormFieldLabel}</CustomText>
																											{subnets.filter(s => s.network_id === net.uuid).map((sub,ind) => {
																													return (
																															<div key={ind}>
																																	{sub.allocation_pools.map((p,index) => {
																																			return (
																																					<div key={index}>
																																							<CustomText>{p.start} - {p.end}</CustomText>
																																					</div>
																																					
																																			)
																																	})}
																															</div>
																													)
																											})}
																									</Grid>
																							}
																							{net.ip_allocation === "manual" && <Grid item>
																									<CustomSelectField
																											currentValue={net.subnet_id}
																											setCurrentValue={handleSelectedNetworksChange}
																											field_key="subnet_id"
																											index={index}
																											items={subnet_options}
																											self_item_titles={true}
																											label={defaultTexts.subnetFormFieldLabel}
																											size="large"
																											empty={false}
																											required={false}
																											menuProps={{width: undefined}}
																									/>
																							</Grid>}
																							{net.ip_allocation === "manual" && <Grid item>
																									<CustomTextField
																											currentValue={net.fixed_ip}
																											setCurrentValue={handleSelectedNetworksChange}
																											field_key="fixed_ip"
																											index={index}
																											label={defaultTexts.ipAddressFormFieldLabel}
																											size="large"
																											
																									/>
																							</Grid>}
																					</Grid>)
																			})}
																			{selectedNetworks.length > 0 && <MinusButton 
																					style={{ transform: 'scale(0.7)' }}
																					sx={{height: 5}}
																					onClick={handleSelectedNetworksRemove}
																			/>}
																			{<Stack 
																					direction="row" 
																					spacing={2} 
																					alignItems="center"
																					onClick={handleSelectedNetworksAdd}
																					sx={{cursor: "pointer"}}
																			>
																					<PlusButton 
																							style={{ transform: 'scale(0.7)' }}
																							sx={{height: 5}}
																							onClick={handleSelectedNetworksAdd}
																					/>
																					<CustomText>
																							{defaultTexts.addNetworkFormFieldLabel}
																					</CustomText>
																	</Stack>}
																	{selectedNetworksError && 
																			<FormHelperText error={selectedNetworksError} sx={{m: 2}}>
																					{defaultTexts.emptyNetworkListErrorText}
																			</FormHelperText>
																	}
															</div>
													}
											</Grid>
									</Grid>
									{networkAllocationType === "manual" && <CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.portSectionTitle}
									</CustomText>}
									{networkAllocationType === "manual" && <Divider sx={{my: 1}} />}
									{networkAllocationType === "manual" &&  <Grid container spacing={2} sx={{my: 1, mb: 5}} alignItems="center">
											<Grid item>
													<CustomSelectField
															currentValue={selectedPorts}
															setCurrentValue={setSelectedPorts}
															items={portOptions}
															self_item_titles={true}
															label={defaultTexts.portFormFieldLabel}
															size="large"
															empty={true}
															required={false}
															menuProps={{width: undefined}}
															fieldWidth={350}
															multiple={true}
													/>
											</Grid>
											{selectedPorts.length > 0 && 
													<Grid item>
															<CustomText sx={{mr: 1}}>
																	{defaultTexts.selectedPortsNoteText}:
															</CustomText>
															<Chip 
																	label={selectedPorts.length} 
																	color="primary"
															/>
													</Grid>
											}
									</Grid>}
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.securitySectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Grid container spacing={2} sx={{my: 1, mb: 5}} alignItems="center">
									{(networkAllocationType === "auto" || (networkAllocationType === "manual" && selectedNetworks.length > 0 && 
											networks.filter(n => selectedNetworks.map(sn => sn.uuid).includes(n.id) && 
											!n.port_security_enabled).length === 0)) && 
											<Grid item xs={12}>
													{false && <Stack
															direction="row"
															spacing={2}
															alignItems="center"
													>
															<CustomText>
																	{defaultTexts.createNewSecGroupFormFieldLabel}
															</CustomText>
															<ButtonGroup
																	disableElevation
																	aria-label="Disabled button group"
																	size="large"
																	color="customBlueColor"
																	>
																	<Button 
																			variant={createNewSecGroup ? "contained" : "outlined"}
																			onClick={() => handleSelectNewSecGroupCreate(true)}
																	>
																			{defaultTexts.formValueYes}
																	</Button>
																	<Button 
																			variant={createNewSecGroup ? "outlined" : "contained"}
																			onClick={() => handleSelectNewSecGroupCreate(false)}
																	>
																			{defaultTexts.formValueNo}
																	</Button>
															</ButtonGroup>
													</Stack>}
													</Grid>}
													{(networkAllocationType === "auto" || 
															(networkAllocationType === "manual" && 
																	selectedNetworks.length > 0 && 
																	networks.filter(n => selectedNetworks.map(sn => sn.uuid).includes(n.id) && 
																	!n.port_security_enabled).length === 0)) && 
																	createNewSecGroup &&
															<Grid container spacing={2} sx={{m: 1}} alignItems="center">
															<Grid item>
															<CustomTextField
																	currentValue={newSecurityGroup.name}
																	setCurrentValue={handleNewSecurityGroupChange}
																	field_key="name"
																	label={defaultTexts.nameFormFieldLabel}
																	size="large"
																	required={true}
																	error={newSecurityGroupOptions.name && newSecurityGroupOptions.name.error}
															/>
													</Grid>
													<Grid item  sx={{flexGrow: 1}}>
															<CustomCheckboxField
																	currentValue={newSecurityGroup.allow_ssh}
																	setCurrentValue={handleNewSecurityGroupChange}
																	field_key="allow_ssh"
																	label={defaultTexts.allowSSHFormFieldLabel}
																	required={false}
																	sx={{width: "100%"}}
															/>
													</Grid>
													<Grid item  sx={{flexGrow: 1}}>
															<CustomCheckboxField
																	currentValue={newSecurityGroup.allow_http}
																	setCurrentValue={handleNewSecurityGroupChange}
																	field_key="allow_http"
																	label={defaultTexts.allowHTTPFormFieldLabel}
																	required={false}
																	sx={{width: "100%"}}
															/>
													</Grid>
													<Grid item  sx={{flexGrow: 1}}>
															<CustomCheckboxField
																	currentValue={newSecurityGroup.allow_https}
																	setCurrentValue={handleNewSecurityGroupChange}
																	field_key="allow_https"
																	label={defaultTexts.allowHTTPSFormFieldLabel}
																	required={false}
																	sx={{width: "100%"}}
															/>
													</Grid>
											</Grid>}
											{(networkAllocationType === "auto" || 
													(networkAllocationType === "manual" && 
															selectedNetworks.length > 0 && 
															networks.filter(n => selectedNetworks.map(sn => sn.uuid).includes(n.id) && 
															!n.port_security_enabled).length === 0)) && 
															!createNewSecGroup && 
													<Grid container spacing={2} sx={{m: 1}} alignItems="center">
															<Grid item>
																	<CustomSelectField
																			currentValue={selectedSecurityGroups}
																			setCurrentValue={setSelectedSecurityGroups}
																			items={securityGroupOptions}
																			self_item_titles={true}
																			label={defaultTexts.securityGroupFormFieldLabel}
																			size="large"
																			empty={true}
																			required={false}
																			menuProps={{width: undefined}}
																			fieldWidth={350}
																			multiple={true}
																	/>
															</Grid>
															{selectedSecurityGroups.length > 0 && 
																	<Grid item>
																			<CustomText sx={{mr: 1}}>
																					{defaultTexts.selectedSecurityGroupsNoteText}:
																			</CustomText>
																			<Chip 
																					label={selectedSecurityGroups.length} 
																					color="primary"
																			/>
																	</Grid>
															}
													</Grid>
											}
											{(networkAllocationType === "none" || 
													(networkAllocationType === "manual" && 
															selectedNetworks.length === 0)) && 
													<Grid container spacing={2} sx={{m: 1}} alignItems="center">
															<Grid item sx={{flexGrow: 1}}>
																	<CustomText>
																			{defaultTexts.noSecGroupAppliedToServerNoteText}
																	</CustomText>
															</Grid>
													</Grid>
											}
											{networkAllocationType === "manual" && 
															networks.filter(n => selectedNetworks.map(sn => sn.uuid).includes(n.id) && 
															!n.port_security_enabled).length > 0 && 
													<Grid container spacing={2} sx={{m: 1}} alignItems="center">
															<Grid item sx={{flexGrow: 1}}>
																	<CustomText>
																			{defaultTexts.mulformSecGroupAppliedToServerNoteText}
																	</CustomText>
															</Grid>
													</Grid>
											}
									</Grid>
									<CustomText sx={{...sectionTitleStyle}}>
											{defaultTexts.keyPairSectionTitle}
									</CustomText>
									<Divider sx={{my: 1}} />
									<Grid container spacing={2} sx={{m: 1}} alignItems="center">
											<Grid item xs={12}>
													<Stack
															direction="row"
															spacing={2}
															alignItems="center"
													>
															<CustomText>
																	{defaultTexts.createNewKeypairFormFieldLabel}
															</CustomText>
															<ButtonGroup
																	disableElevation
																	aria-label="Disabled button group"
																	size="large"
																	color="customBlueColor"
																	>
																	<Button 
																			variant={createNewKeypair ? "contained" : "outlined"}
																			onClick={() => handleSelectNewKeypairCreate(true)}
																	>
																			{defaultTexts.formValueYes}
																	</Button>
																	<Button 
																			variant={createNewKeypair ? "outlined" : "contained"}
																			onClick={() => handleSelectNewKeypairCreate(false)}
																	>
																			{defaultTexts.formValueNo}
																	</Button>
															</ButtonGroup>
													</Stack>
											</Grid>
											{createNewKeypair && <Grid item xs={12}>
													<CustomTextField
															currentValue={newPublicKey.name}
															setCurrentValue={handlePublicKeyDataChange}
															label={defaultTexts.nameFormFieldLabel}
															required={true}
															error={newKeypairOptions.name ? newKeypairOptions.name.error : false}
															errorText={defaultTexts.requiredFormFieldError}
															field_key="name"
															sx={{width: {xs: "90%", md: "60%"}}}
													/>
											</Grid>}
											{createNewKeypair && <Grid item xs={12}>
													<CustomTextField
															currentValue={newPublicKey.public_key}
															setCurrentValue={handlePublicKeyDataChange}
															label={defaultTexts.publicKeyFormFieldLabel}
															field_key="public_key"
															multiline={true}
															required={true}
															error={newKeypairOptions.public_key ? newKeypairOptions.public_key.error : false}
															errorText={defaultTexts.requiredFormFieldError}
															sx={{width: {xs: "90%", md: "60%"}}}
													/>
											</Grid>}
											{createNewKeypair && <Grid item>
													<Button 
															component="label"
															role={undefined}
															variant="contained"
															tabIndex={-1}
															startIcon={<CloudUploadIcon />} 
															color="primary"
															sx={{mb: 1}}
													>
															{defaultTexts.uploadPublicKeyButtonText}
															<VisuallyHiddenInput 
																	type="file" 
																	onChange={handlePublicKeyUpload} 
															/>
													</Button>
											</Grid>}
											{createNewKeypair && <Grid item>
													<Button 
															component="label"
															variant="contained"
															tabIndex={-1}
															color="secondary"
															sx={{mb: 1}}
															onClick={handleCreateNewKeypair}
													>
															{defaultTexts.saveButtonText}
													</Button>
											</Grid>}
											{!createNewKeypair && <Grid item>
													<CustomSelectField
															currentValue={selectedKeypair}
															setCurrentValue={setSelectedKeypair}
															items={keypairs}
															self_item_titles={true}
															label={defaultTexts.keypairFormFieldLabel}
															size="large"
															empty={true}
															required={false}
															menuProps={{width: undefined}}
															fieldWidth={350}
													/>
											</Grid>}
									</Grid>
									<Divider sx={{mt: 5, mb: 2}} />
									{!showAdvancedOptions && <Grid item xs={12}>
											<Button 
													component="label"
													variant="text"
													tabIndex={-1}
													color="primary"
													sx={{my: 2}}
													endIcon={<ArrowDropDownIcon />}
													onClick={() => setShowAdvancedOptions(true)}
											>
													{defaultTexts.showAdvancedOptionsButtonText}
											</Button>
									</Grid>}
									{showAdvancedOptions && <Grid item xs={12}>
											<Button 
													component="label"
													variant="text"
													tabIndex={-1}
													color="primary"
													sx={{my: 2}}
													endIcon={<ArrowDropUpIcon />}
													onClick={() => setShowAdvancedOptions(false)}
											>
													{defaultTexts.hideAdvancedOptionsButtonText}
											</Button>
									</Grid>}
									{showAdvancedOptions &&
											<React.Fragment>
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.adminPasswordSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item  sx={{flexGrow: 1}}>
															<CustomCheckboxField
																	currentValue={adminPasswordRequired}
																	setCurrentValue={(event) => setAdminPasswordRequired(event.target.checked)}
																	label={defaultTexts.setAdminPasswordFormFieldLabel}
																	required={false}
																	sx={{width: "100%", my: 3}}
															/>
													</Grid>
													{adminPasswordRequired && <Grid item xs={12}>
															<CustomTextField 
																	size="medium"
																	error={adminPasswordError}
																	errorText={defaultTexts.passwordDoNotMatchError}
																	required={true}
																	label={defaultTexts.passwordFormFieldLabel}
																	currentValue={adminPassword}
																	setCurrentValue={handleAdminPasswordChange}
																	type={showPassword ? 'text' : 'password'}
																	EndIcon={
																			<IconButton
																					onClick={handleClickShowPassword}
																					onMouseDown={handleMouseDownPassword}
																					edge="end"
																			>
																			{showPassword ? 
																					<VisibilityOff /> : 
																					<Visibility />}
																			</IconButton>
																	}
																	sx={{mb: 2, width: {xs: "90%", sm: 400}}}
															/>
													</Grid>}
													{adminPasswordRequired && <Grid item xs={12}>
															<CustomTextField 
																	size="medium"
																	error={adminPasswordConfirmError}
																	errorText={defaultTexts.passwordDoNotMatchError}
																	required={true}
																	label={defaultTexts.confirmPasswordFormFieldLabel}
																	currentValue={adminPasswordConfirm}
																	setCurrentValue={handleAdminPasswordConfirmChange}
																	type={showPasswordConfirm ? 'text' : 'password'}
																	EndIcon={
																			<IconButton
																					onClick={handleClickShowPasswordConfirm}
																					onMouseDown={handleMouseDownPasswordConfirm}
																					edge="end"
																			>
																			{showPasswordConfirm ? 
																					<VisibilityOff /> : 
																					<Visibility />}
																			</IconButton>
																	}
																	sx={{mb: 5, width: {xs: "90%", sm: 400}}}
															/>
													</Grid>}
													
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.descriptionSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item xs={12}>
															<CustomTextField
																	currentValue={selectedDescription}
																	setCurrentValue={(event) => setSelectedDescription(event.target.value)}
																	label={defaultTexts.descriptionFormFieldLabel}
																	multiline={true}
																	sx={{width: {xs: "90%", md: "60%"}, my: 3}}
															/>
													</Grid>
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.availabilityZoneSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item>
															<CustomSelectField
																	currentValue={selectedAvailabilityZone}
																	setCurrentValue={setSelectedAvailabilityZone}
																	items={availabilityZones}
																	self_item_titles={true}
																	label={defaultTexts.availabilityZoneFormFieldLabel}
																	size="large"
																	empty={true}
																	required={false}
																	menuProps={{width: undefined}}
																	fieldWidth={350}
																	sx={{my: 3}}
															/>
													</Grid>
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.diskPartitionSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item>
															<CustomSelectField
																	currentValue={selectedDiskPartition}
																	setCurrentValue={setSelectedDiskPartition}
																	items={diskPartitions}
																	self_item_titles={false}
																	item_titles={defaultTexts}
																	label={defaultTexts.diskPartitionFormFieldLabel}
																	size="large"
																	empty={true}
																	required={false}
																	menuProps={{width: undefined}}
																	fieldWidth={350}
																	sx={{my: 3}}
															/>
													</Grid>
													{/* <CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.serverGroupSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item>
															<CustomSelectField
																	currentValue={selectedServerGroup}
																	setCurrentValue={setSelectedServerGroup}
																	items={serverGroups}
																	self_item_titles={true}
																	label={defaultTexts.serverGroupFormFieldLabel}
																	size="large"
																	empty={true}
																	required={false}
																	menuProps={{width: undefined}}
																	fieldWidth={350}
																	sx={{my: 3}}
															/>
													</Grid> */}
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.userDataSectionTitle}
													</CustomText>
													<Divider sx={{my: 1, mb: 3}} />
													<Grid item xs={12}>
															<CustomText>
																	{defaultTexts.providedDataSizeLabelText}: {userDataSize} {defaultTexts.bytesFormFieldLabel} | {defaultTexts.maxAllowedDataSizeLabel}: {MAX_ALLOWEDDATA_SIZE} {defaultTexts.bytesFormFieldLabel}
															</CustomText>
													</Grid>
													<Grid item xs={12}>
															<CustomTextField
																	currentValue={selectedUserData}
																	setCurrentValue={(event) => setSelectedUserData(event.target.value)}
																	label={defaultTexts.customCofigurationFormFieldLabel}
																	multiline={true}
																	sx={{my: 3, width: {xs: "90%", md: "60%"}}}
															/>
													</Grid>
													<Grid item>
															<Button 
																	component="label"
																	role={undefined}
																	variant="contained"
																	tabIndex={-1}
																	startIcon={<CloudUploadIcon />} 
																	color="primary"
																	sx={{mb: 5}}
															>
																	{defaultTexts.uploadUserDataButtonText}
																	<VisuallyHiddenInput 
																			type="file" 
																			onChange={handleUserDataUpload} 
																	/>
															</Button>
													</Grid>
													<CustomText sx={{...sectionTitleStyle}}>
															{defaultTexts.metadataSectionTitle}
													</CustomText>
													<Divider sx={{my: 1}} />
													<Grid item xs={12}>
															<Button 
																	component="label"
																	variant="contained"
																	tabIndex={-1}
																	color="secondary"
																	sx={{my: 3}}
																	onClick={handleServerMetadataDialogOpen}
															>
																	{defaultTexts.addMetadataButtonText}
															</Button>
													</Grid>
													<Grid item>
																	{Object.keys(selectedMetadata).map(md => {
																			return (
																					<Stack key={md} direction="row" spacing={1} alignItems="center" sx={{m: 2}}>
																							<CustomText>{md} =</CustomText>
																							<CustomText>{selectedMetadata[md]}</CustomText>
																					</Stack>
																			)
																	})}
													</Grid>
											</React.Fragment>
									}
							</Grid>
						</Grid>

						<Box
							sx={{
								position: 'absolute',
								width: `${summaryWidth}px`,
								top: width >=900 ? '90px' : '50px',
								right: width >=900 ? '30px' : 'auto',
								left: width >=900 ? 'auto' : '16px',
							}}
						>							
							<Box
								sx={{
									...sideBoxStyle,
									height: quotasExpanded ? '250px' : '45px',
									overflowY: 'hidden',
									float: width >=900 ? 'none' : 'left',
									width: width >=900 ? '100%' : '47%',
									background: mode === 'light' ? 'white' : theme.palette.customLightBlack,
								}}
							>
								<Box sx={expandButtonStyle}>
									{
										quotasExpanded ?
										<IconButton onClick={() => setQuotasExpanded(false)}>
											<ExpandLessIcon />
										</IconButton>
										:
										<IconButton onClick={() => setQuotasExpanded(true)}>
											<ExpandMoreIcon />
										</IconButton>
									}									
								</Box>

										<Box sx={summaryLabelStyle} onClick={() => setQuotasExpanded((prev) => !prev)}>{defaultTexts.resourcesQuotasLabel}</Box>

										{
											(serverLimits && serverLimits.cpu_cores && serverLimits.ram) &&
											<>
												<Box sx={quotasItemStyle}>
													<Box component='span'>{defaultTexts.cpuQuotaLabel}: </Box>
													<Box component='span' sx={{
														color: serverLimits.cpu_cores.used + selectedCpus < serverLimits.cpu_cores.limit ? theme.palette.primary.main: theme.palette.error.main,
													}}>
														{serverLimits.cpu_cores.used + selectedCpus}/{serverLimits.cpu_cores.limit}
													</Box>
												</Box>

												<Box sx={quotasGraphBlockStyle}>
													<Box
														sx={{
															...quotasGraphUsedStyle,
															width: `${(serverLimits.cpu_cores.used/serverLimits.cpu_cores.limit)*100}%`,
														}}
													></Box>
													<Box
														sx={{
															...quotasGraphSelectedStyle,
															width: serverLimits.cpu_cores.used + selectedCpus < serverLimits.cpu_cores.limit ? `${(selectedCpus/serverLimits.cpu_cores.limit)*100}%` : `calc(${((serverLimits.cpu_cores.limit - serverLimits.cpu_cores.used)/serverLimits.cpu_cores.limit)*100}% + 4px)`,
														}}
													></Box>
												</Box>

												<Box sx={quotasItemStyle}>
													<Box component='span'>{defaultTexts.memoryQuotaLabel}: </Box>
													<Box component='span' sx={{
														color: serverLimits.ram.used + selectedRam < serverLimits.ram.limit ? theme.palette.primary.main: theme.palette.error.main,
													}}>
														{(serverLimits.ram.used + selectedRam)/1024}/{serverLimits.ram.limit/1024}
													</Box>
												</Box>

												<Box sx={quotasGraphBlockStyle}>
													<Box
														sx={{
															...quotasGraphUsedStyle,
															width: `${(serverLimits.ram.used/serverLimits.ram.limit)*100}%`,
														}}
													></Box>
													<Box
														sx={{
															...quotasGraphSelectedStyle,
															width: serverLimits.ram.used + selectedRam < serverLimits.ram.limit ? `${(selectedRam/serverLimits.ram.limit)*100}%` : `calc(${((serverLimits.ram.limit - serverLimits.ram.used)/serverLimits.ram.limit)*100}% + 4px)`,
														}}
													></Box>
												</Box>
											</>
										}

										{
											(volumeLimits && volumeLimits.volumes && volumeLimits.gigabytes) &&
											<>
												<Box sx={quotasItemStyle}>
													<Box component='span'>{defaultTexts.volumeQuotaLabel}: </Box>
													<Box component='span' sx={{
														color: volumeLimits.volumes.in_use + selectedVolumeCount < volumeLimits.volumes.limit ? theme.palette.primary.main: theme.palette.error.main,
													}}>
														{volumeLimits.volumes.in_use + selectedVolumeCount}/{volumeLimits.volumes.limit}
													</Box>
												</Box>

												<Box sx={quotasGraphBlockStyle}>
													<Box
														sx={{
															...quotasGraphUsedStyle,
															width: `${(volumeLimits.volumes.in_use/volumeLimits.volumes.limit)*100}%`,
														}}
													></Box>
													<Box
														sx={{
															...quotasGraphSelectedStyle,
															width: volumeLimits.volumes.in_use + selectedVolumeCount < volumeLimits.volumes.limit ? `${(selectedVolumeCount/volumeLimits.volumes.limit)*100}%` : `calc(${((volumeLimits.volumes.limit - volumeLimits.volumes.in_use)/volumeLimits.volumes.limit)*100}% + 4px)`,
														}}
													></Box>
												</Box>

												<Box sx={quotasItemStyle}>
													<Box component='span'>{defaultTexts.volumeCapacityQuotaLabel}: </Box>
													<Box component='span' sx={{
														color: volumeLimits.gigabytes.in_use + selectedVolumeCapacity < volumeLimits.gigabytes.limit ? theme.palette.primary.main: theme.palette.error.main,
													}}>
														{volumeLimits.gigabytes.in_use + selectedVolumeCapacity}/{volumeLimits.gigabytes.limit}
													</Box>
												</Box>

												<Box sx={quotasGraphBlockStyle}>
													<Box
														sx={{
															...quotasGraphUsedStyle,
															width: `${(volumeLimits.gigabytes.in_use/volumeLimits.gigabytes.limit)*100}%`,
														}}
													></Box>
													<Box
														sx={{
															...quotasGraphSelectedStyle,
															width: volumeLimits.gigabytes.in_use + selectedVolumeCapacity < volumeLimits.gigabytes.limit ? `${(selectedVolumeCapacity/volumeLimits.gigabytes.limit)*100}%` : `calc(${((volumeLimits.gigabytes.limit - volumeLimits.gigabytes.in_use)/volumeLimits.gigabytes.limit)*100}% + 4px)`,
														}}
													></Box>
												</Box>
											</>
										}

									</Box>

									<Box
										sx={{
											...sideBoxStyle,
											height: summaryExpanded ? `${summaryHeight}px` : '45px',
											overflowY: summaryExpanded ? 'auto' : 'hidden',
											float: width >=900 ? 'none' : 'left',
											width: width >=900 ? '100%' : '47%',
											background: mode === 'light' ? 'white' : theme.palette.customLightBlack,
										}}
									>
										<Box sx={expandButtonStyle}>
											{
												summaryExpanded ?
												<IconButton onClick={() => setSummaryExpanded(false)}>
													<ExpandLessIcon />
												</IconButton>
												:
												<IconButton onClick={() => setSummaryExpanded(true)}>
													<ExpandMoreIcon />
												</IconButton>
											}									
										</Box>

										<Box sx={summaryLabelStyle} onClick={() => setSummaryExpanded((prev) => !prev)}>{defaultTexts.summaryLabel}</Box>

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.generalInfoSectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box sx={{ color: selectedName ? theme.palette.success.main : theme.palette.error.main }}>
												{defaultTexts.nameFormFieldLabel}
											</Box>
											<Box sx={summaryValueStyle}>{selectedName}</Box>
										</Stack>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box>{defaultTexts.instanceCountFormFieldLabel}</Box>
											<Box sx={summaryValueStyle}>{selectedServerCount}</Box>
										</Stack>
										<Divider />

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.bootSourceSectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box sx={{ color: selectedBootSource ? theme.palette.success.main : theme.palette.error.main }}>
												{defaultTexts.bootSourceSectionTitle}
											</Box>
											<Box sx={summaryValueStyle}>{getKeywordFromValue(selectedBootSource, bootSourceList)}</Box>
										</Stack>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box sx={{ color: ( (selectedBootSource === 'image' && selectedImage) || (selectedBootSource === 'instance_snapshot' && selectedInstanceSnapshot) || (selectedBootSource === 'volume' && selectedVolume) || (selectedBootSource === 'volume_snapshot' && selectedVolumeSnapshot) ) ? theme.palette.success.main : theme.palette.error.main }}>
												{ selectedBootSource === 'image' && defaultTexts.imageFormFieldLabel }
												{ selectedBootSource === 'instance_snapshot' && defaultTexts.snapshotFormFieldLabel }
												{ selectedBootSource === 'volume' && defaultTexts.volumeFormFieldLabel }
												{ selectedBootSource === 'volume_snapshot' && defaultTexts.volumeSnapshotFormFieldLabel }
											</Box>
											<Box sx={summaryValueStyle}>
												{ (selectedBootSource === 'image' && selectedImage) && selectedImage.label }
												{ (selectedBootSource === 'instance_snapshot' && selectedInstanceSnapshot) && selectedInstanceSnapshot.label }
												{ (selectedBootSource === 'volume' && selectedVolume) && selectedVolume.label }
												{ (selectedBootSource === 'volume_snapshot' && selectedVolumeSnapshot) && selectedVolumeSnapshot.label }
											</Box>
										</Stack>
										<Divider />

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.flavorSectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box sx={{ color: selectedFlavor ? theme.palette.success.main : theme.palette.error.main }}>
												{defaultTexts.flavorSectionTitle}
											</Box>
											<Box sx={summaryValueStyle}>{selectedFlavor && selectedFlavor.label}</Box>
										</Stack>
										<Divider />

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.storageSectionTitle}</Box>
										<Divider />

										{
											selectedBootSource === 'image' &&
											<>
												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.createNewVolumeFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{ createNewVolume ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
												</Stack>
												<Divider />
											</>
										}

										{
											(selectedBootSource === 'image' && createNewVolume) &&
											<>
												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.sizeInGBFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{newSystemVolume.size}</Box>
												</Stack>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.volumeTypeFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{getLabelFromValue(newSystemVolume.type, volumeTypes)}</Box>
												</Stack>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.deleteOnTerminateFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{ newSystemVolume.delete_on_terminate ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
												</Stack>
												<Divider />
											</>
										}

										{
											(selectedBootSource === "instance_snapshot" && selectedInstanceSnapshot) &&
											<>
												{
													JSON.parse(images.filter(i => i.id === selectedInstanceSnapshot.id)[0].block_device_mapping).map((item,index) => {
														const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
														const volume_size = `${item.volume_size}GB`
														const volume_type = `${item.volume_type ? item.volume_type : defaultTexts.defaultVolumeTypeFormFieldLabel}`

														return (
															<Box key={index}>
																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.diskTableHeaderLabel}</Box>
																	<Box sx={summaryValueStyle}>{disk_title}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.sizeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{volume_size}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.volumeTypeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{volume_type}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.deleteOnTerminateFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{ item.delete_on_termination ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
																</Stack>
																<Divider />
															</Box>
														)
													})
												}
											</>
										}

										{
											(selectedBootSource === "volume" && selectedVolume) &&
											<>
												{
													volumes.filter(v => v.id === selectedVolume.id).map((item,index) => {
														const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
														const volume_size = `${item.size}GB`
														const volume_type = `${item.volume_type ? item.volume_type : defaultTexts.defaultVolumeTypeFormFieldLabel}`
														
														return (
															<Box key={index}>
																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.diskTableHeaderLabel}</Box>
																	<Box sx={summaryValueStyle}>{disk_title}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.sizeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{volume_size}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.volumeTypeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{volume_type}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.deleteOnTerminateFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{ selectedVolumeData.delete_on_termination ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
																</Stack>
																<Divider />
															</Box>
														)
                          })
												}
											</>
										}

										{
											(selectedBootSource === "volume_snapshot" && selectedVolumeSnapshot) &&
											<>
												{
													volumeSnapshots.filter(v => v.id === selectedVolumeSnapshot.id).map((item,index) => {
														const disk_title = index > 0 ? defaultTexts.dataDiskTableTitle : defaultTexts.systemDiskTableTitle
														const volume_size = `${item.size}GB`

														return (
															<Box key={index}>
																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.diskTableHeaderLabel}</Box>
																	<Box sx={summaryValueStyle}>{disk_title}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.sizeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{volume_size}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.deleteOnTerminateFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{ selectedVolumeSnapshotData.delete_on_termination ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
																</Stack>
																<Divider />
															</Box>
														)
                          })
												}
											</>
										}
										
										{
											moreVolumes.map((item, index) => (
												<Box key={index}>
													<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="flex-start"
														sx={summaryItemStyle}
													>
														<Box>{defaultTexts.sizeInGBFormFieldLabel}</Box>
														<Box sx={summaryValueStyle}>{item.size}</Box>
													</Stack>
													<Divider />

													<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="flex-start"
														sx={summaryItemStyle}
													>
														<Box>{defaultTexts.volumeTypeFormFieldLabel}</Box>
														<Box sx={summaryValueStyle}>{getLabelFromValue(item.volume_type, volumeTypes)}</Box>
													</Stack>
													<Divider />

													<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="flex-start"
														sx={summaryItemStyle}
													>
														<Box>{defaultTexts.deleteOnTerminateFormFieldLabel}</Box>
														<Box sx={summaryValueStyle}>{ item.delete_on_termination ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
													</Stack>
													<Divider />
												</Box>
											))
										}

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.networkSectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box>{defaultTexts.networkAllocationOptionsLabel}</Box>
											<Box sx={summaryValueStyle}>
												{networkAllocationType === 'auto' && defaultTexts.autoNetworkAllocationTypeTitle}
												{networkAllocationType === 'none' && defaultTexts.noneNetworkAllocationTypeTitle}
												{networkAllocationType === 'manual' && defaultTexts.manualNetworkAllocationTypeTitle}
											</Box>
										</Stack>
										<Divider />

										{
											networkAllocationType === 'manual' &&
											<>
												{
													selectedNetworks.map((item, index) => {
														let subs = subnets.filter(n => n.network_id === item.uuid)
														const subnet_options = subs.map(n => {
															return {
																keyword: `${n.name} | ${n.cidr}`,
																value: n.id,
																default: false
															}
														})

														return (
															<Box key={index}>
																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box sx={{ color: item.uuid ? theme.palette.success.main : theme.palette.error.main }}>
																		{defaultTexts.networkFormFieldLabel}
																	</Box>
																	<Box sx={summaryValueStyle}>{getLabelFromValue(item.uuid, networkOptions)}</Box>
																</Stack>
																<Divider />

																<Stack 
																	direction="row" 
																	justifyContent="space-between" 
																	alignItems="flex-start"
																	sx={summaryItemStyle}
																>
																	<Box>{defaultTexts.ipAllocationTypeFormFieldLabel}</Box>
																	<Box sx={summaryValueStyle}>{getKeywordFromValue(item.ip_allocation, ipAllocationOptions)}</Box>
																</Stack>
																<Divider />

																{
																	(item.uuid.length > 0 && item.ip_allocation === 'auto') &&
																	<>
																		<Stack 
																			direction="row" 
																			justifyContent="space-between" 
																			alignItems="flex-start"
																			sx={summaryItemStyle}
																		>
																			<Box>{defaultTexts.allocationPoolsFormFieldLabel}</Box>
																			<Box sx={summaryValueStyle}>
																				{
																					subnets.filter(s => s.network_id === item.uuid).map((item2, index2) => (
																						<Box key={index2}>
																							{
																								item2.allocation_pools.map((item3, index3) => (
																									<Box key={index3}>{item3.start} - {item3.end}</Box>
																								))
																							}
																						</Box>
																					))
																				}
																			</Box>
																		</Stack>
																		<Divider />
																	</>
																}

																{
																	item.ip_allocation === 'manual' &&
																	<>
																		<Stack 
																			direction="row" 
																			justifyContent="space-between" 
																			alignItems="flex-start"
																			sx={summaryItemStyle}
																		>
																			<Box>{defaultTexts.subnetFormFieldLabel}</Box>
																			<Box sx={summaryValueStyle}>{getLabelFromValue(item.subnet_id, subnet_options)}</Box>
																		</Stack>
																		<Divider />

																		<Stack 
																			direction="row" 
																			justifyContent="space-between" 
																			alignItems="flex-start"
																			sx={summaryItemStyle}
																		>
																			<Box>{defaultTexts.ipAddressFormFieldLabel}</Box>
																			<Box sx={summaryValueStyle}>{item.fixed_ip}</Box>
																		</Stack>
																		<Divider />
																	</>
																}
															</Box>
														)
													})
												}
											</>
										}

										{
											networkAllocationType === 'manual' &&
											<>
												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.portSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.selectedPortsNoteText}</Box>
													<Box sx={summaryValueStyle}>
														{
															selectedPorts.map((item, index) => (
																<Box key={index}>{getLabelFromValue(item, portOptions)}</Box>
															))
														}
													</Box>
												</Stack>
												<Divider />
											</>
										}

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.securitySectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box>{defaultTexts.selectedSecurityGroupsNoteText}</Box>
											<Box sx={summaryValueStyle}>
												{
													selectedSecurityGroups.map((item, index) => (
														<Box key={index}>{getLabelFromValue(item, securityGroupOptions)}</Box>
													))
												}
											</Box>
										</Stack>
										<Divider />

										<Box sx={summaryCategoryLabelStyle}>{defaultTexts.keyPairSectionTitle}</Box>
										<Divider />

										<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="flex-start"
											sx={summaryItemStyle}
										>
											<Box>{defaultTexts.createNewKeypairFormFieldLabel}</Box>
											<Box sx={summaryValueStyle}>{ createNewKeypair ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
										</Stack>
										<Divider />

										{
											createNewKeypair &&
											<>
												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.nameFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{newPublicKey && newPublicKey.name}</Box>
												</Stack>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.publicKeyFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{newPublicKey && newPublicKey.public_key}</Box>
												</Stack>
												<Divider />
											</>
										}

										{
											!createNewKeypair &&
											<>
												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.keypairFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{getLabelFromValue(selectedKeypair, keypairs)}</Box>
												</Stack>
												<Divider />
											</>
										}

										{
											showAdvancedOptions &&
											<>
												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.adminPasswordSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.setAdminPasswordFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{ adminPasswordRequired ? defaultTexts.formValueYes : defaultTexts.formValueNo }</Box>
												</Stack>
												<Divider />

												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.descriptionSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.descriptionFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{selectedDescription}</Box>
												</Stack>
												<Divider />

												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.availabilityZoneSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.availabilityZoneSectionTitle}</Box>
													<Box sx={summaryValueStyle}>{getLabelFromValue(selectedAvailabilityZone, availabilityZones)}</Box>
												</Stack>
												<Divider />

												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.diskPartitionSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.diskPartitionFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{getKeywordFromValue(selectedDiskPartition, diskPartitions)}</Box>
												</Stack>
												<Divider />

												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.userDataSectionTitle}</Box>
												<Divider />

												<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="flex-start"
													sx={summaryItemStyle}
												>
													<Box>{defaultTexts.customCofigurationFormFieldLabel}</Box>
													<Box sx={summaryValueStyle}>{selectedUserData}</Box>
												</Stack>
												<Divider />

												<Box sx={summaryCategoryLabelStyle}>{defaultTexts.metadataSectionTitle}</Box>
												<Divider />

												{
													Object.keys(selectedMetadata).map((item, index) => (
														<Box key={index}>
															<Stack 
																direction="row" 
																justifyContent="space-between" 
																alignItems="flex-start"
																sx={summaryItemStyle}
															>
																<Box>{item}</Box>
																<Box sx={summaryValueStyle}>{selectedMetadata[item]}</Box>
															</Stack>
															<Divider />
														</Box>
													))
												}
											</>
										}

									</Box>
				</Box>

					<CustomDialog
							open={serverMetadataDialogOpen}
							onClose={handleServerMetadataSelectionDialogClose}
							dialogTitle={{
									title: defaultTexts.serverMetadataSelectActionTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: "", 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.submitButtonText, 
									onClick: onServerMetadataUpdate, 
									sx: {color: 'primary.main'}}]}
							maxWidth="lg"
					>
							<CustomTransferList 
									withCustomList={true}
									withInstructions={true}
									catalogList={metadataCatalog}
									appliedFieldList={selectedMetadata}
									onDataUpdate={setUpdatedMetadata}
							/>
					</CustomDialog>
					{error && <CustomDialog
							open={errorDialogOpen}
							onClose={handleErrorDialogClose}
							dialogTitle={{
									title: defaultTexts[error.error_title], 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: `<span>${defaultTexts[error.error_message]}</span>
													<br>
													<br>
													<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
													<span style="color: orange">
															${error.error_details}
													</span>`, 
									sx: {color: 'text.primary'}}}
					/>}
			</CustomDialog>
	)
}

export default ComputeServerLunchDialogV21