import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import GroupsTableV3 from './groupsTableV3';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { volumeGroupsUrl as groupUrlResponses } from '../../../../../_api_responses/openstack/cinder/groups/v3';
import GroupDetailV3 from './groupDetailV3';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';
import SearchIcon from '@mui/icons-material/Search';
import ServiceMenu from '../../../../_common/ServiceMenu';
import GroupAddV3 from './GroupAddV3';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import CustomPopover from '../../../../_common/CustomPopover'
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.volumeService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const GroupsWrapperV3 = (props) => {
	const theme = useTheme()
	const { navigate, location, changeMenuActiveTab, serviceMenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const [groupsData, setGroupsData] = useState([])
	const [groups, setGroups] = useState([])
	const { width } = useWindowDimensions();
	const WIDTH_WEIGHT = getWidthWeight(width)
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [groupDeleteConfirmDialogOpen, setGroupDeleteConfirmDialogOpen] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState([])
	const [groupsSortParams, setGroupsSortParams] = useState("")
	const [volumeTypes, setVolumeTypes] = useState([]);
	const [volumeTypesList, setVolumeTypesList] = useState([]);
	const [groupTypes, setGroupTypes] = useState([]);
	const [groupTypesList, setGroupTypesList] = useState([]);
	
	const [groupFilterQueryParams, setGroupFilterQueryParams] = useState("")
	const [volumeDeleteCascade, setVolumeDeleteCascade] = useState(false)

	const [volumes, setVolumes] = useState([])

	const [selectedGroupIds, setSelectedGroupIds] = useState([])
	const [searchKeyword, setSearchKeyword] = useState('')
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const groupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	const volumeTypesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
	const groupTypesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
	
	const handleFilteredSearch = () => {
		if (searchKeyword) {
			setGroupFilterQueryParams(`&name=${searchKeyword}`)
		} else {
			setGroupFilterQueryParams('')
		}

		handleDataFetch()
	}

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleGroupsDataFormatting = useCallback(() => {
			const formatted_data = groupsData.map((item) => {
					let new_item = {...item}
					
					const group_type = groupTypes.filter(g => g.id === item.group_type)
					if (group_type.length > 0) {
							new_item.group_type = group_type[0].name ? group_type[0].name : group_type[0].id
					}
					return new_item
			})
			setGroups(formatted_data)
	},[
			groupsData,
			groupTypes,
	])

	const handleGroupFilterReset = () => {
			setSearchKeyword('')
			setGroupFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToVolume = (volume_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			navigate("/storage",{state: {volume_id: volume_id}})
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedGroup(groupsData[index].id)
			setSelectedRow(groupsData[index])
	},[groupsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedGroup(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleGroupsSorting = (field,direction) => {
			const sort_param = `&&sort=${field}:${direction}`
			setGroupsSortParams(sort_param)
			handleDataFetch()
	}

	const handleGroupDelete = async (s_id) => {
			
		const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/${s_id}/action`
		const method = "POST"
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				data: {"delete": {"delete-volumes": volumeDeleteCascade}},
				token,
				has_response: false
		})
		if (vt_response.status_code === groupUrlResponses.post.success_response.status_code) {
				return null
		} else {
				return vt_response.error
		}			
	}

	const onGroupDelete = async () => {
			handleGroupDeleteConfirmDialogClose()
			let err = []
			for (let s in selectedGroups) {
					const resp = await handleGroupDelete(selectedGroups[s].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onGroupDeleteConfirm = (s_list) => {
			const selected_s_list = groupsData.filter(s => 
					s_list.includes(s.id))
			setSelectedGroups([...selected_s_list])
			setGroupDeleteConfirmDialogOpen(true)
	}

	const handleGroupDeleteConfirmDialogClose = () => {
			setGroupDeleteConfirmDialogOpen(false)
	}

	const getGroupsActionsList = () => {
			let group_actions = []
			let new_action = {}
			new_action["value"] = "group_delete"
			new_action["action"] = onGroupDeleteConfirm
			new_action["keyword"] = "groupDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			group_actions.push({...new_action})
			
			return group_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleVolumeDeleteCascadeChange = (event) => {
			setVolumeDeleteCascade(event.target.checked)
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedGroup(null)
		setSelectedRow(null)
	}, [defaultAdminProject, token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)							
							
							let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail?list_volume=true${groupFilterQueryParams}${groupsSortParams}`

							const method = "GET"
							const groups_response = await volumeCinderRequest({url:url, method:method, token, })
							
							if (groups_response.status_code === groupUrlResponses.get.success_response.status_code) {
									setGroupsData(groups_response.data.groups)
									if (selectedGroup) {
											const selected_group = groups_response.data.groups.filter(item => item.id === selectedGroup)
											if (selected_group.length > 0) {
													setSelectedRow(selected_group[0])
											}
									}
							} else {
								setGroupsData([])
							}
							
							
					})();
			}

			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			groupsUrl, 
			groupFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			groupsSortParams,
			selectedGroup,
			token,
	]);

	useEffect(() => {
			(async () => {					
					
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?`
				const method = "GET"

				const volume_response = await volumeCinderRequest({url:url, method:method, token, })
				if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
						setVolumes(volume_response.data.volumes)
				}
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			volumesUrl,
			token,
	]);

	useEffect(() => {
		if (groupsData.length > 0) {
			handleGroupsDataFormatting()
		} else {
			setGroups([])
		}
	},[
		groupsData,
		handleGroupsDataFormatting
	])

	useEffect(() => {
			(async () => {
					
				let all_volume_types = []
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
				const method = "GET"

				const volume_types_response = await volumeCinderRequest({url:url, method:method, token, })
				if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
						all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
				}
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
				const response = await volumeCinderRequest({url:url, method:method, token, })
				if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
						const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
						all_volume_types = [...private_vt, ...all_volume_types]
				}
				setVolumeTypes([...all_volume_types])
				const volume_types_list = all_volume_types.map(item => {
						return {keyword: item.name, value: item.id, default: false}
				})
				setVolumeTypesList(volume_types_list)
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			volumeTypesUrl,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let all_group_types = []
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}`
				const method = "GET"

				const group_types_response = await volumeCinderRequest({url:url, method:method, token, })
				if (group_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
						all_group_types = [...all_group_types, ...group_types_response.data.group_types]
				}
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}?is_public=false`
				const response = await volumeCinderRequest({url:url, method:method, token, })
				if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
						const private_vt = response.data.group_types.filter(vt => !all_group_types.map(i => i.id).includes(vt.id))
						all_group_types = [...private_vt, ...all_group_types]
				}
				setGroupTypes([...all_group_types])
				const group_types_list = all_group_types.map(item => {
						return {keyword: item.name, value: item.id, default: false}
				})
				setGroupTypesList(group_types_list)
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			groupTypesUrl,
			token,
	]);

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const group_id = location.state ? location.state.group_id : null
							const group_index = groupsData.findIndex(g => g.id === group_id);
							if (group_index !== -1) {
									setTimeout(() => handleDetailCardOpen(group_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			groupsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		if (location.state && location.state.group_id && groups.length > 0) {
			const findGroup = groups.find(item => item.id === location.state.group_id)
			setSelectedGroup(findGroup ? findGroup : null)
			setSelectedRow(findGroup ? findGroup : null)
			navigate(location.path, {})
		}
	}, [location, navigate, groups])

	const handleActionRun = () => {
		const selected_group_list = groupsData.filter(item => selectedGroupIds.includes(item.id))
		setSelectedGroups([...selected_group_list])
		
		if (currentAction === 'group_delete') {
			setGroupDeleteConfirmDialogOpen(true)
		}
	}

	const handleDeselectAll = () => {
		setSelectedGroupIds([])
		setSelectedGroups([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedGroupIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getGroupsActionsList()}
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<GroupAddV3
					handleDataFetch={handleDataFetch}
					volumeTypes={volumeTypesList}
					volumeTypesList={volumeTypesList}
					groupTypesList={groupTypesList}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						label={defaultTexts.nameFormFieldLabel}
						size="small"
						variant="outlined"
						currentValue={searchKeyword}
						setCurrentValue={e => setSearchKeyword(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleGroupFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getGroupsHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
					<Box>
						<ServiceMenu
							parent='volumes'
							serviceMenu={serviceMenu}
							selectedItems={selectedGroupIds.length}
							allItems={groupsData.length}
							onClick={changeMenuActiveTab}
						/>
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Box
					sx={{
						fontSize: '17px',
						color: theme.palette.primary.main,
						padding: '0px 20px 10px 20px',
					}}
				>
					{defaultTexts.submenuGroups} ({selectedGroupIds.length}/{groupsData.length})
				</Box>

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedGroupIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getGroupsTable = () => {
		return (
			<GroupsTableV3 
				data={groups}
				setData={setGroups}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getGroupsActionsList()}
				sortHandler={handleGroupsSorting}
				sortParams={groupsSortParams}
				setSelectedGroupIds={setSelectedGroupIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getGroupsContent = () => {
		return (
			<>
				{ getGroupsHeader() }
				{ getGroupsTable() }
			</>
		)
	}

	const getGroupDetails = () => {
		return (
			<GroupDetailV3
				selectedRow={selectedRow}
				selectedGroup={groups.filter(s => s.id === selectedRow.id)[0]}
				widthWeight={WIDTH_WEIGHT}
				handleFetchData={handleDataFetch}
				handleNavigateToVolume={handleNavigateToVolume}
				handleDelete={onGroupDeleteConfirm}
				volumeTypes={volumeTypes}
				volumes={volumes}
				setSelectedRow={setSelectedRow}
				setSelectedGroup={setSelectedGroup}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getGroupsContent()} contentBottom={getGroupDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getGroupsHeader() }

				{ (selectedRow === null && !isLoading) && getGroupsTable() }
					
				<CustomDialog
						open={groupDeleteConfirmDialogOpen}
						onClose={handleGroupDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.groupDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.groupDeleteConfirmText}: [${selectedGroups.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onGroupDelete, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={volumeDeleteCascade}
								setCurrentValue={handleVolumeDeleteCascadeChange}
								label={defaultTexts.deleteVolumesFormFieldLabel}
								sx={{my: 1}}
						/>
				</CustomDialog>
					
				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default GroupsWrapperV3