import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { trunkDataForm } from '../../../../../../_data/openstack/neutron/trunks/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { trunksUrl as trunkUrlResponses } from '../../../../../../_api_responses/openstack/neutron/trunks/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const TrunkAddV20 = (props) => {
  const { handleDataFetch } = props
  const { subnets } = props 
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successTrunkAdd, setSuccessTrunkAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [ports, setPorts] = useState([]);

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const trunksUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
  const portsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.portsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessTrunkAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddTrunkFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in trunkDataForm) {
          if (
              trunkDataForm[n].field_type === "string" || 
              trunkDataForm[n].field_type === "select"
              ) {
              new_form_data[trunkDataForm[n].field_key] = ""
          } else if (trunkDataForm[n].field_type === "bool") {
              new_form_data[trunkDataForm[n].field_key] = trunkDataForm[n].default_value ? 
              trunkDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (trunkDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (trunkDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select")  {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }
  
  const getDataForm = () => {
      let form = [...trunkDataForm]
      
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "port_id" && subnets.length > 0) {
                      const port_filter = ports.map(p => {
                          const subnet = subnets.filter(s => s.id === p.fixed_ips[0].subnet_id)
                          return subnet.length > 0 ? {keyword: `${subnet[0].name}: ${p.fixed_ips[0].ip_address}`, value: p.id, default: false} : ''
                      })
                      form_field_options["items"] = [...port_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in trunkDataForm) {
          if (trunkDataForm[n].required && !formData[trunkDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[trunkDataForm[n].field_key] = {}
              updatedDataFormOptions[trunkDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[trunkDataForm[n].field_key]["errorText"] = defaultTexts[trunkDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const formatData = () => {
      let new_data = {...formData}
      return new_data
  }

  const onAddTrunk = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = formatData()

          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}`
          const method = "POST"
          const trunk_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {trunk: data},
              token,
          })
          if (trunk_response.status_code === trunkUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddTrunkFormReset()
              setFormDataOptions({})
          } else {
              const error_response = trunkUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === trunk_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: trunk_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = trunkUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: trunk_response.error
                  }
                  setError(errorObject)
              }
          }          
      }
      return validateFormData
  }

  useEffect(() => {
      (async () => {
          
        let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
        const method = "GET"
        const network_response = await openstackRequest({url:url, method:method, token, })
        if (network_response.status_code === trunkUrlResponses.get.success_response.status_code) {
            setPorts(network_response.data.ports)
        }
          
      })();
  },[
      neutronServiceDomain, 
      neutronServiceVersion, 
      portsUrl,
      token
  ]);

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successTrunkAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in trunkDataForm) {
              if (
                  trunkDataForm[n].field_type === "string" || 
                  trunkDataForm[n].field_type === "select"
                  ) {
                  new_form_data[trunkDataForm[n].field_key] = ""
              } else if (trunkDataForm[n].field_type === "bool") {
                  new_form_data[trunkDataForm[n].field_key] = trunkDataForm[n].default_value ? 
                  trunkDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddTrunk}
        formReset={handleAddTrunkFormReset}
        customTexts={{
          title: defaultTexts.addTrunkDialogTitle
        }}
      />

      {
        successTrunkAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successTrunkAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successTrunkAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default TrunkAddV20