import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import SubnetsTableV20 from './subnetsTableV2.0';
import SubnetDetailV20 from './subnetDetailV2.0';
import { subnetsFilterMenu } from '../../../../../../_data/openstack/neutron/subnets/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { subnetsUrl as subnetUrlResponses } from '../../../../../../_api_responses/openstack/neutron/subnets/v2.0';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import SubnetAdd from './SubnetAddV2_0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const SubnetsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const {currentTab, setCurrentTab} = props
	const [subnetsData, setSubnetsData] = useState([])
	const [subnets, setSubnets] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedSubnet, setSelectedSubnet] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [subnetDeleteConfirmDialogOpen, setSubnetDeleteConfirmDialogOpen] = useState(false);
	const [selectedSubnets, setSelectedSubnets] = useState([])
	const [subnetsSortParams, setSubnetsSortParams] = useState("")
	
	const [subnetFilterQueryParams, setSubnetFilterQueryParams] = useState("")
	const [selectedSubnetFilter, setSelectedSubnetFilter] = useState(subnetsFilterMenu[0].value)
	const [selectedSubnetFilterValue, setSelectedSubnetFilterValue] = useState("")
	const [subnetsFilter, setSubnetsFilter] = useState([...subnetsFilterMenu])

	const [networks, setNetworks] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = subnetsFilterMenu.find(item => item.value === selectedSubnetFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		if (selectedSubnetFilter === 'network_id') {
			let networks_filter = []
			networks.forEach(item => networks_filter.push({ keyword: item.name, value: item.id, default: false }))
			return networks_filter
		}

		const filterObject = subnetsFilterMenu.find(item => item.value === selectedSubnetFilter)
		return filterObject ? filterObject.items : []
	}

	const handleSubnetFilteredSearch = () => {
			if (selectedSubnetFilter && selectedSubnetFilterValue) {
					setSubnetFilterQueryParams(`${selectedSubnetFilter}=${selectedSubnetFilterValue}`)
			} else {
					setSubnetFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleSubnetsDataFormatting = useCallback(() => {
			const formatted_data = subnetsData.map((item) => {
					let new_item = {...item}
					
					const network = networks.filter(n => n.id === item.network_id)
					if (network.length > 0) {
							new_item.network_id = network[0].name
					} else {
							new_item.network_id = "-"
					}
					return new_item
			})
			setSubnets(formatted_data)
	},[
			subnetsData,
			networks
	])

	const handleSubnetFilterReset = () => {
			setSelectedSubnetFilter(subnetsFilterMenu[0].value)
			setSelectedSubnetFilterValue("")
			setSubnetFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToNetwork = (network_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			navigate("/networks",{state: {network_id: network_id}})
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedSubnet(subnetsData[index].id)
			setSelectedRow(subnetsData[index])
	},[subnetsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedSubnet(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleSubnetsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setSubnetsSortParams(sort_param)
			handleDataFetch()
	}

	const handleSubnetDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === subnetUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onSubnetDelete = async () => {
			handleSubnetDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedSubnets) {
					const resp = await handleSubnetDelete(selectedSubnets[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onSubnetDeleteConfirm = (n_list) => {
			const selected_n_list = subnetsData.filter(n => 
					n_list.includes(n.id))
			setSelectedSubnets([...selected_n_list])
			setSubnetDeleteConfirmDialogOpen(true)
	}

	const handleSubnetDeleteConfirmDialogClose = () => {
			setSubnetDeleteConfirmDialogOpen(false)
	}

	const getSubnetsActionsList = () => {
			let subnet_actions = []
			let new_action = {}
			new_action["value"] = "subnet_delete"
			new_action["action"] = onSubnetDeleteConfirm
			new_action["keyword"] = "subnetDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			subnet_actions.push({...new_action})
			
			return subnet_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedSubnet(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
						handleLoading(true)

						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}?${subnetFilterQueryParams}${subnetsSortParams}`
						const method = "GET"
						const subnet_response = await openstackRequest({url:url, method:method, token, })
						if (subnet_response.status_code === subnetUrlResponses.get.success_response.status_code) {
								setSubnetsData(subnet_response.data.subnets)
								if (selectedSubnet) {
										const selected_subnet = subnet_response.data.subnets.filter(item => item.id === selectedSubnet)
										if (selected_subnet.length > 0) {
												setSelectedRow(selected_subnet[0])
										}
								}
						} else {
							setSubnetsData([])
						}
						
						
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			subnetsUrl, 
			subnetFilterQueryParams,
			dataFetchingRequired,
			subnetsSortParams,
			selectedSubnet,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === subnetUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl,
			token
	]);

	useEffect(() => {
		if (subnetsData.length > 0) {
			handleSubnetsDataFormatting()
		} else {
			setSubnets([])
		}
	},[
		subnetsData,
		handleSubnetsDataFormatting
	])

	useEffect(() => {
			let networks_filter = []
			if (networks.length > 0) {
					networks_filter = networks.map(network => {
							return {keyword: network.name, value: network.id, default: false}
					})
			}
			
			let subnet_filter_menu = subnetsFilterMenu.map(nt => {
					let new_item = {...nt}
					
					if (nt.value === "network_id") {
							new_item.items = [...networks_filter]
					}
					return new_item
			})

			setSubnetsFilter(subnet_filter_menu)
	},[
			subnetsData,
			networks
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const subnet_id = location.state ? location.state.subnet_id : null
							const subnet_index = subnetsData.findIndex(v => v.id === subnet_id);
							if (subnet_index !== -1) {
									setTimeout(() => handleDetailCardOpen(subnet_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			subnetsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l3-networks')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedSubnetFilterValue('')
	}, [selectedSubnetFilter])

	const handleActionRun = () => {
		const actionsList = getSubnetsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedSubnets([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getSubnetsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<SubnetAdd
					handleDataFetch={handleDataFetch}
					networks={networks}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedSubnetFilterValue}
							setCurrentValue={e => setSelectedSubnetFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedSubnetFilterValue}
							setCurrentValue={setSelectedSubnetFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							self_item_titles={selectedSubnetFilter === 'network_id' ? true : false}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={subnetsFilter} 
						currentValue={selectedSubnetFilter}
						setCurrentValue={setSelectedSubnetFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleSubnetFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleSubnetFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>

					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/l3-networks'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={subnetsData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}
				
				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.subnetsTabButtonText} ({selectedIds.length}/{subnetsData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<SubnetsTableV20 
				subnetsData={subnets}
				setSubnetsData={setSubnets}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getSubnetsActionsList()}
				sortHandler={handleSubnetsSorting}
				sortParams={subnetsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<SubnetDetailV20
				selectedRow={selectedRow}
				handleDataFetch={handleDataFetch}
				handleNavigateToNetwork={handleNavigateToNetwork}
				handleDelete={onSubnetDeleteConfirm}
				networks={networks}
				setSelectedRow={setSelectedRow}
				setSelectedSubnet={setSelectedSubnet}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
					open={subnetDeleteConfirmDialogOpen}
					onClose={handleSubnetDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.subnetDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.subnetDeleteConfirmText}: [${selectedSubnets.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onSubnetDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default SubnetsWrapperV20