import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import FirewallPoliciesTableV20 from './firewallPoliciesTableV2.0';
import FirewallPolicyDetailV20 from './firewallPolicyDetailV2.0';
import { firewallPoliciesFilterMenu } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import FirewallPoliciesAddV20 from './firewallPoliciesAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const FirewallPoliciesWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, currentTab, setCurrentTab, serviceMenu, serviceSubmenu, changeMenuActiveTab } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const [firewallPoliciesData, setFirewallPoliciesData] = useState([])
	const [firewallPolicies, setFirewallPolicies] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedFirewallPolicy, setSelectedFirewallPolicy] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [firewallPolicyDeleteConfirmDialogOpen, setFirewallPolicyDeleteConfirmDialogOpen] = useState(false);
	const [selectedFirewallPolicies, setSelectedFirewallPolicies] = useState([])
	const [firewallPoliciesSortParams, setFirewallPoliciesSortParams] = useState("")
	const [auditedStateChangeDialogOpen, setAuditedStateChangeDialogOpen] = useState(false);
	const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
	const [selectedAuditedState, setSelectedAuditedState] = useState(true);
	const [selectedSharedState, setSelectedSharedState] = useState(true);
	
	const [firewallPolicyFilterQueryParams, setFirewallPolicyFilterQueryParams] = useState("")
	const [selectedFirewallPolicyFilter, setSelectedFirewallPolicyFilter] = useState(firewallPoliciesFilterMenu[0].value)
	const [selectedFirewallPolicyFilterValue, setSelectedFirewallPolicyFilterValue] = useState("")

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const firewallPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleSelectedSharedStateChange = (event) => {
			setSelectedSharedState(event.target.checked)
	}
	const handleSelectedAuditedStateChange = (event) => {
			setSelectedAuditedState(event.target.checked)
	}

	const handleSharedStateChangeDialogClose = () => {
			setSharedStateChangeDialogOpen(false)
	}

	const handleAuditedStateChangeDialogClose = () => {
			setAuditedStateChangeDialogOpen(false)
	}

	const handleSharedStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallPoliciesData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallPolicies([...selected_list])
			setSharedStateChangeDialogOpen(true)
	}

	const handleAuditedStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallPoliciesData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallPolicies([...selected_list])
			setAuditedStateChangeDialogOpen(true)
	}

	const handleChangeAuditedState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_policy: {audited: selectedAuditedState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeAuditedState = async () => {
			handleAuditedStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallPolicies) {
					const resp = await handleChangeAuditedState(selectedFirewallPolicies[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleChangeSharedState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_policy: {shared: selectedSharedState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeSharedState = async () => {
			handleSharedStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallPolicies) {
					const resp = await handleChangeSharedState(selectedFirewallPolicies[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleFirewallPolicyFilteredSearch = () => {
			if (selectedFirewallPolicyFilter && selectedFirewallPolicyFilterValue) {
					setFirewallPolicyFilterQueryParams(`${selectedFirewallPolicyFilter}=${selectedFirewallPolicyFilterValue}`)
			} else {
					setFirewallPolicyFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleFirewallPoliciesDataFormatting = useCallback(() => {
			const formatted_data = firewallPoliciesData.map((item) => {
					let new_item = {...item}

					new_item.firewall_rules = item.firewall_rules.length

					return new_item
			})
			setFirewallPolicies(formatted_data)
	},[
			firewallPoliciesData,
	])

	const handleFirewallPolicyFilterReset = () => {
			setSelectedFirewallPolicyFilter(firewallPoliciesFilterMenu[0].value)
			setSelectedFirewallPolicyFilterValue("")
			setFirewallPolicyFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedFirewallPolicy(firewallPoliciesData[index].id)
			setSelectedRow(firewallPoliciesData[index])
	},[firewallPoliciesData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedFirewallPolicy(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFirewallPoliciesSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setFirewallPoliciesSortParams(sort_param)
			handleDataFetch()
	}

	const handleFirewallPolicyDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onFirewallPolicyDelete = async () => {
			handleFirewallPolicyDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedFirewallPolicies) {
					const resp = await handleFirewallPolicyDelete(selectedFirewallPolicies[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onFirewallPolicyDeleteConfirm = (n_list) => {
			const selected_n_list = firewallPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedFirewallPolicies([...selected_n_list])
			setFirewallPolicyDeleteConfirmDialogOpen(true)
	}

	const handleFirewallPolicyDeleteConfirmDialogClose = () => {
			setFirewallPolicyDeleteConfirmDialogOpen(false)
	}

	const getFirewallPoliciesActionsList = () => {
			let firewallPolicy_actions = []
			let new_action = {}
			new_action["value"] = "audited_state_update"
			new_action["action"] = handleAuditedStateChangeDialogOpen
			new_action["keyword"] = "auditedStateChangeActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallPolicy_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "share_state_update"
			// new_action["action"] = handleSharedStateChangeDialogOpen
			// new_action["keyword"] = "shareStateChangeActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// firewallPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "firewallPolicy_delete"
			new_action["action"] = onFirewallPolicyDeleteConfirm
			new_action["keyword"] = "firewallPolicyDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallPolicy_actions.push({...new_action})
			
			return firewallPolicy_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedFirewallPolicy(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}?${firewallPolicyFilterQueryParams}${firewallPoliciesSortParams}`
							const method = "GET"
							const firewallPolicy_response = await openstackRequest({url:url, method:method, token, })
							if (firewallPolicy_response.status_code === firewallUrlResponses.get.success_response.status_code) {
									setFirewallPoliciesData(firewallPolicy_response.data.firewall_policies)
									if (selectedFirewallPolicy) {
											const selected_firewallPolicy = firewallPolicy_response.data.firewall_policies.filter(item => item.id === selectedFirewallPolicy)
											if (selected_firewallPolicy.length > 0) {
													setSelectedRow(selected_firewallPolicy[0])
											}
									}
							} else {
								setFirewallPoliciesData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			firewallPoliciesUrl, 
			firewallPolicyFilterQueryParams,
			dataFetchingRequired,
			token,
			firewallPoliciesSortParams,
			selectedFirewallPolicy
	]);

	useEffect(() => {
			if (firewallPoliciesData.length > 0) {
					handleFirewallPoliciesDataFormatting()
			} else {
				setFirewallPolicies([])
			}
	},[
			firewallPoliciesData,
			handleFirewallPoliciesDataFormatting
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const firewallPolicy_id = location.state ? location.state.firewallPolicy_id : null
							const firewallPolicy_index = firewallPoliciesData.findIndex(v => v.id === firewallPolicy_id);
							if (firewallPolicy_index !== -1) {
									setTimeout(() => handleDetailCardOpen(firewallPolicy_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			firewallPoliciesData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-fwaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getFirewallPoliciesActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedFirewallPolicies([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getFirewallPoliciesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<FirewallPoliciesAddV20
					handleDataFetch={handleDataFetch}
					firewallPolicies={firewallPolicies}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						label={defaultTexts[firewallPoliciesFilterMenu[0].keyword]}
						currentValue={selectedFirewallPolicyFilterValue}
						setCurrentValue={e => setSelectedFirewallPolicyFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFirewallPolicyFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFirewallPolicyFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-fwaas'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={firewallPoliciesData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.firewallPoliciesTabButtonText} ({selectedIds.length}/{firewallPoliciesData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<FirewallPoliciesTableV20 
				firewallPoliciesData={firewallPolicies}
				setFirewallPoliciesData={setFirewallPolicies}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getFirewallPoliciesActionsList()}
				sortHandler={handleFirewallPoliciesSorting}
				sortParams={firewallPoliciesSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<FirewallPolicyDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedFirewallPolicy={setSelectedFirewallPolicy}
				handleDataFetch={handleDataFetch}
				handleDelete={onFirewallPolicyDeleteConfirm}
				firewallPolicies={firewallPoliciesData}
				auditedStateChange={handleAuditedStateChangeDialogOpen}
				sharedStateChange={handleSharedStateChangeDialogOpen}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
						open={firewallPolicyDeleteConfirmDialogOpen}
						onClose={handleFirewallPolicyDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.fwPolicypDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.fwPolicyDeleteConfirmText}: [${selectedFirewallPolicies.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onFirewallPolicyDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				<CustomDialog
						open={auditedStateChangeDialogOpen}
						onClose={handleAuditedStateChangeDialogClose}
						dialogTitle={{
								title: defaultTexts.changeAuditedStateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onChangeAuditedState, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={selectedAuditedState}
								setCurrentValue={handleSelectedAuditedStateChange}
								label={defaultTexts.auditedStateFormFieldLabel}
								default_value={false}
						/>
				</CustomDialog>
				<CustomDialog
						open={sharedStateChangeDialogOpen}
						onClose={handleSharedStateChangeDialogClose}
						dialogTitle={{
								title: defaultTexts.changeSharedStateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onChangeSharedState, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={selectedSharedState}
								setCurrentValue={handleSelectedSharedStateChange}
								label={defaultTexts.sharedFormFieldLabel}
								default_value={false}
						/>
				</CustomDialog>
				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
		</Box>
	</>
	)
}

export default FirewallPoliciesWrapperV20