import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import VolumesWrapperV3 from './volumes/volumesWrapperV3';
import VolumeTransfersWrapperV3 from './volumes/VolumeTransfersWrapperV3';
import SnapshotsWrapperV3 from './snapshots/snapshotsWrapperV3';
import BackupsWrapperV3 from './backups/backupsWrapperV3';
import GroupsWrapperV3 from './groups/groupsWrapperV3';
import GroupSnapshotsWrapperV3 from './group_snapshots/groupSnapshotsWrapperV3';

const VolumesCinderContentV3 = (props) => {
	const theme = useTheme()
	const { navigate, location } = props

	const [serviceMenu, setServiceMenu] = useState([
		{keyword: "submenuVolumes", navigation: "/volumes", is_active: true, component: VolumesWrapperV3},
		{keyword: "submenuTransfers", navigation: "/transfers", is_active: false, component: VolumeTransfersWrapperV3},
		// {keyword: "submenuVolumeTypes", navigation: "/volume-types", is_active: false, component: VolumeTypesWrapperV3},
		{keyword: "submenuSnapshots", navigation: "/snapshots", is_active: false, component: SnapshotsWrapperV3},
		{keyword: "submenuBackups", navigation: "/backups", is_active: false, component: BackupsWrapperV3},
		{keyword: "submenuGroups", navigation: "/volume-groups", is_active: false, component: GroupsWrapperV3},
		{keyword: "submenuGroupSnapshots", navigation: "/volume-group-snapshots", is_active: false, component: GroupSnapshotsWrapperV3},
		// {keyword: "submenuGroupTypes", navigation: "/volume-group-types", is_active: false, component: () => <Box />},
		// {keyword: "submenuClusters", navigation: "/clusters", is_active: false, component: () => <Box />},
		// {keyword: "submenuLimits", navigation: "/volume-limits", is_active: false, component: () => <Box />}
	])

	const getCurrentTab = () => {
		const currentItem = serviceMenu.filter(item => item.is_active)[0]
		return currentItem
	}

	const [currentTab, setCurrentTab] = useState(getCurrentTab())

	const changeMenuActiveTab = useCallback((navigation) => {
		const updated_menu = serviceMenu.map(item => {
			if (item.navigation === navigation) {
				item.is_active = true
				setCurrentTab(item)
			} else {
				item.is_active = false
			}

			return item
		})

		setServiceMenu(updated_menu)
	}, [serviceMenu])

	useEffect(() => {
		if (location.state && location.state.menu && location.state) {
			changeMenuActiveTab(location.state.menu)
			navigate(location.path, {})
		}
	}, [navigate, location, changeMenuActiveTab])

	return (
		<Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
			<currentTab.component 
				navigate={navigate} 
				location={location}
				serviceMenu={serviceMenu}
				changeMenuActiveTab={changeMenuActiveTab}
			/>
		</Box>
	)
}

export default VolumesCinderContentV3