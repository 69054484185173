import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import VolumeTransfersTableV3 from './volumeTransfersTableV3';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import ServiceMenu from '../../../../_common/ServiceMenu';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import CustomPopover from '../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'

const SERVICE_NAME = openStackServices.volumeService
const TRANSFERS_MIN_API_VERSION = "3.55"

const VolumeTransfersWrapperV3 = (props) => {
  const { serviceMenu, changeMenuActiveTab } = props
	const { width } = useWindowDimensions()
	const theme = useTheme()

	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)

	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const [volumesData, setVolumesData] = useState([])
	const [volumeTransfersData, setVolumeTransfersData] = useState([])
	const [volumeTransfers, setVolumeTransfers] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const selectedVolume = null;
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [fetchTransferDataRequired, setFetchTransferDataRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [currentTransfersAction, setCurrentTransfersAction] = useState("");
	const [volumeTransferDeleteConfirmDialogOpen, setVolumeTransferDeleteConfirmDialogOpen] = useState(false)
	const [selectedVolumeTransfers, setSelectedVolumeTransfers] = useState([])
	
	const volumesSortParams = ''
	const volumeFilterQueryParams = ''

	const [providedAuthKey, setProvidedAuthKey] = useState("")
	const [volumeTransferAcceptDialogOpen, setVolumeTransferAcceptDialogOpen] = useState(false)
	const [providedAuthKeyError, setProvidedAuthKeyError] = useState(false)

	const [selectedVolumeTransferIds, setSelectedVolumeTransferIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	
	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const maxAPIVersion = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3").max_api_version)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	const volumeTransfersUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumeTransfersUrl)[0].url)
	
	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleTransfersDataFetch = () => {
			setFetchTransferDataRequired(true)
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleVolumeTransferDelete = async (t_id) => {			
			
			let url = ""
			if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
					url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}`
			} else {
					url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}`
			}
			
			const method = "DELETE"
			
			const vt_response = await volumeCinderRequest({
					url:url, 
					method:method,
					token,
			})

			if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
					return null
			} else {
					return vt_response.error
			}
			
	};

	const onVolumeTransferDelete = async () => {
		handleVolumeTransferDeleteConfirmDialogClose()

		let err = []

		for (let t in selectedVolumeTransfers) {
			const resp = await handleVolumeTransferDelete(selectedVolumeTransfers[t].id)
			if (resp !== null) {
				err = [...err, resp]
			}
		}

		handleTransfersDataFetch()
		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const onVolumeTransferDeleteConfirm = (t_list) => {
			const selected_t_list = volumeTransfers.filter(t => 
					t_list.includes(t.id))
			setSelectedVolumeTransfers([...selected_t_list])
			setVolumeTransferDeleteConfirmDialogOpen(true)
	}

	const handleVolumeTransferAccept = async (t_id) => {
		let url = ""
		if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}/accept`
		} else {
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}/accept`
		}
		
		const method = "POST"
		
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				data: {
						accept: {
								"auth_key": providedAuthKey
						}
				},
				token,
		})

		if (vt_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
				return true
		} else {
				return false
		}        
	}

	const onVolumeTransferAccept = async () => {
			if (providedAuthKey.length === 0) {
					setProvidedAuthKeyError(true)
					return false
			} else {
					for (let t in selectedVolumeTransfers) {
							await handleVolumeTransferAccept(selectedVolumeTransfers[t].id)
					}
					handleVolumeTransferAcceptDialogClose()
					handleTransfersDataFetch()
					handleDataFetch()
			}
	}

	const handleVolumeTransferAcceptDialogOpen = (t_list) => {
			const selected_t_list = volumeTransfers.filter(t => 
					t_list.includes(t.id))
			setSelectedVolumeTransfers([...selected_t_list])
			setVolumeTransferAcceptDialogOpen(true)
	}

	const handleVolumeTransferAcceptDialogClose = () => {
			setProvidedAuthKey("")
			setVolumeTransferAcceptDialogOpen(false)
	}

	const handleProvidedAuthKeyChange = (event) => {
			setProvidedAuthKeyError(false)
			setProvidedAuthKey(event.target.value)
	}

	const handleVolumeTransferDeleteConfirmDialogClose = () => {
			setVolumeTransferDeleteConfirmDialogOpen(false)
	}

	const getVolumeTransfersActionsList = () => {
			let volume_transfer_actions = []
			let new_action = {}
			new_action["value"] = "volume_transfer_accept"
			new_action["action"] = handleVolumeTransferAcceptDialogOpen
			new_action["keyword"] = "volumeTransferAcceptActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			volume_transfer_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "volume_transfer_delete"
			new_action["action"] = onVolumeTransferDeleteConfirm
			new_action["keyword"] = "volumeTransferDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			volume_transfer_actions.push({...new_action})
			
			return volume_transfer_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [defaultAdminProject, token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							const firstSymbol = volumeFilterQueryParams ? '&&' : ''
							const sort_params = volumesSortParams ? firstSymbol + volumesSortParams : ''				
							
							let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail${volumeFilterQueryParams}${sort_params}`
							const method = "GET"
	
							const volume_response = await volumeCinderRequest({url:url, method:method, token, })
							
							if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
									setVolumesData(volume_response.data.volumes)
									if (selectedVolume) {
											const selected_volume = volume_response.data.volumes.filter(item => item.id === selectedVolume)
											if (selected_volume.length > 0) {
													setSelectedRow(selected_volume[0])
											}
									}
							} else {
								setVolumesData([])
							}			
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumesUrl, 
			volumeFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			volumesSortParams,
			selectedVolume,
			token,
	]);
	
	useEffect(() => {
			if (fetchTransferDataRequired) {
					(async () => {                
						let url = ""
						if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
								url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/detail?`
						} else {
								url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/detail?`
						}
						
						const method = "GET"

						const volume_transfers_response = await volumeCinderRequest({url:url, method:method, token, })
						if (volume_transfers_response.status_code === volumeUrlResponses.get.success_response.status_code) {
								setVolumeTransfersData(volume_transfers_response.data.transfers)
						}                
					})();
			}
			setFetchTransferDataRequired(false)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumeTransfersUrl,
			fetchTransferDataRequired,
			defaultAdminProject,
			maxAPIVersion,
			token,
	]);

	useEffect(() => {
			const formatVolumeTransfersData = (data) => {
					const new_list = data.map(item => {
							let new_item = {...item} 

							// if (item.source_project_id) {
							// 		const source = projects.filter(p => p.id === item.source_project_id)
							// 		if (source.length > 0) {
							// 				new_item.source_project_id = source[0].name
							// 		}
							// }

							// if (item.destination_project_id) {
							// 		const destination = projects.filter(p => p.id === item.destination_project_id)
							
							// 		if (destination.length > 0) {
							// 				new_item.destination_project_id = destination[0].name
							// 		}
							// }

							const volume = volumesData.filter(v => v.id === item.volume_id)
							if (volume.length > 0) {
									new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
							}
							
							return new_item
					})
					setVolumeTransfers(new_list)
			}

			formatVolumeTransfersData(volumeTransfersData)

	},[
			volumeTransfersData,
			volumesData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	const handleActionRun = () => {
		const selected_transfer_list = volumeTransfersData.filter(item => selectedVolumeTransferIds.includes(item.id))
		setSelectedVolumeTransfers([...selected_transfer_list])
		
		if (currentAction === 'volume_transfer_accept') {
			setVolumeTransferAcceptDialogOpen(true)
		}

		if (currentAction === 'volume_transfer_delete') {
			setVolumeTransferDeleteConfirmDialogOpen(true)
		}
	}

	const handleDeselectAll = () => {
		setSelectedVolumeTransferIds([])
		setSelectedVolumeTransfers([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedVolumeTransferIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getVolumeTransfersActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}
			</Stack>
		)
	}

	const getTransfersHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 5px 20px',
						width: "100%"
					}}
				>
					<Box>
						<ServiceMenu
							parent='volumes'
							serviceMenu={serviceMenu}
							selectedItems={selectedVolumeTransferIds.length}
							allItems={volumeTransfersData.length}
							onClick={changeMenuActiveTab}
						/>
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Box
					sx={{
						fontSize: '17px',
						color: theme.palette.primary.main,
						padding: '0px 20px 10px 20px',
					}}
				>
					{defaultTexts.submenuTransfers} ({selectedVolumeTransferIds.length}/{volumeTransfersData.length})
				</Box>

				<Box
					sx={{ padding: '0px 20px 20px 10px', }}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedVolumeTransferIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
				</Box>
			</>
		)
	}

	const getTransfersTable = () => {
		return (
			<VolumeTransfersTableV3 
				volumeTransfersData={volumeTransfers}
				setVolumeTransfersData={setVolumeTransfers}
				currentAction={currentTransfersAction}
				setCurrentAction={setCurrentTransfersAction}
				actionsTexts={defaultTexts}
				actionsList={getVolumeTransfersActionsList()}
				setSelectedVolumeTransferIds={setSelectedVolumeTransferIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}
	
	return (
		<>
			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getTransfersHeader() }

				{ (selectedRow === null && !isLoading) && getTransfersTable() }

				<CustomDialog
						open={volumeTransferDeleteConfirmDialogOpen}
						onClose={handleVolumeTransferDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.volumeTransferDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.volumeTransferDeleteConfirmText}: [${selectedVolumeTransfers.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onVolumeTransferDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				
				<CustomDialog
						open={volumeTransferAcceptDialogOpen}
						onClose={handleVolumeTransferAcceptDialogClose}
						dialogTitle={{
								title: defaultTexts.acceptVolumeTransferActionTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.submitButtonText, 
								onClick: onVolumeTransferAccept, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomTextField
								required={true}
								error={providedAuthKeyError}
								errorText={defaultTexts.requiredFormFieldError}
								currentValue={providedAuthKey}
								setCurrentValue={handleProvidedAuthKeyChange}
								label={defaultTexts.authKeyFormFieldLabel}
								size="large"
								sx={{my: 1, width: '90%'}}
						/>
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}
			</Box>
		</>
	)
}

export default VolumeTransfersWrapperV3