import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import RouterSpecsV20 from './routerSpecsV2.0';
import RouterStaticRoutesV20 from './routerStaticRoutesV2.0';
import RouterInterfacesV20 from './routerInterfacesV2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { routersUrl as routerUrlResponses } from '../../../../../../_api_responses/openstack/neutron/routers/v2.0';
import { openStackServices } from '../../../../../../config/openStackConstants';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from '../../../../../_common/CustomDialog';
import { routerDataUpdateForm, routerStaticRouteForm } from '../../../../../../_data/openstack/neutron/routers/v2.0';
import CustomText from '../../../../../_common/CustomText';
import Stack from '@mui/material/Stack';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';

const SERVICE_NAME = openStackServices.networkService

const RouterDetailV20 = (props) => {
	const theme = useTheme()
	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const { selectedRow, setSelectedRow, setSelectedRouter, handleDataFetch } = props
	const { handleDelete, onAdminStateChange } = props
	const { networks } = props
	const { handleNavigateToNetwork } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const token = useSelector(state => state.profile.x_auth_token)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);

	const [routerActions, setRouterActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [routerUpdateData, setRouterUpdateData] = useState({})
	const [updateRouterDialogOpen, setUpdateRouterDialogOpen] = useState(false)

	const [staticRoutes, setStaticRoutes] = useState([...selectedRow.routes]);
	const [updateStaticRoutesDialogOpen, setUpdateStaticRoutesDialogOpen] = useState(false);
	const [routerPorts, setRouterPorts] = useState([])
	const [subnets, setSubnets] = useState([])
	const [projectSubnets, setProjectSubnets] = useState([])
	const [selectedSubnet, setSelectedSubnet] = useState("")
	const [addRouterInterfaceDialogOpen, setAddRouterInterfaceDialogOpen] = useState(false)
	const [selectedSubnetError, setSelectedSubnetError] = useState(false)
		
	const [routerSubMenu, setRouterSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/router-details", is_active: true},
			{keyword: "submenuInterfaces", navigation: "/router-interfaces", is_active: false},
			{keyword: "submenuStaticRoutes", navigation: "/router-static-routes", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/router-details")
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const routersUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
	const portsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)

	const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}/${selectedRow.id}`

	const getFormattedRouterData = useCallback((data) => {
			let formatted_data = {...data}
			
			if (formatted_data.external_gateway_info) {
				const network = networks.filter(n => n.id === data.external_gateway_info.network_id)[0]
				formatted_data.external_gateway_info.network_id = network ? network.name : data.external_gateway_info.network_id
			}			
			
			return formatted_data
	},[networks])


	const handleRouterDetailTabChange = useCallback((navigation) => {
			let newRouterSubmenuData = routerSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setRouterSubMenu(newRouterSubmenuData)
			setCurrentTab(navigation)
	},[
			routerSubMenu
	])

	const handleChangeAdminStateDialogOpen = useCallback(() => {
			onAdminStateChange([selectedRow.id])
			setCurrentAction("")
	},[selectedRow, onAdminStateChange])

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}			
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleStaticRouteFormDataChange = (event,field_key,index) => {
			let new_form_data = [...staticRoutes]
			if (routerStaticRouteForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[index][field_key] = event.target.checked
			} else if (routerStaticRouteForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[index][field_key] = event.trim()
			} else {
					new_form_data[index][field_key] = event.target.value.trim()
			}
			setStaticRoutes(new_form_data)
	}

	const handleStaticRouteAdd = () => {
			let new_form_data = {}
			for (const n in routerStaticRouteForm) {
					if (
							routerStaticRouteForm[n].field_type === "string" || 
							routerStaticRouteForm[n].field_type === "select"
							) {
							new_form_data[routerStaticRouteForm[n].field_key] = ""
					} else if (routerStaticRouteForm[n].field_type === "bool") {
							new_form_data[routerStaticRouteForm[n].field_key] = routerStaticRouteForm[n].default_value ? 
							routerStaticRouteForm[n].default_value : 
							false
					}
			}
			const updated_data = [...staticRoutes]
			updated_data.push(new_form_data)
			setStaticRoutes(updated_data)
	}

	const handleStaticRouteRemove = () => {
			let new_data = [...staticRoutes]
			new_data.pop()
			setStaticRoutes(new_data)
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			let form_data = [...form]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getStaticRoutesForm = () => {
			return (
					<FormGroup>
							{staticRoutes.map((serv,index) => {
									return (
											<div key={index}>
											<CustomText>{defaultTexts.staticRoutesFormFieldLabel} {index + 1}</CustomText>
													{routerStaticRouteForm.map(item => {
															return (
																	getFormFieldComponent(
																			item,
																			serv,
																			handleStaticRouteFormDataChange,
																			defaultTexts[item.label],
																			{index: index}
																	)
															)
													})}
											</div>
									)
									})}
									{staticRoutes.length > 0 && <MinusButton 
											style={{ transform: 'scale(0.7)' }}
											sx={{height: 5}}
											onClick={handleStaticRouteRemove}
									/>}
									{<Stack 
											direction="row" 
											spacing={2} 
											alignItems="center"
											onClick={handleStaticRouteAdd}
											sx={{cursor: "pointer"}}
									>
											<PlusButton 
													style={{ transform: 'scale(0.7)' }}
													sx={{height: 5}}
													onClick={handleStaticRouteAdd}
											/>
											<CustomText>
													{defaultTexts.addStaticRouteFormFieldLabel}
											</CustomText>
									</Stack>}
					</FormGroup>
			)
	}

	const handleRouterUpdateDataChange = (event,field_key) => {
			let new_form_data = {...routerUpdateData}
			if (routerDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (routerDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setRouterUpdateData(new_form_data)
	}

	const handleUpdateRouterDialogOpen = () => {
			setUpdateRouterDialogOpen(true)
	}

	const handleUpdateRouterDialogClose = () => {
			setUpdateRouterDialogOpen(false)
	}

	const onRouterUpdate = useCallback(async (event,data) => {
			let updated_data = {...routerUpdateData}
			if (data) {
					updated_data = data
			}
			
			const method = "PUT"
			const router_response = await openstackRequest({
					url: common_url, 
					method: method, 
					data: {router: updated_data},
					token,
			})
			if (router_response.status_code === routerUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleUpdateRouterDialogClose()
					handleDataFetch()
			} else {
					const error_response = routerUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === router_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					} else {
							const error_response = routerUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			token,
			routerUpdateData,
			handleDataFetch
	])

	const onRouterInterfaceAdd = useCallback(async () => {
			if (selectedSubnet.length === 0) {
					setSelectedSubnetError(true)
					return false
			}
			
			const method = "PUT"
			const router_response = await openstackRequest({
					url: `${common_url}/add_router_interface`, 
					method: method, 
					data: {subnet_id: selectedSubnet},
					token,
			})
			if (router_response.status_code === routerUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleAddRouterInterfaceDialogClose()
					handleDataFetch()
			} else {
					const error_response = routerUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === router_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					} else {
							const error_response = routerUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			token,
			handleDataFetch,
			selectedSubnet
	])

	const onRouterInterfaceRemove = useCallback(async (subnet_id) => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			const method = "PUT"
			const router_response = await openstackRequest({
					url: `${common_url}/remove_router_interface`, 
					method: method, 
					data: {port_id: subnet_id},
					token,
			})
			if (router_response.status_code === routerUrlResponses.put.success_response.status_code) {
					handleDataFetch()
			} else {
					const error_response = routerUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === router_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					} else {
							const error_response = routerUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: router_response.error
							}
							setError(errorObject)
					}
			}
		}			
	},[
			common_url,
			token,
			handleDataFetch,
			isSuspended,
			setSuspendedDialogOpen,
	])

	const handleAddRouterInterfaceDialogOpen = () => {
			setAddRouterInterfaceDialogOpen(true)
	}

	const handleAddRouterInterfaceDialogClose = () => {
			setSelectedSubnet("")
			setSelectedSubnetError(false)
			setAddRouterInterfaceDialogOpen(false)
	}

	const handleSelectedSubnetChange = (event) => {
			setSelectedSubnetError(false)
			setSelectedSubnet(event)
	}

	const handleUpdateStaticRoutesDialogOpen = () => {
			setUpdateStaticRoutesDialogOpen(true)
	}

	const handleUpdateStaticRoutesDialogClose = () => {
			setStaticRoutes([...selectedRow.routes])
			setUpdateStaticRoutesDialogOpen(false)
	}

	const onStaticRoutesUpdate = async () => {
			const data = {}
			data["routes"] = [...staticRoutes.filter(
					item => item.destination.length > 0 &&
					item.nexthop.length > 0
			)]
			await onRouterUpdate(undefined,data)
			handleUpdateStaticRoutesDialogClose()
	}

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			routerActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			let router_actions = []
			let new_action = {}
			new_action["value"] = "update_router"
			new_action["action"] = handleUpdateRouterDialogOpen
			new_action["keyword"] = "routerUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			router_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "admin_state_update"
			new_action["action"] = handleChangeAdminStateDialogOpen
			new_action["keyword"] = "changeAdminStateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			router_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "add_router_interface"
			new_action["action"] = handleAddRouterInterfaceDialogOpen
			new_action["keyword"] = "addRouterInterfaceActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			router_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "update_static_routes"
			new_action["action"] = handleUpdateStaticRoutesDialogOpen
			new_action["keyword"] = "routerUpdateStaticRoutesActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			router_actions.push({...new_action})
			
			setRouterActions(router_actions)
	},[
			selectedRow,
			handleChangeAdminStateDialogOpen
	])

	useEffect(() => {
			(async () => {
					
				const method = "GET"
				const router_response = await openstackRequest({
						url: `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}?device_id=${selectedRow.id}`, 
						method: method, 
						token,
				})
				if (router_response.status_code === routerUrlResponses.get.success_response.status_code) {
						setRouterPorts(router_response.data.ports)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			portsUrl,
			token,
			selectedRow
	]);

	useEffect(() => {
			(async () => {

				const method = "GET"
				const s_response = await openstackRequest({
						url: `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`, 
						method: method, 
						token,
				})
				if (s_response.status_code === routerUrlResponses.get.success_response.status_code) {
						setSubnets(s_response.data.subnets)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			subnetsUrl,
			token,
			selectedRow
	]);

	useEffect(() => {
			if (Object.keys(routerUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in routerDataUpdateForm) {
							new_form_data[routerDataUpdateForm[n].field_key] = selectedRow[routerDataUpdateForm[n].field_key]
					}
					setRouterUpdateData(new_form_data)
			}
	},[routerUpdateData, selectedRow]);

	useEffect(() => {
			let router_subnets = []
			for (let p in routerPorts) {
					const port_subnets = routerPorts[p].fixed_ips.map(r => r.subnet_id)
					router_subnets = [...router_subnets, ...port_subnets]
			}
			const project_subnets = subnets.filter(s => 
					s.project_id === selectedRow.project_id && 
					!router_subnets.includes(s.id))
			const subnet_filters = project_subnets.map(s => {
					return {keyword: `${s.name} (${s.cidr})`, value: s.id, default: false}
			})

			setProjectSubnets(subnet_filters)
	},[
			subnets,
			selectedRow,
			routerPorts
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])

	useEffect(() => {
		setCurrentTab('/router-details')
		setRouterSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/router-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={routerSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleRouterDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedRouter}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{currentTab === "/router-details" &&
					<RouterSpecsV20
							routerData={getFormattedRouterData(selectedRow)}
							selectedRow={selectedRow}
							handleNavigateToNetwork={handleNavigateToNetwork}
					/>
				}
				{currentTab === "/router-interfaces" &&
						<RouterInterfacesV20
								attachedInterfaces={routerPorts}
								subnets={subnets}
								networks={networks}
								onRouterInterfaceRemove={onRouterInterfaceRemove}
						/>
				}
				{currentTab === "/router-static-routes" &&
						<RouterStaticRoutesV20
								staticRoutes={selectedRow.routes}
						/>
				}
			</Box>

			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
						padding: '0px 20px 0px 12px',
					}} 
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={routerActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[routerActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			}

			<CustomDialog
				open={updateRouterDialogOpen}
				onClose={handleUpdateRouterDialogClose}
				dialogTitle={{
						title: defaultTexts.updateRouterActionTitle, 
						sx: {color: 'primary.main'}}}
				dialogBody={{
						text: "", 
						sx: {color: 'text.primary'}}}
				actionButtons={[{
						title: defaultTexts.submitButtonText, 
						onClick: onRouterUpdate, 
						sx: {color: 'primary.main'}}]}
			>
				{
					getDataForm(
						routerDataUpdateForm,
						{},
						routerUpdateData,
						handleRouterUpdateDataChange
					)
				}
			</CustomDialog>

			<CustomDialog
				open={addRouterInterfaceDialogOpen}
				onClose={handleAddRouterInterfaceDialogClose}
				dialogTitle={{
						title: defaultTexts.addRouterInterfaceActionTitle, 
						sx: {color: 'primary.main'}}}
				dialogBody={{
						text: "", 
						sx: {color: 'text.primary'}}}
				actionButtons={[{
						title: defaultTexts.submitButtonText, 
						onClick: onRouterInterfaceAdd, 
						sx: {color: 'primary.main'}}]}
			>
				<CustomSelectField
						currentValue={selectedSubnet}
						setCurrentValue={handleSelectedSubnetChange}
						label={defaultTexts.subnetFormFieldLabel}
						items={projectSubnets}
						self_item_titles={true}
						empty={false}
						required={true}
						error={selectedSubnetError}
						errorText={defaultTexts.requiredFormFieldError}
						sx={{width: "80%", m: 1}}
				/>
			</CustomDialog>

			<CustomDialog
				open={updateStaticRoutesDialogOpen}
				onClose={handleUpdateStaticRoutesDialogClose}
				dialogTitle={{
						title: defaultTexts.routerUpdateStaticRoutesActionTitle, 
						sx: {color: 'primary.main'}}}
				dialogBody={{
						text: "", 
						sx: {color: 'text.primary'}}}
				actionButtons={[{
						title: defaultTexts.submitButtonText, 
						onClick: onStaticRoutesUpdate, 
						sx: {color: 'primary.main'}}]}
			>
				{getStaticRoutesForm()}
			</CustomDialog>

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
}

export default RouterDetailV20