import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import Checkbox from '@mui/material/Checkbox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { TbTopologyBus } from "react-icons/tb";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    width: "100%",
    alignItems: "start",
    pl: 3
}

const NetworkSegmentsV20 = (props) => {
    const { networkSubnets } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    
    const theme = useTheme();

    return (
        <React.Fragment>
            <WrapperBox
                sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <TbTopologyBus
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.networkSubnetsTitleTexts}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {networkSubnets.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    {false && <TableCell align="left">
                                    {networkSubnets.length > 0 && 
                                        <IconButton 
                                            disabled={true}
                                        >
                                            <DeleteIcon 
                                                color="primary"
                                            />
                                        </IconButton>}
                                    </TableCell>}
                                    <TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.ipVersionFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.cidrFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.gatewayFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.dhcpEnabledFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {networkSubnets.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {false && <TableCell>
                                        <Checkbox
                                            color="primary"
                                            //checked={selectedSegmentsToDelete.includes(index)}
                                            //onChange={(event) => handleSelectedSegmentToDeleteChange(event,index)}
                                        />
                                    </TableCell>}
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                        sx={{color: "primary.main"}}
                                    >
                                        {row.name ? row.name : row.id}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.ip_version}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.cidr}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.gateway_ip}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.enable_dhcp ? defaultTexts.formValueYes : defaultTexts.formValueNo}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
    </React.Fragment>
    )
};

export default NetworkSegmentsV20;