import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import Box from '@mui/material/Box';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { BsHddNetwork } from "react-icons/bs";
import { NetworkSlash } from "@phosphor-icons/react";
import { CgArrowLongRight } from "react-icons/cg";

const VolumeSpecsV3 = (props) => {
	const theme = useTheme()

	const { volumeData, selectedRow, attachedServers, handleNavigateToServer, handleNavigateToVolumeGroup } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const hasAttachement = attachedServers.length > 0

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '320px',
		justifyContent: 'flex-start',
	}
	
	return (
		<>
			<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
					<Grid item xs={12} lg={5}>
							<WrapperBox sx={detailTabCardConfig}>
									<Stack direction="column" spacing={1} sx={{width: "90%"}}>
											<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.nameFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.name ? volumeData.name : ""}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.idFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData ? volumeData.id : ""}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.statusFormFieldLabel}
													</CustomText>
													<CustomText size="p" sx={{ color: (volumeData.status === 'available' || volumeData.status === 'in-use') ? theme.palette.success.main : theme.palette.warning.main }}>
															{volumeData.status.toUpperCase()}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.createTimeHeaderLabel}
													</CustomText>
													<CustomText size="p">
															{new Date(volumeData.created_at).toLocaleString()}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.updatedAtFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{new Date(volumeData.updated_at).toLocaleString()}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.bootableFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.bootable  ? defaultTexts.formValueYes : defaultTexts.formValueNo}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.availabilityZoneFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.availability_zone ? volumeData.availability_zone : "-"}
													</CustomText>
											</Stack>
											<Divider />	
									</Stack>
							</WrapperBox>
					</Grid>
					<Grid item xs={12} lg={4}>
							<WrapperBox sx={detailTabCardConfig}>
									<Stack 
											direction="column" 
											spacing={1} 
											sx={{width: "90%"}}
									>
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.sizeFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.size}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.volumeSourceFormFieldLabel}
													</CustomText>
													{volumeData.snapshot_id && <CustomText size="p">
															{defaultTexts.snapshotFormFieldLabel} {volumeData.snapshot_id}
													</CustomText>}
													{volumeData.source_volid && <CustomText size="p">
															{defaultTexts.volumeFormFieldLabel} {volumeData.source_volid}
													</CustomText>}
													{Object.keys(volumeData).includes("volume_image_metadata") && <CustomText size="p">
															{defaultTexts.imageFormFieldLabel} {volumeData.volume_image_metadata.image_name}
											</CustomText>}
											</Stack>
											<Divider />
											
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.volumeTypeFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.volume_type ? volumeData.volume_type : "-"}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.encryptedFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.encrypted ? 
																	defaultTexts.formValueYes : defaultTexts.formValueNo}
													</CustomText>
											</Stack>
											<Divider />
											<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
                      >
												<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
												>
													{defaultTexts.groupFormFieldLabel}
												</CustomText>
												{
													volumeData.group_id ? <Box 
														sx={{cursor: "pointer", color: "primary.main"}}
														onClick={() => handleNavigateToVolumeGroup(selectedRow.group_id, "/volume-groups")}
													>
														<CustomText size="p">
																{volumeData.group_id}
														</CustomText>
													</Box> : 
													<CustomText size="p">-</CustomText>
												}
                      </Stack>
											<Divider />
											<Stack 
													direction="row" 
													justifyContent="space-between" 
													alignItems="center"
											>
													<CustomText 
															size="p" 
															sx={{color: "text.secondary"}}
													>
															{defaultTexts.descriptionFormFieldLabel}
													</CustomText>
													<CustomText size="p">
															{volumeData.description ? volumeData.description : "-"}
													</CustomText>
											</Stack>
											<Divider />
									</Stack>
							</WrapperBox>
					</Grid>
					<Grid item xs={12} lg={3}>
						<WrapperBox sx={detailTabCardConfig}>
							<Stack 
								direction="column" 
								spacing={1} 
								sx={{width: "90%"}}
							>
								<Box
									sx={{
										color: theme.palette.primary.main
									}}
								>
									{defaultTexts.attachedToServerLabel}
								</Box>
								<Box
									sx={{										
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'space-evenly',
										height: '200px',
									}}
								>
									{
										hasAttachement ? (
											<>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'flex-start',
													}}
												>
													<Box
														sx={{
															color: theme.palette.secondary.main,
															cursor: 'pointer',
															textDecoration: 'underline',
															'&:hover': {
																textDecoration: 'none',
															}
														}}
														onClick={() => handleNavigateToServer(attachedServers[0].id)}
													>
														{ attachedServers[0].name }
													</Box>
													<Box
														sx={{
															color: theme.palette.customGrayDark,
															padding: '5px 20px 0px 20px',
														}}
													>
														<CgArrowLongRight size={36} />
													</Box>
													<Box>{attachedServers[0].device}</Box>
												</Box>
												<Box sx={{ color: theme.palette.customGrayDark }}>
													<BsHddNetwork size={120} />
												</Box>												
											</>
										) : (
											<Box sx={{ color: theme.palette.customGray, }}>
												<NetworkSlash size={120} weight='light' />
												<Box>{defaultTexts.notAttachedLabel}</Box>
											</Box>
										)
									}							
								</Box>
							</Stack>
						</WrapperBox>
					</Grid>
			</Grid>
		</>
	)
}

export default VolumeSpecsV3