import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../_common/WrapperBox';
import { openstackRequest } from '../../../../../_network/openstack_request';
import { keypairsUrl as keypairsUrlResponses } from '../../../../../_api_responses/openstack/compute/keypairs/v2.1';
import { openStackServices } from '../../../../../config/openStackConstants';
import { computeNovaConstants } from '../../../../../config/openStackConstants';
import ComputeKeypairsTableV21 from './computeKeypairsTableV2_1';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomText from '../../../../_common/CustomText';
import { Divider } from '@mui/material';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import ServiceMenu from '../../../../_common/ServiceMenu';
import ComputeKeypairAddV21 from './computeKeypairAddV2_1';
import CustomPopover from '../../../../_common/CustomPopover';
import useWindowDimensions from '../../../../_common/WindowDimensions'
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'

const SERVICE_NAME = openStackServices.computeService

const ComputeKeypairsWrapperV21 = (props) => {
	const { serviceMenu, changeMenuActiveTab } = props
	const theme = useTheme()
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState()
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	//const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const { width } = useWindowDimensions()

	// const [filterMenu, setFilterMenu] = useState(keypairsFilterMenu)
	const [keypairsData, setKeypairsData] = useState([]);
	//const { width } = useWindowDimensions();
	//const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
	//const [detailCardOpen, setDetailCardOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	// const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
	// const [selectedFilterValue, setSelectedFilterValue] = useState("");
	// const [filterQueryParams, setFilterQueryParams] = useState("")
	const [currentAction, setCurrentAction] = useState("");
	const [selectedKeypair, setSelectedKeypair] = useState({})
	const [showKeypairDetailsOpen, setShowKeypairDetailsOpen] = useState(false)
	const [deselectAll, setDeselectAll] = useState(false)

	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [fetchDataRequired, setFetchDataRequired] = useState(true);

	const [selectedIds, setSelectedIds] = useState([])

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const keypairsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)

	const handleKeypairDataFormatting = useCallback((data) => {
			const formatted_data = data.map(item => {
					let new_item = {...item.keypair}
					new_item["public_key_details"] = 
					<WrapperBox
							sx={{
									border: 1,
									borderColor: "primary",
									borderRadius: 1,
									p: 1,
									"&:hover": {backgroundColor: "primary.main"}
							}}
					>
							{defaultTexts.showProjectsAccessTableLabelText}
					</WrapperBox>
					
					return new_item
			})

			setKeypairsData(formatted_data)
	},[defaultTexts])

	const handleShowKeypairDetails = useCallback((row) => {
			setSelectedKeypair(keypairsData[row])
			setShowKeypairDetailsOpen(true)
	},[keypairsData])

	const handleShowKeypairDetailsClose = () => {
			setSelectedKeypair({})
			setShowKeypairDetailsOpen(false)
	}
	
	//const handleSuccessDialogClose = () => {
	//    setSuccessDialogOpen(false);
	//};

	const handleDataFetch = () => {
			setFetchDataRequired(true)
	}

	// const handleFilteredSearch = () => {
	// 		if (selectedFilter && selectedFilterValue) {
	// 				setFilterQueryParams(`?${selectedFilter}=${selectedFilterValue}`)
	// 		} else {
	// 				setFilterQueryParams("")
	// 		}
	// }

	// const handleFilterReset = () => {
	// 		setSelectedFilter(filterMenu[0].value)
	// 		setSelectedFilterValue("")
	// 		setFilterQueryParams("")
	// }

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	//const handleDetailCardOpen = (index) => {
	//    setSelectedRow(flavorsData[index])
	//    setDetailCardOpen(true)
	//};

	//const handleDetailCardClose = () => {
	//    setDetailCardOpen(false)
	//    setSelectedRow(null)
	//};

	const handleDeleteKeypairAction = async (keypair_name, token) => {
		const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}/${keypair_name}`
		const method = "DELETE"
		await openstackRequest({url:url, method:method, token: token})
		handleDataFetch()
	}

	const onKeypairDelete = async (keypair_list) => {
		setIsLoading(true)
		
		for (let k in keypair_list) {
			await handleDeleteKeypairAction(keypair_list[k], token)
		}
		
		setCurrentAction("")
		setIsLoading(false)
		handleDataFetch()
	}

	const getKeypairsActionsList = () => {
			let keypair_actions = []
			let new_action = {}

			new_action["value"] = "keypair_delete"
			new_action["action"] = onKeypairDelete
			new_action["keyword"] = "keypairDeleteActionLabel"
			new_action["button_text"] = "applyButtonTitleText"
			keypair_actions.push({...new_action})
			
			return keypair_actions
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	useEffect(() => {
		setFetchDataRequired(true)
	}, [token])
	
	useEffect(() => {
			handleLoading(true)
			if (fetchDataRequired) {
			(async () => {
					
				const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
				const method = "GET"

				const keypairs_response = await openstackRequest({url:url, method:method, token, })
				if (keypairs_response.status_code === keypairsUrlResponses.get.success_response.status_code) {
						handleKeypairDataFormatting([...keypairs_response.data.keypairs])
						if (selectedRow) {
								let new_data = keypairs_response.data.keypairs.filter(keypair => keypair.keypair.name === selectedRow.name)
								if (new_data.length > 0) {
										setSelectedRow(new_data[0])
								} else {
										setSelectedRow(null)
								}
						}
				} else {
						const error_response = keypairsUrlResponses.get.error_response.filter(
								error_item => error_item.status_code === keypairs_response.status_code)
						if (error_response.length > 0) {
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: keypairs_response.error
								}
								setError(errorObject)
						} else {
								const error_response = keypairsUrlResponses.get.error_response.filter(
										error_item => error_item.status_code === "unknown")
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: keypairs_response.error
								}
								setError(errorObject)
						}
				}
				
				
			})();
			
			}
			
			setTimeout(()=>{setFetchDataRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			keypairsUrl, 
			fetchDataRequired,
			handleKeypairDataFormatting,
			token,
			selectedRow
	]);

	const handleActionRun = () => {
		const actionsList = getKeypairsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getKeypairsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}
				
				<ComputeKeypairAddV21
					handleDataFetch={handleDataFetch}
					defaultTexts={defaultTexts}
				/>
			</Stack>
		)
	}

	const getKeypairsHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
					<Box>
						<ServiceMenu
							parent='instances'
							serviceMenu={serviceMenu}
							selectedItems={selectedIds.length}
							allItems={keypairsData.length}
							onClick={changeMenuActiveTab}
						/>
					</Box>

					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Box
					sx={{ 
						fontSize: '17px',
						color: theme.palette.primary.main,
						padding: '0px 20px 5px 20px',
						}}
				>
					{defaultTexts.submenuKeypairs} ({selectedIds.length}/{keypairsData.length})
				</Box>

				<Box sx={{ padding: '0px 20px 5px 10px' }}>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>	
				</Box>
			</>
		)
	}

	const getKeypairsTable = () => {
		return (
			<ComputeKeypairsTableV21 
				keypairsData={keypairsData}
				setKeypairsData={setKeypairsData}
				handleCellClick={{public_key_details: handleShowKeypairDetails}}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getKeypairsActionsList()}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	return (
		<>
			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ getKeypairsHeader() }
				
				{ !isLoading && getKeypairsTable() }

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>Details:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<CustomDialog
						open={showKeypairDetailsOpen}
						onClose={handleShowKeypairDetailsClose}
						dialogTitle={{
								title: defaultTexts.keypairDetailsDialogTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
				>
								{ 
										<React.Fragment>
												<WrapperBox 
														sx={{
																my: 1, 
																alignItems: "start", 
																flexDirection: "row",
																justifyContent: "flex-start"
														}}
												>
														<CustomText size="p" sx={{mr: 1, color: "text.secondary"}}>{defaultTexts.nameFormFieldLabel}:</CustomText>
														<CustomText size="p" sx={{ml: 1}}>{selectedKeypair.name}</CustomText> 
												</WrapperBox>
												<Divider />
												<WrapperBox 
														sx={{
																my: 1, 
																alignItems: "start", 
																flexDirection: "row",
																justifyContent: "flex-start"
														}}
												>
														<CustomText size="p" sx={{mr: 1, color: "text.secondary"}}>{defaultTexts.fingerprintFormFieldLabel}:</CustomText>
														<CustomText size="p" sx={{ml: 1}}>{selectedKeypair.fingerprint}</CustomText> 
												</WrapperBox>
												<Divider />
												<div style={{'white-space': 'pre-wrap', 'overflow-wrap': 'break-word'}}>
														<p>{selectedKeypair.public_key}</p>
												</div>
										</React.Fragment>
								}
				</CustomDialog>
			</Box>
		</>
	)
}

export default ComputeKeypairsWrapperV21