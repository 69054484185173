import React from 'react'
import { Route, Routes } from "react-router-dom"
import HomeWrapper from './components/home/components/homeWrapper'
import ComputeNovaWrapper from './components/compute_nova/components/computeNovaWrapper'
import VolumesCinderWrapper from './components/volumes_cinder/components/volumesCinderWrapper'
import ImagesGlanceWrapper from './components/images_glance/components/imagesGlanceWrapper'
import NetworksNeutronWrapper from './components/networks_neutron/components/networksNeutronWrapper'
import LimitsWrapper from './components/limits/components/LimitsWrapper'
import PaymentsWrapper from './components/billing/components/PaymentsWrapper'
import BillingWrapper from './components/billing/components/BillingWrapper'
import CustomerSupportWrapper from './components/support/CustomerSupportWrapper'
import useAutoLogout from './components/_common/_hooks/sessionAutoLogout'

const AppRoutes = (props) => {
  useAutoLogout()

  return (
    <Routes>
      <Route exact path="/" element={<HomeWrapper />} />  
      <Route exact path="/instances" element={<ComputeNovaWrapper />} />
      <Route exact path="/storage" element={<VolumesCinderWrapper />} />
      <Route exact path="/images" element={<ImagesGlanceWrapper />} />
      <Route exact path="/networks" element={<NetworksNeutronWrapper />} />
      <Route exact path="/limits" element={<LimitsWrapper />} />
      <Route exact path="/billing" element={<BillingWrapper />} />
      <Route exact path="/payments" element={<PaymentsWrapper />} />
      <Route exact path="/support" element={<CustomerSupportWrapper />} />
      <Route path='*' element={<HomeWrapper />} />
    </Routes>
  )
}

export default AppRoutes