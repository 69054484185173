import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import SecurityGroupsTableV20 from './securityGroupsTableV2.0';
import SecurityGroupDetailV20 from './securityGroupDetailV2.0';
import { securityGroupsFilterMenu } from '../../../../../../_data/openstack/neutron/security/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import SecurityGroupAddV20 from './securityGroupAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const SecurityGroupsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, serviceMenu, serviceSubmenu, changeMenuActiveTab } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const {currentTab, setCurrentTab} = props
	const [securityGroupsData, setSecurityGroupsData] = useState([])
	const [securityGroups, setSecurityGroups] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedSecurityGroup, setSelectedSecurityGroup] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [securityGroupDeleteConfirmDialogOpen, setSecurityGroupDeleteConfirmDialogOpen] = useState(false);
	const [selectedSecurityGroups, setSelectedSecurityGroups] = useState([])
	const [securityGroupsSortParams, setSecurityGroupsSortParams] = useState("")
	
	const [securityGroupFilterQueryParams, setSecurityGroupFilterQueryParams] = useState("")
	const [selectedSecurityGroupFilter, setSelectedSecurityGroupFilter] = useState(securityGroupsFilterMenu[0].value)
	const [selectedSecurityGroupFilterValue, setSelectedSecurityGroupFilterValue] = useState("")
	const [selectedStatefulState, setSelectedStatefulState] = useState(false)
	const [statefulStateChangeDialogOpen, setStatefulStateChangeDialogOpen] = useState(false)

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const securityGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = securityGroupsFilterMenu.find(item => item.value === selectedSecurityGroupFilter)
		return filterObject ? filterObject.type : ''
	}

	const handleSecurityGroupFilteredSearch = () => {
			if (selectedSecurityGroupFilter && selectedSecurityGroupFilterValue) {
					setSecurityGroupFilterQueryParams(`${selectedSecurityGroupFilter}=${selectedSecurityGroupFilterValue}`)
			} else {
					setSecurityGroupFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleSelectedStatefulStateChange = (event) => {
			setSelectedStatefulState(event.target.checked)
	}

	const handleStatefulStateChangeDialogClose = () => {
			setStatefulStateChangeDialogOpen(false)
	}

	const handleStatefulStateChangeDialogOpen = (sg_list) => {
			const selected_sg_list = securityGroupsData.filter(sg => 
					sg_list.includes(sg.id))
			setSelectedSecurityGroups([...selected_sg_list])
			setStatefulStateChangeDialogOpen(true)
	}

	const handleSecurityGroupsDataFormatting = useCallback(() => {
			const formatted_data = securityGroupsData.map((item) => {
					let new_item = {...item}
					
					new_item.security_group_rules = item.security_group_rules.length
					return new_item
			})
			setSecurityGroups(formatted_data)
	},[
			securityGroupsData,
	])

	const handleSecurityGroupFilterReset = () => {
			setSelectedSecurityGroupFilter(securityGroupsFilterMenu[0].value)
			setSelectedSecurityGroupFilterValue("")
			setSecurityGroupFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedSecurityGroup(securityGroupsData[index].id)
			setSelectedRow(securityGroupsData[index])
	},[securityGroupsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedSecurityGroup(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleSecurityGroupsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setSecurityGroupsSortParams(sort_param)
			handleDataFetch()
	}

	const handleChangeStatefulState = async (sg_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}/${sg_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {security_group: {stateful: selectedStatefulState}},
				token,
		})

		if (nt_response.status_code === securityUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeStatefulState = async () => {
			handleStatefulStateChangeDialogClose()
			let err = []
			for (let n in selectedSecurityGroups) {
					const resp = await handleChangeStatefulState(selectedSecurityGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleSecurityGroupDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === securityUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onSecurityGroupDelete = async () => {
			handleSecurityGroupDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedSecurityGroups) {
					const resp = await handleSecurityGroupDelete(selectedSecurityGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onSecurityGroupDeleteConfirm = (n_list) => {
			const selected_n_list = securityGroupsData.filter(n => 
					n_list.includes(n.id))
			setSelectedSecurityGroups([...selected_n_list])
			setSecurityGroupDeleteConfirmDialogOpen(true)
	}

	const handleSecurityGroupDeleteConfirmDialogClose = () => {
			setSecurityGroupDeleteConfirmDialogOpen(false)
	}

	const getSecurityGroupsActionsList = () => {
			let securityGroup_actions = []
			let new_action = {}
			new_action["value"] = "change_stateful_state"
			new_action["action"] = handleStatefulStateChangeDialogOpen
			new_action["keyword"] = "changeStatefulStateActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			securityGroup_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "securityGroup_delete"
			new_action["action"] = onSecurityGroupDeleteConfirm
			new_action["keyword"] = "securityGroupDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			securityGroup_actions.push({...new_action})
			
			return securityGroup_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedSecurityGroup(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}?${securityGroupFilterQueryParams}${securityGroupsSortParams}`
							const method = "GET"
							const securityGroup_response = await openstackRequest({url:url, method:method, token, })
							if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
									setSecurityGroupsData(securityGroup_response.data.security_groups)
									if (selectedSecurityGroup) {
											const selected_securityGroup = securityGroup_response.data.security_groups.filter(item => item.id === selectedSecurityGroup)
											if (selected_securityGroup.length > 0) {
													setSelectedRow(selected_securityGroup[0])
											}
									}
							} else {
								setSecurityGroupsData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			securityGroupsUrl, 
			securityGroupFilterQueryParams,
			dataFetchingRequired,
			token,
			securityGroupsSortParams,
			selectedSecurityGroup
	]);

	useEffect(() => {
		if (securityGroupsData.length > 0) {
			handleSecurityGroupsDataFormatting()
		} else {
			setSecurityGroups([])
		}
	},[
		securityGroupsData,
		handleSecurityGroupsDataFormatting
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const securityGroup_id = location.state ? location.state.securityGroup_id : null
							const securityGroup_index = securityGroupsData.findIndex(v => v.id === securityGroup_id);
							if (securityGroup_index !== -1) {
									setTimeout(() => handleDetailCardOpen(securityGroup_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			securityGroupsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-security')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getSecurityGroupsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedSecurityGroups([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getSecurityGroupsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<SecurityGroupAddV20
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							label={defaultTexts[securityGroupsFilterMenu[0].keyword]}
							currentValue={selectedSecurityGroupFilterValue}
							setCurrentValue={e => setSelectedSecurityGroupFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleSecurityGroupFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleSecurityGroupFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-security'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={securityGroupsData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.securityGroupsTabButtonText} ({selectedIds.length}/{securityGroupsData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<SecurityGroupsTableV20 
				securityGroupsData={securityGroups}
				setSecurityGroupsData={setSecurityGroups}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getSecurityGroupsActionsList()}
				sortHandler={handleSecurityGroupsSorting}
				sortParams={securityGroupsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<SecurityGroupDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedSecurityGroup={setSelectedSecurityGroup}
				handleDataFetch={handleDataFetch}
				handleDelete={onSecurityGroupDeleteConfirm}
				handleStatefulStateChange={handleStatefulStateChangeDialogOpen}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
						open={securityGroupDeleteConfirmDialogOpen}
						onClose={handleSecurityGroupDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.securityGroupDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.securityGroupDeleteConfirmText}: [${selectedSecurityGroups.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onSecurityGroupDelete, 
								sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
						open={statefulStateChangeDialogOpen}
						onClose={handleStatefulStateChangeDialogClose}
						dialogTitle={{
								title: defaultTexts.changeStatefulStateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onChangeStatefulState, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={selectedStatefulState}
								setCurrentValue={handleSelectedStatefulStateChange}
								label={defaultTexts.statefuleFormFieldLabel}
								default_value={false}
						/>
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default SecurityGroupsWrapperV20