import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { openStackServices, networkNeutronConstants } from '../../../../config/openStackConstants';
import { openstackRequest } from '../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } from '../../../../_api_responses/openstack/neutron/networks/v2.0';
import L2NetworkingWrapperV20 from './l2Networking/l2NetworkingWrapperV2_0';
import L3NetworkingWrapperV20 from './l3Networking/l3NetworkingWrapperV2_0';
import SecurityWrapperV20 from './security/securityWrapperV2_0';
import FirewallWrapperV20 from './fwaas/firewallWrapperV2.0';
import VPNaaSWrapperV20 from './vpnaas/vpnWrapperV2_0';

const SERVICE_NAME = openStackServices.networkService

const NetworksNeutronContentV2_0 = (props) => {
	const theme = useTheme()
	const { navigate, location } = props

	const token = useSelector(state => state.profile.x_auth_token)

	const [serviceMenu, setServiceMenu] = useState([
		{ keyword: "submenuL2Networks", navigation: "/l2-networks", is_active: true, component: L2NetworkingWrapperV20 },
		{ keyword: "submenuL3Networks", navigation: "/l3-networks", is_active: false, component: L3NetworkingWrapperV20 },
		{ keyword: "submenuSecurity", navigation: "/network-security", is_active: false, component: SecurityWrapperV20 },
		{ keyword: "submenuFWaaS", navigation: "/network-fwaas", is_active: false, component: FirewallWrapperV20 },
		{ keyword: "submenuVPNaaS", navigation: "/network-vpnaas", is_active: false, component: VPNaaSWrapperV20 },
		// {keyword: "submenuQoS", navigation: "/network-qos", is_active: false, component: () => <Box />},
		// {keyword: "submenuNetworkFlavors", navigation: "/network-flavors", is_active: false, component: () => <Box />},
		// {keyword: "submenuNetworkLogging", navigation: "/network-logging", is_active: false, component: () => <Box />},
		// {keyword: "submenuNetworkLimits", navigation: "/network-limits", is_active: false, component: () => <Box />},
		// {keyword: "submenuNetworkTopology", navigation: "/network-topology", is_active: false, component: () => <Box />}
	])

	const [segmentsSupported, setSegmentsSupported] = useState(true)
	const [trunksSupported, setTrunksSupported] = useState(true)
	const [virtualIPsSupported, setVirtualIPsSupported] = useState(true)
	const [serviceSubmenu, setServiceSubmenu] = useState([])
	const [selectedSubmenu, setSelectedSubmenu] = useState('')

	const neutronServiceDomain = useSelector(
		state => state.openstack.purchasedServices.filter(
		service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const segmentsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)
	const trunksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
	const virtualIPsUrl = useSelector(
		state => state.networkNeutron.networkNeutronApiUrls.filter(
				version => version.api_version === "v2.0")[0].urls.filter(
						url => url.keyword === networkNeutronConstants.virtualIPsUrl)[0].url)
	
	const getCurrentTab = () => {
		const currentItem = serviceMenu.filter(item => item.is_active)[0]
		return currentItem
	}

	const [currentTab, setCurrentTab] = useState(getCurrentTab())

	const changeMenuActiveTab = useCallback((navigation) => {
		const updated_menu = serviceMenu.map(item => {
			if (item.navigation === navigation) {
				item.is_active = true
				setCurrentTab(item)
			} else {
				item.is_active = false
			}

			return item
		})

		setServiceMenu(updated_menu)
	}, [serviceMenu])

	useEffect(() => {
		(async () => {				
			let url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}`
			const method = "GET"
			const segment_response = await openstackRequest({url:url, method:method, token, })

			if (segment_response.status_code === networkUrlResponses.get.success_response.status_code) {
					setSegmentsSupported(true)
			} else if (segment_response.status_code === 404) {
					setSegmentsSupported(false)
			}				
		})();
	},[
		neutronServiceDomain, 
		neutronServiceVersion, 
		segmentsUrl,
		token,
	]);

useEffect(() => {
	(async () => {			
		let url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}`
		const method = "GET"
		const trunk_response = await openstackRequest({url:url, method:method, token, })
		if (trunk_response.status_code === networkUrlResponses.get.success_response.status_code) {
				setTrunksSupported(true)
		} else if (trunk_response.status_code === 404) {
				setTrunksSupported(false)
		}			
	})();
	},[
		neutronServiceDomain, 
		neutronServiceVersion, 
		trunksUrl,
		token,
	]);

	useEffect(() => {
		(async () => {			
			let url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}`
			const method = "GET"
			const virtual_ips_response = await openstackRequest({url:url, method:method, token, })
			if (virtual_ips_response.status_code === networkUrlResponses.get.success_response.status_code) {
					setVirtualIPsSupported(true)
			} else if (virtual_ips_response.status_code === 404) {
				setVirtualIPsSupported(false)
			}			
		})();
		},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			virtualIPsUrl,
			token,
		]);

	useEffect(() => {
		const serviceSubmenuInitial = [
			{ parent: '/l2-networks', keyword: "networksTabButtonText", navigation: "networks", },
			{ parent: '/l2-networks', keyword: "portsTabButtonText", navigation: "ports", },
			// { parent: '/l2-networks', keyword: "segmentsTabButtonText", navigation: "segments", },
			{ parent: '/l2-networks', keyword: "trunksTabButtonText", navigation: "trunks", },
			{ parent: '/l3-networks', keyword: "subnetsTabButtonText", navigation: "subnets", },
			{ parent: '/l3-networks', keyword: "floatingIPsTabButtonText", navigation: "floating_ips", },
			{ parent: '/l3-networks', keyword: "virtualIPsTabButtonText", navigation: "virtual_ips", },
			{ parent: '/l3-networks', keyword: "routersTabButtonText", navigation: "routers", },
			{ parent: '/network-security', keyword: "securityGroupsTabButtonText", navigation: "security_groups", },
			{ parent: '/network-security', keyword: "addressGroupsTabButtonText", navigation: "address_groups", },
			{ parent: '/network-fwaas', keyword: "firewallGroupsTabButtonText", navigation: "firewall_groups", },
			{ parent: '/network-fwaas', keyword: "firewallPoliciesTabButtonText", navigation: "firewall_policies", },
			{ parent: '/network-fwaas', keyword: "firewallRulesTabButtonText", navigation: "firewall_rules", },
			{ parent: '/network-vpnaas', keyword: "ikePoliciesTabButtonText", navigation: "ike_policies", },
			{ parent: '/network-vpnaas', keyword: "ipsecPoliciesTabButtonText", navigation: "ipsec_policies", },
			{ parent: '/network-vpnaas', keyword: "vpnServicesTabButtonText", navigation: "vpn_services", },
			{ parent: '/network-vpnaas', keyword: "endpointGroupsTabButtonText", navigation: "endpoint_groups", },
			{ parent: '/network-vpnaas', keyword: "siteConnectionsTabButtonText", navigation: "site_connections", },
		]

		let service_submenu = []

		serviceSubmenuInitial.forEach(item => {
			if (
				(item.navigation === 'segments' && segmentsSupported) ||
				(item.navigation === 'trunks' && trunksSupported) ||
				(item.navigation === 'virtual_ips' && virtualIPsSupported) ||
				(item.navigation !== 'segments' && item.navigation !== 'trunks' && item.navigation !== 'virtual_ips')
			) {
				service_submenu.push(item)
			}
		})
		
		setServiceSubmenu(service_submenu)
	}, [segmentsSupported, trunksSupported, virtualIPsSupported])
	
	useEffect(() => {
		if (location.state && location.state.menu && location.state) {
			changeMenuActiveTab(location.state.menu)
			
			if (location.state.submenu) {
				setSelectedSubmenu(location.state.submenu)
			}

			navigate(location.path, {})
		}
	}, [navigate, location, changeMenuActiveTab])
	
	return (
		<Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
			<currentTab.component 
				navigate={navigate} 
				location={location}
				serviceMenu={serviceMenu}
				serviceSubmenu={serviceSubmenu}
				selectedSubmenu={selectedSubmenu}
				changeMenuActiveTab={changeMenuActiveTab}
				segmentsSupported={segmentsSupported}
				trunksSupported={trunksSupported}
				virtualIPsSupported={virtualIPsSupported}
			/>
		</Box>
	)
}

export default NetworksNeutronContentV2_0