import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import WrapperBox from '../../../../_common/WrapperBox';
import { Button, Divider } from '@mui/material';
import { flavorsUrl as flavorsUrlResponses } from '../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { openstackRequest, computeNovaRequest } from '../../../../../_network/openstack_request';
import { openStackServices } from '../../../../../config/openStackConstants';
import { computeNovaConstants } from '../../../../../config/openStackConstants';
import { Stack } from '@mui/material';
import ComputeServersTableV21 from './computeServersTableV2_1';
import CustomText from '../../../../_common/CustomText';
import CustomDialog from '../../../../_common/CustomDialog';
import SubheaderButton from '../../../../_common/SubheaderButton';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import Box from '@mui/material/Box';
import { 
    power_states,
    serversFilterGroups, 
    serversFilters,
    serverGroupDataForm,
    serverGroupRulesDataForm
} from '../../../../../_data/openstack/compute/servers/v2.1';
import ComputeServerDetailV21 from './computeServerDetailsV2_1';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import ComputeServerFilterV21 from './computeServerFilterV2_1';
import ServiceMenu from '../../../../_common/ServiceMenu';
import ComputeServerLunchDialogV21 from './computeServerLunchDialogV2_1';
import CustomPopover from '../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.computeService
const flavors_default_url_query = "?is_public=none"

const CONSOLE_TRANSISION_MICROVERSION = 2.6

const ComputeServersWrapperV21 = (props) => {
	const theme = useTheme()

	const { navigate, location, serviceMenu, changeMenuActiveTab } = props
	
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [serversData, setServersData] = useState([])
	const [servers, setServers] = useState([])
	const [flavorsData, setFlavorsData] = useState([])
	const [selectedFlavor, setSelectedFlavor] = useState();
	const [flavorDialogModalOpen, setFlavorDialogModalOpen] = useState(false)
	const { width } = useWindowDimensions();
	const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedServer, setSelectedServer] = useState(null);
	const [fetchDataRequired, setFetchDataRequired] = useState(true);
	const [fetchGroupsDataRequired, setFetchGroupsDataRequired] = useState(true);
	const [serversStatusToCheck,setServersStatusToCheck] = useState({})
	const [intervalId, setIntervalId] = useState(null)
	const [startedCheck, setStartedCheck] = useState(false)
	const [currentAction, setCurrentAction] = useState("");
	const [serverDeleteConfirmDialogOpen, setServerDeleteConfirmDialogOpen] = useState(false);
	const [selectedServers, setSelectedServers] = useState([])
	const [currentFilters, setCurrentFilters] = useState({})
	const [queryParams, setQueryParams] = useState("")
	const [sortParams, setSortParams] = useState("")
	const [serverGroups, setServerGroups] = useState([])
	const [serverGroupForm, setServerGroupForm] = useState({})
	const [serverGroupRuleForm, setServerGroupRuleForm] = useState({})
	const [selectedServerIds, setSelectedServerIds] = useState([])
	const [serverCreateDialogOpen, setServerCreateDialogOpen] = useState(false)
	const [consoleAvailable, setConsoleAvailable] = useState(false)
	const [deselectAll, setDeselectAll] = useState(false)
	
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)
	
	const sxLaunchButton = {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    background: theme.palette.success.main,
    border: `${theme.palette.customGrayDark} 1px solid`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0px 10px',
    marginLeft: width >= 900 ? '10px' : '0px',

    '&:hover': {
      background: alpha(theme.palette.success.main, 0.7),
    }
  }

	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	const serverGroupsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serverGroupsUrl)[0].url)
	const flavorsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
	const maxAPIVersion = useSelector(state =>
		state.computeNova.computeNovaApiUrls.filter(
				version => version.api_version === computeServiceVersion)[0].max_api_version)

	const handleActionRun = () => {
		const selected_server_list = serversData.filter(item => selectedServerIds.includes(item.id))
		setSelectedServers([...selected_server_list])
		setServerDeleteConfirmDialogOpen(true)
	}

	const handleServerCreateDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setServerCreateDialogOpen(true)
		}		
	}

	const handleServerCreateDialogClose = () => {
		setServerCreateDialogOpen(false)
	}
	
	const handleServerDelete = async (server) => {
		const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${server.id}`
		const method = "DELETE"
		
		const server_response = await openstackRequest({
				url, 
				method, 
				token,
		})

		if (server_response.status_code === 204) {
			return null
		} else {
			return server_response.error
		}
	}

	const onServerDelete = async () => {
		handleServerDeleteConfirmDialogClose()
		setIsLoading(true)

		let err = []

		for (let s in selectedServers) {
			const resp = await handleServerDelete(selectedServers[s])
			if (resp !== null) {
				err = [...err, resp]
			}
		}

		setIsLoading(false)
		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const onServerDeleteConfirm = (server_list) => {
			const selected_server_list = serversData.filter(srv => 
					server_list.includes(srv.id))
			setSelectedServers([...selected_server_list])
			setServerDeleteConfirmDialogOpen(true)
	}

	const handleServerDeleteConfirmDialogClose = () => {
			setServerDeleteConfirmDialogOpen(false)
	}

	const getServersActionsList = () => {
			let server_actions = []
			let new_action = {}
			new_action["value"] = "server_delete"
			new_action["action"] = onServerDeleteConfirm
			new_action["keyword"] = "serverDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			server_actions.push({...new_action})
			
			return server_actions
	}

	const handleDataFetch = () => {
		setFetchDataRequired(true)
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleServerStatusChange = (status,id) => {
			if (!Object.keys(serversStatusToCheck).includes(id)) {
					let new_list = {...serversStatusToCheck}
					new_list[id] = status
					setServersStatusToCheck(new_list)
			}
	}

	const handleDetailCardOpen = (index) => {
			setSelectedRow(servers[index])
			setSelectedServer(serversData.filter(item => 
					item.id === servers[index].id)[0])
			// setDetailCardOpen(true)
	}

	const handleServerConsoleOpen = async () => {
		const serverId = selectedServerIds.length === 1 ? selectedServerIds[0] : ''

		if (!serverId) {
			return
		}

		setIsLoading(true)
		
		const method = "POST"
		let url = ""
		let data_to_send = {}

		if (parseFloat(maxAPIVersion) >= CONSOLE_TRANSISION_MICROVERSION) {
			url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverId}/remote-consoles`
			
			data_to_send["remote_console"] = {}
			data_to_send["remote_console"]["protocol"] = "vnc"
			data_to_send["remote_console"]["type"] = "novnc"

			const server_response = await computeNovaRequest({
					url, 
					method, 
					data: data_to_send,
					token
			})

			if (server_response.status_code === 200) {
					const console_url = server_response.data.remote_console.url
					window.open(console_url, "_blank")
			} else {
					setError(server_response.error)
			}
	} else {
			url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverId}/action`

			data_to_send["os-getVNCConsole"] = {}
			data_to_send["os-getVNCConsole"]["type"] = "novnc"

			const server_response = await computeNovaRequest({
					url, 
					method, 
					data: data_to_send,
					token
			})

			if (server_response.status_code === 200) {
					const console_url = server_response.data.console.url
					window.open(console_url, "_blank")
			} else {
					setError(server_response.error)
			}
		}
		
		setIsLoading(false)
	}

	const handleCurrentFiltersChange = (event,field_key) => {
			let new_filter_data = {...currentFilters}
			for (let g in serversFilterGroups) {
					const field_item = serversFilters[serversFilterGroups[g].name].filter(
							item => item.field_key === field_key)
					if (field_item.length > 0) {
							if (serversFilters[serversFilterGroups[g].name].filter(
									item => item.field_key === field_key)[0].field_type === "bool") {
									new_filter_data[field_key] = event.target.checked
							} else if (serversFilters[serversFilterGroups[g].name].filter(
									item => item.field_key === field_key)[0].field_type === "select" ||
									serversFilters[serversFilterGroups[g].name].filter(
											item => item.field_key === field_key)[0].field_type === "datetime"
							) {
									new_filter_data[field_key] = event
							} else {
									new_filter_data[field_key] = event.target.value
							}
					}
			}
			setCurrentFilters(new_filter_data)
	}

	const handleServerFilterReset = () => {
			setCurrentFilters({})
			setQueryParams("")
			handleDataFetch()
	}

	const handleServerSearch = () => {
			let query_params = ""
			for (let key in currentFilters) {
					if (currentFilters[key]) {
							if (key === "changes-since" || key === "created_at") {
									const val = currentFilters[key].toISOString()
									query_params = `${query_params}&${key}=${val}`
							} else {
									query_params = `${query_params}&${key}=${currentFilters[key]}`
							}
					}
			}
			setQueryParams(query_params)
			handleDataFetch()
	}

	const handleFlavorDataFormatting = useCallback((data) => {
			const formatted_data = data.map(item => {
					let new_item = {...item}
					if (item.ram > (1024 * 1024) - 1) {
							new_item.ram = `${Math.round(item.ram / 1024 * 1024)} TB`
					} else if (item.ram > 1023) {
							new_item.ram = `${Math.round(item.ram / 1024)} GB`
					} else {
							new_item.ram = `${item.ram} MB`
					}

					if (item.swap) {
							if (item.swap > 1023) {
									new_item.swap = `${Math.round(item.swap / 1024 * 1024)} GB`
							} else {
									new_item.swap = `${item.swap} MB`
							}
					}

					if (item["os-flv-ext-data:ephemeral"]) {
							new_item["os-flv-ext-data:ephemeral"] = `${item["os-flv-ext-data:ephemeral"]} GB`
					}

					if (item.disk) {
							new_item.disk = `${item.disk} GB`
					}
					
					return new_item
			})

			setFlavorsData(formatted_data)
	},[])

	const flavorDialogOpen = useCallback((row) => {
			setSelectedFlavor(servers[row].flavor_object)
			setFlavorDialogModalOpen(true)
	},[servers])
	
	const flavorDialogClose = () => {
			setSelectedFlavor(undefined)
			setFlavorDialogModalOpen(false)
	}

	const FlavorPopoverComponent = (props) => {
		const { flavor } = props
		return (
			<Button>{flavor.original_name}</Button>
		)
	}

	const handleServerDataFormatting = useCallback((data) => {
			const formatted_data = data.map(item => {
					let new_item = {...item}

					new_item.flavor = <FlavorPopoverComponent flavor={item.flavor} />
					new_item.flavor_object = item.flavor
					
					new_item.addresses = []

					if (Object.keys(item.addresses).length > 0) {
							for (let a in item.addresses) {
									for (let i in item.addresses[a]) {
											new_item.addresses.push(item.addresses[a][i].addr)
									}
									
							}
					}

					new_item["OS-EXT-STS:power_state"] = defaultTexts[power_states[item["OS-EXT-STS:power_state"]]]
					
					const age_mins = (new Date() - new Date(item.created)) / 1000 / 60
					if (age_mins < 60) {
							new_item.age = `${parseInt(age_mins)} minutes`
					} else if (age_mins < 1440) {
							new_item.age = `${parseInt(age_mins / 60)} hours, ${parseInt(age_mins % 60)} minutes`
					} else {
							new_item.age = `${parseInt(age_mins / 1440)} days`
					}

					if (Object.keys(serversStatusToCheck).includes(item.id)) {
							new_item.progress = <WrapperBox
							sx={{
									borderColor: "primary"
							}}
					>
							<CircularProgress />
					</WrapperBox>
					} else {
							new_item.progress = "-"
					}

					return new_item
			})

			setServers(formatted_data)
	},[
			defaultTexts,
			serversStatusToCheck
	])

	const handleServerSorting = (field, direction) => {
		const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
		setSortParams(sort_param)
		handleDataFetch()
	}
	
	useEffect(() => {
			(async () => {
					const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${flavors_default_url_query}`
					const method = "GET"

					const flavors_response = await openstackRequest({url:url, method:method})
					if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
							handleFlavorDataFormatting([...flavors_response.data.flavors])
					}
			})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			flavorsUrl, 
			handleFlavorDataFormatting
	]);

	useEffect(() => {
		setFetchDataRequired(true)
		setSelectedServer(null)
		setSelectedRow(null)
	}, [token])
	
	useEffect(() => {
			handleLoading(true)
			if (fetchDataRequired) {
							(async () => {
									const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?${queryParams}${sortParams}`
									const method = "GET"
									
											const servers_response = await computeNovaRequest({
													url:url, 
													method:method, 
											})
											console.log(servers_response)
											if (Object.keys(serversStatusToCheck).length > 0) {
											const changed_servers = servers_response.data.servers.filter(s => Object.keys(serversStatusToCheck).includes(s.id))
											let new_list = {...serversStatusToCheck}
											if (changed_servers.length > 0) {
													for (let sr in changed_servers) {
															if (new_list[changed_servers[sr].id] !== changed_servers[sr].status) {
																	delete new_list[changed_servers[sr].id]
															}
													}
											}

											setServersStatusToCheck(new_list)
									}
									
									if (servers_response.data && servers_response.data.servers) {
										setServersData(servers_response.data.servers)
									}                    
									
							})();
					
			}
			setTimeout(()=>{setFetchDataRequired(false)},700)
			handleLoading(false)
	},[
			computeServiceDomain,
			computeServiceVersion,
			serversUrl,
			flavorsData,
			fetchDataRequired,
			selectedRow,
			serversStatusToCheck,
			intervalId,
			queryParams,
			sortParams,
			token,
	]);

	useEffect(() => {
			// handleLoading(true)
			if (fetchGroupsDataRequired) {
							(async () => {
									const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}?all_projects=true`
									const method = "GET"

									const server_groups_response = await computeNovaRequest({
											url:url, 
											method:method, 
											token,
									})
									setServerGroups(server_groups_response.data.server_groups)
									
							})();
					setFetchGroupsDataRequired(false)
			}
			// handleLoading(false)
	},[
			computeServiceDomain,
			computeServiceVersion,
			serverGroupsUrl,
			token,
			fetchGroupsDataRequired
	]);

	useEffect(() => {
			handleServerDataFormatting(serversData)
	},[
			handleServerDataFormatting,
			flavorsData,
			serversData
	]);

	useEffect(() => {
			if (selectedServer && servers.length > 0) {
					const s = servers.filter(item => item.id === selectedServer.id)
					if (s.length > 0) {
							setSelectedRow(s[0])
					}
			}
	},[
			servers,
			selectedServer
	]);

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	useEffect(() => {
			let interval_id = null
			if (Object.keys(serversStatusToCheck).length > 0 && !startedCheck) {
					handleServerDataFormatting(serversData)
					setStartedCheck(true)
							interval_id = setInterval(() => {
									handleDataFetch(); 
							},10000)
							setIntervalId(interval_id)
			}
			if (Object.keys(serversStatusToCheck).length === 0) {
					if (intervalId) {
							clearInterval(intervalId)
							setStartedCheck(false)
							setIntervalId(null)
					}
					handleServerDataFormatting(serversData)
			}
	},[
			serversStatusToCheck,
			serversData,
			handleServerDataFormatting,
			startedCheck,
			intervalId
	])

	useEffect(() => {
			if (Object.keys(currentFilters).length === 0) {
					let new_filter_data = {}
					for (let g in serversFilterGroups) {
							for (let n in serversFilters[serversFilterGroups[g].name]) {
									if (
											serversFilters[serversFilterGroups[g].name][n].field_type === "string" || 
											serversFilters[serversFilterGroups[g].name][n].field_type === "select" ||
											serversFilters[serversFilterGroups[g].name][n].field_type === "list"
											) {
													new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = ""
									} else if (serversFilters[serversFilterGroups[g].name][n].field_type === "bool") {
											new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = serversFilters[g.name][n].default_value
									}
							}
					}
					setCurrentFilters(new_filter_data)
			}
	},[
			currentFilters
	]);

	useEffect(() => {
			if (Object.keys(serverGroupForm).length === 0) {
					let new_form_data = {}
					for (const n in serverGroupDataForm) {
							if (
									serverGroupDataForm[n].field_type === "string" || 
									serverGroupDataForm[n].field_type === "select" ||
									serverGroupDataForm[n].field_type === "list"
									) {
									new_form_data[serverGroupDataForm[n].field_key] = ""
							} else if (serverGroupDataForm[n].field_type === "bool") {
									new_form_data[serverGroupDataForm[n].field_key] = serverGroupDataForm[n].default_value ? 
									serverGroupDataForm[n].default_value : 
									false
							} else if (serverGroupDataForm[n].field_type === "integer") {
									new_form_data[serverGroupDataForm[n].field_key] = serverGroupDataForm[n].default_value ? 
									serverGroupDataForm[n].default_value : 
									0
							}
					}
					setServerGroupForm(new_form_data)
			}
	},[serverGroupForm]);

	useEffect(() => {
			if (Object.keys(serverGroupRuleForm).length === 0) {
					let new_form_data = {}
					for (const n in serverGroupRulesDataForm) {
							if (
									serverGroupRulesDataForm[n].field_type === "string" || 
									serverGroupRulesDataForm[n].field_type === "select" ||
									serverGroupRulesDataForm[n].field_type === "list"
									) {
									new_form_data[serverGroupRulesDataForm[n].field_key] = ""
							} else if (serverGroupRulesDataForm[n].field_type === "bool") {
									new_form_data[serverGroupRulesDataForm[n].field_key] = serverGroupRulesDataForm[n].default_value ? 
									serverGroupRulesDataForm[n].default_value : 
									false
							} else if (serverGroupRulesDataForm[n].field_type === "integer") {
									new_form_data[serverGroupRulesDataForm[n].field_key] = serverGroupRulesDataForm[n].default_value ? 
									serverGroupRulesDataForm[n].default_value : 
									0
							}
					}
					setServerGroupRuleForm(new_form_data)
			}
	},[serverGroupRuleForm]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		let console_available = false

		if (selectedServerIds.length === 1) {
			const server_object = serversData.find(item => item.id === selectedServerIds[0])
			if (server_object && server_object.status && server_object.status === 'ACTIVE') {
				console_available = true
			}
		}

		setConsoleAvailable(console_available)
	}, [selectedServerIds, serversData])
	
	useEffect(() => {
		if (location.state && location.state.server_id && servers.length > 0) {
			const findServer = servers.find(item => item.id === location.state.server_id)
			setSelectedServer(findServer ? findServer : null)
			navigate(location.path, {})
		}
	}, [location, navigate, servers])

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					consoleAvailable &&
					<SubheaderButton
						buttontitle={defaultTexts.serverConsoleTitle.toUpperCase()}
						onClick={handleServerConsoleOpen}
						sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }
					/>
				}

				{
					selectedServerIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getServersActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<Box
					onClick={handleServerCreateDialogOpen}
					sx={sxLaunchButton}
				>
					{defaultTexts.launchInstance}
				</Box>
			</Stack>
		)
	}

	const handleDeselectAll = () => {
		setSelectedServerIds([])
		setSelectedServers([])
		setDeselectAll(true)
	}

	const getServersHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 5px 20px',
						width: "100%"
					}}
				>
					<Box>
						<ServiceMenu
							parent='instances'
							serviceMenu={serviceMenu}
							onClick={changeMenuActiveTab}
						/>
					</Box>

					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '0px 20px 5px 20px',
						width: "100%",
						position: 'relative'
					}}
				>
					<Box>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
							}}
						>
							{defaultTexts.submenuServers} ({selectedServerIds.length}/{serversData.length})
						</Box>
					</Box>					
					<Box sx={{
						position: "absolute", 
						top: 0, 
						right: '20px',
						zIndex: 10
					}}>
						<ComputeServerFilterV21 
							filterGroups={serversFilterGroups}
							filters={serversFilters}
							flavors={flavorsData}
							currentValues={currentFilters}
							setCurrentValue={handleCurrentFiltersChange}
							handleSearch={handleServerSearch}
							handleFilterReset={handleServerFilterReset}
						/>
					</Box>					
				</Stack>

				<Box sx={{ padding: '0px 20px 5px 10px' }}>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedServerIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>	
				</Box>
			</>
		)
	}

	const getServersTable = () => {
		return (
			<ComputeServersTableV21 
				serversData={servers}
				setServersData={setServers}
				handleRowSelection={handleDetailCardOpen}
				handleCellClick={{flavor: flavorDialogOpen}}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getServersActionsList()}
				filterGroups={serversFilterGroups}
				filters={serversFilters}
				flavors={flavorsData}
				currentValues={currentFilters}
				setCurrentValue={handleCurrentFiltersChange}
				handleServerFilterReset={handleServerFilterReset}
				handleServerSearch={handleServerSearch}
				sortParams={sortParams}
				sortHandler={handleServerSorting}
				setSelectedServerIds={setSelectedServerIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getServersContent = () => {
		return (
			<>
				{ getServersHeader() }
				{ getServersTable() }
			</>
		)
	}

	const getServerDetails = () => {
		return (
			<ComputeServerDetailV21
				selectedRow={selectedRow}
				selectedServer={selectedServer}
				widthWeight={WIDTH_WEIGHT}
				handleDataFetch={handleDataFetch}
				handleServerStatusChange={handleServerStatusChange}
				serverGroups={serverGroups}
				setSelectedRow={setSelectedRow}
				setSelectedServer={setSelectedServer}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}
	
	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getServersContent()} contentBottom={getServerDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getServersHeader() }
				
				{ (selectedRow === null && !isLoading) && getServersTable() }

				{
					selectedFlavor &&
					<CustomDialog
						open={flavorDialogModalOpen}
						onClose={flavorDialogClose}
						dialogTitle={{
							title: `${defaultTexts.flavorDetailsDialogTitle} ${selectedFlavor.original_name}`, 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					>
						<Stack direction="column" spacing={2}>
							<Stack 
									direction="row" 
									spacing={2} 
									justifyContent="space-between" 
									alignItems="center"
							>
									<CustomText size="p">
										{defaultTexts.vcpusFormFieldLabel}:
									</CustomText>
									<CustomText size="p">
										{selectedFlavor.vcpus}
									</CustomText>
							</Stack>
							<Divider />
							<Stack 
									direction="row" 
									spacing={2} 
									justifyContent="space-between" 
									alignItems="center"
							>
									<CustomText size="p">
										{defaultTexts.ramTableHeaderLabel}:
									</CustomText>
									<CustomText size="p">
										{selectedFlavor.ram}
									</CustomText>
							</Stack>
							<Divider />
							<Stack 
									direction="row" 
									spacing={2} 
									justifyContent="space-between" 
									alignItems="center"
							>
									<CustomText size="p">
										{defaultTexts.diskTableHeaderLabel}:
									</CustomText>
									<CustomText size="p">
										{selectedFlavor.disk}
									</CustomText>
							</Stack>
							<Divider />
						</Stack>
					</CustomDialog>
				}

				<CustomDialog
					open={serverDeleteConfirmDialogOpen}
					onClose={handleServerDeleteConfirmDialogClose}
					dialogTitle={{
						title: defaultTexts.serverDeleteConfirmTitle, 
						sx: {color: 'primary.main'}}}
					dialogBody={{
						text: `${defaultTexts.serverDeleteConfirmText}: [${selectedServers.map(s => s.name).toString()}]`, 
						sx: {color: 'text.primary'}}}
					actionButtons={[{
						title: defaultTexts.confirmButtonText, 
						onClick: onServerDelete, 
						sx: {color: 'primary.main'}}]}
				/>

				<ComputeServerLunchDialogV21 
					open={serverCreateDialogOpen} 
					handleClose={handleServerCreateDialogClose}
					handleDataFetch={handleDataFetch}
					handleServerStatusChange={handleServerStatusChange}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
								<br>
								<br>
								<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
								<span style="color: orange">
									${error.error_details}
								</span>`,
							sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default ComputeServersWrapperV21