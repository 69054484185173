import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { power_states } from '../../../_data/openstack/compute/servers/v2.1'

const HomeInstanceStates = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { id, name, state } = props

  const langTexts = useSelector(state => state.texts.langTexts)

  const sxContainer = {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px',
    borderBottom: `${theme.palette.customGray} 1px solid`,
    color: theme.palette.primary.main,
  }

  const handleClick = () => {
    navigate('/instances', { state: { server_id: id } })
  }

  return (
    <Box sx={sxContainer}>
      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>{name}</Box>
      <Box sx={{
        color: state === 1 ? theme.palette.success.main : theme.palette.error.main,
      }}>{langTexts[power_states[state]]}</Box>
    </Box>
  )
}

export default HomeInstanceStates