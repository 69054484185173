import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CustomText from './CustomText'
import CustomSelectField from './_form_fields/CustomSelectField'
import useWindowDimensions from './WindowDimensions'
import { setNetworkMenuMode } from '../../store/reducers/settingsSlice'
import { TiArrowMinimise } from "react-icons/ti"
import { TiArrowMaximise } from "react-icons/ti"

const ServiceMenu = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { parent, serviceMenu, onClick } = props

  const menuMode = useSelector(state => state.settings.networkMenuMode)
  const [elWidth, setElWidth] = useState(null)
  const [forceAutoWidth, setForceAutoWidth] = useState(false)
  const [currentTab, setCurrentTab] = useState(serviceMenu.filter(item => item.is_active)[0].navigation)

  const elRef = useRef(null)

  const defaultTexts = useSelector(state => state.texts.langTexts)

  const getMinimumWidth = () => {
    let wordCount = 0
    serviceMenu.forEach(item => wordCount += defaultTexts[item.keyword].length)
    return wordCount * 11
  }
  
  const buttonClick = (navigation) => {
    onClick(navigation)
  }

  const getServiceMenuList = () => {
    let service_menu_list = []
    serviceMenu.forEach(item => service_menu_list.push({
      value: item.navigation,
      keyword: defaultTexts[item.keyword],
    }))
    return service_menu_list
  }

  const showDropdown = () => {
    setElWidth(50)
    setTimeout(() => {
      dispatch(setNetworkMenuMode({ networkMenuMode: { ...menuMode, [parent]: true } }))
    }, 600)
  }

  const showTabs = () => {
    dispatch(setNetworkMenuMode({ networkMenuMode: { ...menuMode, [parent]: false } }))
    setTimeout(() => {
      setElWidth(getMinimumWidth())
      setForceAutoWidth(true)
    }, 100)
  }

  useEffect(() => {
    if (elRef && elRef.current && elRef.current.offsetWidth && !elWidth) {
      setElWidth(elRef.current.offsetWidth)
    }
  }, [serviceMenu, elWidth])

  useEffect(() => {
    if (forceAutoWidth) {
      setTimeout(() => {
        setElWidth(null)
        setForceAutoWidth(false)
      }, 600)     
    }
  }, [forceAutoWidth])

  useEffect(() => {
    const findMenuObject = serviceMenu.find(item => item.is_active && item.navigation === currentTab)
    if (!findMenuObject) {
      onClick(currentTab)
    }    
  }, [currentTab, serviceMenu, onClick])

  useEffect(() => {
    if (menuMode && menuMode[parent]) {
      setElWidth(50)
    }
  }, [menuMode, parent])
  
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="center"      
    >
      {
        ((menuMode && menuMode[parent]) || width < 1200) ? (
          <Box sx={{ marginRight: '12px' }}>
            <CustomSelectField
              items={getServiceMenuList()} 
              currentValue={currentTab}
              setCurrentValue={setCurrentTab}
              item_titles={defaultTexts}
              empty={true}
              self_item_titles={true}
              size='small'
              sx={{ width: '270px' }}
            / >
          </Box>
        ) : (
          <Box
            sx={{
              width: elWidth ? `${elWidth}px` : 'auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'hidden',
              transition: 'all 1s',
            }}
            ref={elRef}
          >

          {
            serviceMenu.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'inline-block',
                  color: theme.palette.customGrayDark,
                  paddingTop: '5px',
                }}
              >
                <Box
                  sx={{
                    display: 'inline-block',
                    color: item.is_active ? theme.palette.primary.main : theme.palette.customGrayDark,
                    cursor: item.is_active ? 'auto' : 'pointer',
                    marginRight: '12px',
                    '&:hover': {
                      opacity: item.is_active ? 1 : 0.7,
                    }
                  }}
                  onClick={() => buttonClick(item.navigation)}
                >
                  <CustomText sx={{ fontSize: '17px' }}>
                    {defaultTexts[item.keyword]}
                  </CustomText>
                </Box>            
                {
                  index < serviceMenu.length - 1 &&
                  <Box
                    sx={{
                      display: 'inline-block',
                      fontSize: '17px',
                      marginRight: '12px',
                    }}
                  >|</Box>
                }										
              </Box>
            ))
          }
          </Box>
        )
      }

      {
        width >= 1200 &&
        <>
          {
            (menuMode && menuMode[parent]) ? (
              <Box
                sx={{
                  color: theme.palette.customGrayDark,
                  cursor: 'pointer',
                }}
                onClick={showTabs}
              >
                <TiArrowMaximise size={36} />
              </Box>          
            ) : (
              <Box
                sx={{
                  color: theme.palette.customGrayDark,
                  cursor: 'pointer',
                }}
                onClick={showDropdown}
              >
                <TiArrowMinimise size={36} />
              </Box>
            )
          }
        </>
      }
           
    </Stack>
  )
}

export default ServiceMenu