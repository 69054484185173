import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import FirewallGroupsTableV20 from './firewallGroupsTableV2.0';
import FirewallGroupDetailV20 from './firewallGroupDetailV2.0';
import { firewallGroupsFilterMenu } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import FirewallGroupAddV20 from './firewallGroupAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const FirewallGroupsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, currentTab, setCurrentTab, serviceMenu, serviceSubmenu, changeMenuActiveTab } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const [firewallGroupsData, setFirewallGroupsData] = useState([])
	const [firewallGroups, setFirewallGroups] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedFirewallGroup, setSelectedFirewallGroup] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [firewallGroupDeleteConfirmDialogOpen, setFirewallGroupDeleteConfirmDialogOpen] = useState(false);
	const [selectedFirewallGroups, setSelectedFirewallGroups] = useState([])
	const [firewallGroupsSortParams, setFirewallGroupsSortParams] = useState("")
	const [firewallPolicies, setFirewallPolicies] = useState([])
	const [adminStateChangeDialogOpen, setAdminStateChangeDialogOpen] = useState(false);
	const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
	const [selectedAdminState, setSelectedAdminState] = useState(true);
	const [selectedSharedState, setSelectedSharedState] = useState(true);
	
	const [firewallGroupFilterQueryParams, setFirewallGroupFilterQueryParams] = useState("")
	const [selectedFirewallGroupFilter, setSelectedFirewallGroupFilter] = useState(firewallGroupsFilterMenu[0].value)
	const [selectedFirewallGroupFilterValue, setSelectedFirewallGroupFilterValue] = useState("")

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const firewallGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)
	const firewallPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleSelectedSharedStateChange = (event) => {
			setSelectedSharedState(event.target.checked)
	}
	const handleSelectedAdminStateChange = (event) => {
			setSelectedAdminState(event.target.checked)
	}

	const handleSharedStateChangeDialogClose = () => {
			setSharedStateChangeDialogOpen(false)
	}

	const handleAdminStateChangeDialogClose = () => {
			setAdminStateChangeDialogOpen(false)
	}

	const handleSharedStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallGroupsData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallGroups([...selected_list])
			setSharedStateChangeDialogOpen(true)
	}

	const handleAdminStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallGroupsData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallGroups([...selected_list])
			setAdminStateChangeDialogOpen(true)
	}

	const handleChangeAdminState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_group: {admin_state_up: selectedAdminState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeAdminState = async () => {
			handleAdminStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallGroups) {
					const resp = await handleChangeAdminState(selectedFirewallGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleChangeSharedState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_group: {shared: selectedSharedState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeSharedState = async () => {
			handleSharedStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallGroups) {
					const resp = await handleChangeSharedState(selectedFirewallGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleFirewallGroupFilteredSearch = () => {
			if (selectedFirewallGroupFilter && selectedFirewallGroupFilterValue) {
					setFirewallGroupFilterQueryParams(`${selectedFirewallGroupFilter}=${selectedFirewallGroupFilterValue}`)
			} else {
					setFirewallGroupFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleFirewallGroupsDataFormatting = useCallback(() => {
			const formatted_data = firewallGroupsData.map((item) => {
					let new_item = {...item}
					
					new_item.ports = item.ports.length
					const in_policy = firewallPolicies.filter(fp => fp.id === item.ingress_firewall_policy_id)
					const out_policy = firewallPolicies.filter(fp => fp.id === item.egress_firewall_policy_id)
					if (in_policy.length > 0) {
							new_item.ingress_firewall_policy_id = in_policy[0].name ? in_policy[0].name : item.ingress_firewall_policy_id
					}
					if (out_policy.length > 0) {
							new_item.egress_firewall_policy_id = out_policy[0].name ? out_policy[0].name : item.egress_firewall_policy_id
					}
					return new_item
			})
			setFirewallGroups(formatted_data)
	},[
			firewallGroupsData,
			firewallPolicies
	])

	const handleFirewallGroupFilterReset = () => {
			setSelectedFirewallGroupFilter(firewallGroupsFilterMenu[0].value)
			setSelectedFirewallGroupFilterValue("")
			setFirewallGroupFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedFirewallGroup(firewallGroupsData[index].id)
			setSelectedRow(firewallGroupsData[index])
	},[firewallGroupsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedFirewallGroup(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFirewallGroupsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setFirewallGroupsSortParams(sort_param)
			handleDataFetch()
	}

	const handleFirewallGroupDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onFirewallGroupDelete = async () => {
			handleFirewallGroupDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedFirewallGroups) {
					const resp = await handleFirewallGroupDelete(selectedFirewallGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onFirewallGroupDeleteConfirm = (n_list) => {
			const selected_n_list = firewallGroupsData.filter(n => 
					n_list.includes(n.id))
			setSelectedFirewallGroups([...selected_n_list])
			setFirewallGroupDeleteConfirmDialogOpen(true)
	}

	const handleFirewallGroupDeleteConfirmDialogClose = () => {
			setFirewallGroupDeleteConfirmDialogOpen(false)
	}

	const getFirewallGroupsActionsList = () => {
			let firewallGroup_actions = []
			let new_action = {}
			new_action["value"] = "admin_state_update"
			new_action["action"] = handleAdminStateChangeDialogOpen
			new_action["keyword"] = "adminStateChangeActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallGroup_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "share_state_update"
			// new_action["action"] = handleSharedStateChangeDialogOpen
			// new_action["keyword"] = "shareStateChangeActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// firewallGroup_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "firewallGroup_delete"
			new_action["action"] = onFirewallGroupDeleteConfirm
			new_action["keyword"] = "firewallGroupDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallGroup_actions.push({...new_action})
			
			return firewallGroup_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedFirewallGroup(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}?${firewallGroupFilterQueryParams}${firewallGroupsSortParams}`
							const method = "GET"
							const firewallGroup_response = await openstackRequest({url:url, method:method, token, })
							if (firewallGroup_response.status_code === firewallUrlResponses.get.success_response.status_code) {
									setFirewallGroupsData(firewallGroup_response.data.firewall_groups)
									if (selectedFirewallGroup) {
											const selected_firewallGroup = firewallGroup_response.data.firewall_groups.filter(item => item.id === selectedFirewallGroup)
											if (selected_firewallGroup.length > 0) {
													setSelectedRow(selected_firewallGroup[0])
											}
									}
							} else {
								setFirewallGroupsData([])
							}
							
							
					})();
			}
			
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			firewallGroupsUrl, 
			firewallGroupFilterQueryParams,
			dataFetchingRequired,
			token,
			firewallGroupsSortParams,
			selectedFirewallGroup
	]);

	useEffect(() => {
			(async () => {

				const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}`
				const method = "GET"
				
				const fp_response = await openstackRequest({
						url:url, 
						method:method,
						token,
				})

				if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
						setFirewallPolicies(fp_response.data.firewall_policies)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			firewallPoliciesUrl,
			token
	]);

	useEffect(() => {
			if (firewallGroupsData.length > 0) {
					handleFirewallGroupsDataFormatting()
			} else {
				setFirewallGroups([])
			}
	},[
			firewallGroupsData,
			handleFirewallGroupsDataFormatting
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const firewallGroup_id = location.state ? location.state.firewallGroup_id : null
							const firewallGroup_index = firewallGroupsData.findIndex(v => v.id === firewallGroup_id);
							if (firewallGroup_index !== -1) {
									setTimeout(() => handleDetailCardOpen(firewallGroup_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			firewallGroupsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-fwaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getFirewallGroupsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedFirewallGroups([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getFirewallGroupsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<FirewallGroupAddV20
					handleDataFetch={handleDataFetch}
					firewallPolicies={firewallPolicies}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						label={defaultTexts[firewallGroupsFilterMenu[0].keyword]}
						currentValue={selectedFirewallGroupFilterValue}
						setCurrentValue={e => setSelectedFirewallGroupFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFirewallGroupFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFirewallGroupFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-fwaas'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={firewallGroupsData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.firewallGroupsTabButtonText} ({selectedIds.length}/{firewallGroupsData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<FirewallGroupsTableV20 
				firewallGroupsData={firewallGroups}
				seFirewallGroupsData={setFirewallGroups}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getFirewallGroupsActionsList()}
				sortHandler={handleFirewallGroupsSorting}
				sortParams={firewallGroupsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<FirewallGroupDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedFirewallGroup={setSelectedFirewallGroup}
				handleDataFetch={handleDataFetch}
				handleDelete={onFirewallGroupDeleteConfirm}
				firewallPolicies={firewallPolicies}
				firewallGroups={firewallGroupsData}
				adminStateChange={handleAdminStateChangeDialogOpen}
				sharedStateChange={handleSharedStateChangeDialogOpen}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
					open={firewallGroupDeleteConfirmDialogOpen}
					onClose={handleFirewallGroupDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.firewallGroupDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.firewallGroupDeleteConfirmText}: [${selectedFirewallGroups.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onFirewallGroupDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
					open={adminStateChangeDialogOpen}
					onClose={handleAdminStateChangeDialogClose}
					dialogTitle={{
							title: defaultTexts.changeAdminStateTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onChangeAdminState, 
							sx: {color: 'primary.main'}}]}
				>
					<CustomCheckboxField
						currentValue={selectedAdminState}
						setCurrentValue={handleSelectedAdminStateChange}
						label={defaultTexts.adminStateFormFieldLabel}
						default_value={false}
					/>
				</CustomDialog>

				<CustomDialog
					open={sharedStateChangeDialogOpen}
					onClose={handleSharedStateChangeDialogClose}
					dialogTitle={{
							title: defaultTexts.changeSharedStateTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onChangeSharedState, 
							sx: {color: 'primary.main'}}]}
				>
					<CustomCheckboxField
						currentValue={selectedSharedState}
						setCurrentValue={handleSelectedSharedStateChange}
						label={defaultTexts.sharedFormFieldLabel}
						default_value={false}
					/>
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default FirewallGroupsWrapperV20