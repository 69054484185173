import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IKEPoliciesWrapperV20 from './vpn_ike_policies/ikePoliciesWrapperV2.0';
import IPsecPoliciesWrapperV20 from './vpn_ipsec_policies/ipsecPoliciesWrapperV2.0';
import VPNServicesWrapperV20 from './vpn_services/vpnServicesWrapperV2.0';
import EndpointGroupsWrapperV20 from './vpn_endpoint_groups/vpnEndpointGroupsWrapperV2.0';
import SiteConnectionsWrapperV20 from './vpn_site_connections/siteConnectionsWrapperV2.0';

const VPNaaSWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, selectedSubmenu } = props

	const getSelectedSubmenu = () => {
		const submenu = ['ike_policies', 'ipsec_policies', 'vpn_services', 'endpoint_groups', 'site_connections']
		return submenu.includes(selectedSubmenu) ? selectedSubmenu : submenu[0]
	}

	const [currentTab, setCurrentTab] = useState(getSelectedSubmenu())
	
	return (
		<Box>
				{currentTab === "ike_policies" && 
						<IKEPoliciesWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "ipsec_policies" && 
						<IPsecPoliciesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "vpn_services" &&
						<VPNServicesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "endpoint_groups" &&
						<EndpointGroupsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "site_connections" &&
						<SiteConnectionsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default VPNaaSWrapperV20