import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import IKEPoliciesTableV20 from './ikePoliciesTableV2.0';
import { vpnIKEPoliciesFilterMenu, vpnIKEPolicyDataForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import IkePolicyAddV20 from './ikePolicyAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';

const SERVICE_NAME = openStackServices.networkService

const IKEPoliciesWrapperV20 = (props) => {
	const theme = useTheme()
	const { currentTab, setCurrentTab, serviceMenu, serviceSubmenu, changeMenuActiveTab } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const [ikePoliciesData, setIkePoliciesData] = useState([])
	const [ikePolicies, setIkePolicies] = useState([])
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [ikePolicyDeleteConfirmDialogOpen, setIkePolicyDeleteConfirmDialogOpen] = useState(false);
	const [selectedIkePolicies, setSelectedIkePolicies] = useState([])
	
	const [ikePolicyFilterQueryParams, setIkePolicyFilterQueryParams] = useState("")
	const [selectedIkePolicyFilter, setSelectedIkePolicyFilter] = useState(vpnIKEPoliciesFilterMenu[0].value)
	const [selectedIkePolicyFilterValue, setSelectedIkePolicyFilterValue] = useState("")

	const [updateDataOptions, setUpdateDataOptions] = useState({});
	const [updateFormData, setUpdateFormData] = useState({});
	const [ikePolicyUpdateDialogOpen, setIKEPolicyUpdateDialogOpen] = useState(false)
	const [selectedFieldKey, setSelectedFieldKey] = useState("")
	const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const vpnIKEPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnIKEPoliciesUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleIkePolicyFilteredSearch = () => {
			if (selectedIkePolicyFilter && selectedIkePolicyFilterValue) {
					setIkePolicyFilterQueryParams(`${selectedIkePolicyFilter}=${selectedIkePolicyFilterValue}`)
			} else {
					setIkePolicyFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleIkePoliciesDataFormatting = useCallback(() => {
			const formatted_data = ikePoliciesData.map((item) => {
					let new_item = {...item}
					
					new_item.lifetime = `${item.lifetime.value} ${item.lifetime.units}`
					return new_item
			})
			setIkePolicies(formatted_data)
	},[
			ikePoliciesData,
	])

	const handleFormDataChange = (event,field_key) => {
			setUpdateDataOptions({})
			let new_form_data = {...updateFormData}
			if (vpnIKEPolicyDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (vpnIKEPolicyDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUpdateFormData(new_form_data)
	}

	const getDataForm = () => {
			let form = vpnIKEPolicyDataForm.filter(i => i.field_key === selectedFieldKey)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {...updateDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									return (
											getFormFieldComponent(
													field,
													updateFormData,
													handleFormDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleUpdateDataValidation = (field_key, data) => {
			if (data[field_key].length === 0) {
					let options = {}
					options[field_key] = {}
					options[field_key]["error"] = true
					options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
					setUpdateDataOptions(options)
					return false
			} else {
					return true
			}
	}

	const handleIKEPolicyUpdate = async (eg_id,field_key,data) => {
			const data_is_valid = handleUpdateDataValidation(field_key,data)
			let update_data = {}
			update_data[field_key] = data[field_key]
			if (data_is_valid) {
					handleIKEPolicyUpdateDialogClose()
					
					const method = "PUT"
					const vpnGroup_response = await openstackRequest({
							url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}/${eg_id}`, 
							method: method, 
							data: {ikepolicy: update_data},
							token,
					})
					if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
							return null
					} else {
							return vpnGroup_response.error
					}
					
			} 
	}

	const onIKEPolicyUpdate = async () => {
			let err = []
			for (let n in selectedIkePolicies) {
					const resp = await handleIKEPolicyUpdate(
							selectedIkePolicies[n].id,
							selectedFieldKey,
							updateFormData)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			setUpdateDataOptions({})
			setUpdateFormData({})
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onIKEPolicyUpdateNameDialogOpen = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("name")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyNameTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const onIKEPolicyUpdateDescriptionDialogOpen = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("description")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyDescriptionTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const onIKEPolicyUpdateAuthAlgDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("auth_algorithm")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyAuthAlgTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const onIKEPolicyUpdateEncrAlgDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("encryption_algorithm")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyEncrAlgTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const onIKEPolicyUpdatePFSGroupDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("pfs")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyPFSGroupTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const onIKEPolicyUpdateIKEVersionDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setSelectedFieldKey("ike_version")
			setSelectedFieldTitleText(defaultTexts.updateIkePolicyIKEVersionTitle)
			setIKEPolicyUpdateDialogOpen(true)
	},[ikePoliciesData, defaultTexts])

	const handleIKEPolicyUpdateDialogClose = () => {
			setIKEPolicyUpdateDialogOpen(false)
	}

	const handleIkePolicyFilterReset = () => {
			setSelectedIkePolicyFilter(vpnIKEPoliciesFilterMenu[0].value)
			setSelectedIkePolicyFilterValue("")
			setIkePolicyFilterQueryParams("")
			handleDataFetch()
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleIkePolicyDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onIkePolicyDelete = async () => {
			handleIkePolicyDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedIkePolicies) {
					const resp = await handleIkePolicyDelete(selectedIkePolicies[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onIkePolicyDeleteConfirm = useCallback((n_list) => {
			const selected_n_list = ikePoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIkePolicies([...selected_n_list])
			setIkePolicyDeleteConfirmDialogOpen(true)
	},[ikePoliciesData])

	const handleIkePolicyDeleteConfirmDialogClose = () => {
			setIkePolicyDeleteConfirmDialogOpen(false)
	}

	const getIkePoliciesActionsList = useCallback(() => {
			let ikePolicy_actions = []
			let new_action = {}
			new_action["value"] = "ike_policy_update_name"
			new_action["action"] = onIKEPolicyUpdateNameDialogOpen
			new_action["keyword"] = "ikePolicyUpdateNameActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ike_policy_update_description"
			new_action["action"] = onIKEPolicyUpdateDescriptionDialogOpen
			new_action["keyword"] = "ikePolicyUpdateDescriptionActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ike_policy_update_auth_alg"
			new_action["action"] = onIKEPolicyUpdateAuthAlgDialogOpen
			new_action["keyword"] = "ikePolicyUpdateAuthAlgActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ike_policy_update_encr_alg"
			new_action["action"] = onIKEPolicyUpdateEncrAlgDialogOpen
			new_action["keyword"] = "ikePolicyUpdateEncrAlgActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ike_policy_update_pfs"
			new_action["action"] = onIKEPolicyUpdatePFSGroupDialogOpen
			new_action["keyword"] = "ikePolicyUpdatePFSGroupActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ike_policy_update_version"
			new_action["action"] = onIKEPolicyUpdateIKEVersionDialogOpen
			new_action["keyword"] = "ikePolicyUpdateIKEVersionActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ikePolicy_delete"
			new_action["action"] = onIkePolicyDeleteConfirm
			new_action["keyword"] = "ikePolicyDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ikePolicy_actions.push({...new_action})
			
			return ikePolicy_actions
	},[
			onIkePolicyDeleteConfirm,
			onIKEPolicyUpdateNameDialogOpen,
			onIKEPolicyUpdateDescriptionDialogOpen,
			onIKEPolicyUpdateAuthAlgDialogOpen,
			onIKEPolicyUpdateEncrAlgDialogOpen,
			onIKEPolicyUpdatePFSGroupDialogOpen,
			onIKEPolicyUpdateIKEVersionDialogOpen
	])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}?${ikePolicyFilterQueryParams}`
							const method = "GET"
							const ikePolicy_response = await openstackRequest({url:url, method:method, token, })
							if (ikePolicy_response.status_code === vpnUrlResponses.get.success_response.status_code) {
									setIkePoliciesData(ikePolicy_response.data.ikepolicies)
							} else {
								setIkePoliciesData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnIKEPoliciesUrl, 
			ikePolicyFilterQueryParams,
			dataFetchingRequired,
			token
	]);

	useEffect(() => {
			if (ikePoliciesData.length > 0) {
					handleIkePoliciesDataFormatting()
			} else {
				setIkePolicies([])
			}
	},[
			ikePoliciesData,
			handleIkePoliciesDataFormatting
	])

	useEffect(() => {
			if (Object.keys(updateFormData).length === 0) {
					let new_form_data = {}
					for (const n in vpnIKEPolicyDataForm) {
							if (
									vpnIKEPolicyDataForm[n].field_type === "string" || 
									vpnIKEPolicyDataForm[n].field_type === "select"
									) {
									new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
									vpnIKEPolicyDataForm[n].default_value : 
									""
							} else if (vpnIKEPolicyDataForm[n].field_type === "integer") {
									new_form_data[vpnIKEPolicyDataForm[n].field_key] = 3600
							} else if (vpnIKEPolicyDataForm[n].field_type === "bool") {
									new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
									vpnIKEPolicyDataForm[n].default_value : 
									false
							}
					}
					setUpdateFormData(new_form_data)
			}
	},[
			updateFormData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-vpnaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getIkePoliciesActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedIkePolicies([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getIkePoliciesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<IkePolicyAddV20
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						label={defaultTexts[vpnIKEPoliciesFilterMenu[0].keyword]}
						currentValue={selectedIkePolicyFilterValue}
						setCurrentValue={e => setSelectedIkePolicyFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleIkePolicyFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleIkePolicyFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-vpnaas'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={ikePoliciesData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.ikePoliciesTabButtonText} ({selectedIds.length}/{ikePoliciesData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<IKEPoliciesTableV20 
				ikePoliciesData={ikePolicies}
				setIkePoliciesData={setIkePolicies}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getIkePoliciesActionsList()}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	return (
		<>
			{ isLoading && <CustomBackdrop open={isLoading} /> }

			{ getNetworksHeader() }

			{ !isLoading && getNetworksTable() }

			<CustomDialog
					open={ikePolicyDeleteConfirmDialogOpen}
					onClose={handleIkePolicyDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.ikePolicyDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.ikePolicyDeleteConfirmText}`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onIkePolicyDelete, 
							sx: {color: 'primary.main'}}]}
			/>
			<CustomDialog
					open={ikePolicyUpdateDialogOpen}
					onClose={handleIKEPolicyUpdateDialogClose}
					dialogTitle={{
							title: selectedFieldTitleText, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onIKEPolicyUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm()}
			</CustomDialog>
			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
}

export default IKEPoliciesWrapperV20