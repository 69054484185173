import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import TrunksTableV20 from './trunksTableV2.0';
import TrunkDetailV20 from './trunkDetailV2.0';
import { trunksFilterMenu, trunkAdminStateUpdateForm } from '../../../../../../_data/openstack/neutron/trunks/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { trunksUrl as trunkUrlResponses } from '../../../../../../_api_responses/openstack/neutron/trunks/v2.0';
import { networksUrl as networkUrlResponses} from '../../../../../../_api_responses/openstack/neutron/networks/v2.0'
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import TrunkAddV20 from './trunkAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const TrunksWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const {currentTab, setCurrentTab} = props
	const [trunksData, setTrunksData] = useState([])
	const [trunks, setTrunks] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedTrunk, setSelectedTrunk] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [trunkDeleteConfirmDialogOpen, setTrunkDeleteConfirmDialogOpen] = useState(false);
	const [selectedTrunks, setSelectedTrunks] = useState([])
	const [trunksSortParams, setTrunksSortParams] = useState("")
	
	const [trunkFilterQueryParams, setTrunkFilterQueryParams] = useState("")
	const [selectedTrunkFilter, setSelectedTrunkFilter] = useState(trunksFilterMenu[0].value)
	const [selectedTrunkFilterValue, setSelectedTrunkFilterValue] = useState("")
	const [trunksFilter, setTrunksFilter] = useState([...trunksFilterMenu])
	const [trunkUpdateData, setTrunkUpdateData] = useState({})
	const [selectedUpdateField, setSelectedUpdateField] = useState("")
	const [trunkUpdateDialogOpen, setTrunkUpdateDialogOpen] = useState(false)

	const [networks, setNetworks] = useState([])
	const [subnets, setSubnets] = useState([])
	const [ports, setPorts] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const trunksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
	const portsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.portsUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = trunksFilterMenu.find(item => item.value === selectedTrunkFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		// if (selectedVirtualIPFilter === 'network_id') {
		// 	let networks_filter = []
		// 	networks.forEach(item => networks_filter.push({ keyword: item.name, value: item.id, default: false }))
		// 	return networks_filter
		// }

		const filterObject = trunksFilterMenu.find(item => item.value === selectedTrunkFilter)
		return filterObject ? filterObject.items : []
	}

	const handleTrunkFilteredSearch = () => {
			if (selectedTrunkFilter && selectedTrunkFilterValue) {
					setTrunkFilterQueryParams(`${selectedTrunkFilter}=${selectedTrunkFilterValue}`)
			} else {
					setTrunkFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleTrunksDataFormatting = useCallback(() => {
			const formatted_data = trunksData.map((item) => {
					let new_item = {...item}
					
					new_item.sub_ports = item.sub_ports.length
					
					return new_item
			})

			setTrunks(formatted_data)
	},[
			trunksData,
	])

	const handleTrunkFilterReset = () => {
			setSelectedTrunkFilter(trunksFilterMenu[0].value)
			setSelectedTrunkFilterValue("")
			setTrunkFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedTrunk(trunksData[index].id)
			setSelectedRow(trunksData[index])
	},[trunksData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedTrunk(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleTrunksSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setTrunksSortParams(sort_param)
			handleDataFetch()
	}

	const handleTrunkUpdate = async (p_id,data) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}/${p_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {trunk: data},
				token,
		})

		if (nt_response.status_code === trunkUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onTrunkUpdate = async () => {
			handleTrunkUpdateDialogClose()
			let err = []
			const data = {}
			data[selectedUpdateField] = trunkUpdateData[selectedUpdateField]
			for (let n in selectedTrunks) {
					const resp = await handleTrunkUpdate(selectedTrunks[n].id, data)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorUpdateRecordTitle"
					error_object["error_message"] = "errorUpdateRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleTrunkDelete = async (p_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}/${p_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === trunkUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onTrunkDelete = async () => {
			handleTrunkDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedTrunks) {
					const resp = await handleTrunkDelete(selectedTrunks[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onTrunkDeleteConfirm = (n_list) => {
			const selected_n_list = trunksData.filter(n => 
					n_list.includes(n.id))
			setSelectedTrunks([...selected_n_list])
			setTrunkDeleteConfirmDialogOpen(true)
	}

	const handleTrunkDeleteConfirmDialogClose = () => {
			setTrunkDeleteConfirmDialogOpen(false)
	}

	const onTrunkUpdateAdminState = (n_list) => {
			const selected_n_list = trunksData.filter(n => 
					n_list.includes(n.id))
			setSelectedTrunks([...selected_n_list])
			setSelectedUpdateField("admin_state_up")
			setTrunkUpdateDialogOpen(true)
	}

	const handleTrunkUpdateDialogClose = () => {
			setTrunkUpdateDialogOpen(false)
	}

	const handleTrunkUpdateDataChange = (event,field_key) => {
			let updated_data = {...trunkUpdateData}
			const field_type = trunkAdminStateUpdateForm.filter(field => field.field_key === field_key)[0].field_type
			if (field_type === "bool") {
					updated_data[field_key] = event.target.checked
			} else if (field_type === "select") {
					updated_data[field_key] = event
			} else {
					updated_data[field_key] = event.target.value
			}
			setTrunkUpdateData(updated_data)        
	}

	const getUpdateForm = () => {
			let form = trunkAdminStateUpdateForm.filter(field => field.field_key === selectedUpdateField)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													trunkUpdateData,
													handleTrunkUpdateDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getTrunksActionsList = () => {
			let trunk_actions = []
			let new_action = {}
			new_action["value"] = "update_admin_state"
			new_action["action"] = onTrunkUpdateAdminState
			new_action["keyword"] = "trunkUpdateAdminStateActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			trunk_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "trunk_delete"
			new_action["action"] = onTrunkDeleteConfirm
			new_action["keyword"] = "trunkDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			trunk_actions.push({...new_action})
			
			return trunk_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedTrunk(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}?${trunkFilterQueryParams}${trunksSortParams}`
							const method = "GET"
							const trunk_response = await openstackRequest({url:url, method:method, token, })
							if (trunk_response.status_code === trunkUrlResponses.get.success_response.status_code) {
									setTrunksData(trunk_response.data.trunks)
									if (selectedTrunk) {
											const selected_trunk = trunk_response.data.trunks.filter(item => item.id === selectedTrunk)
											if (selected_trunk.length > 0) {
													setSelectedRow(selected_trunk[0])
											}
									}
							} else {
								setTrunksData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			trunksUrl, 
			trunkFilterQueryParams,
			dataFetchingRequired,
			token,
			trunksSortParams,
			selectedTrunk
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
				const method = "GET"
				const subnets_response = await openstackRequest({url:url, method:method, token, })
				if (subnets_response.status_code === trunkUrlResponses.get.success_response.status_code) {
						setSubnets(subnets_response.data.subnets)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			subnetsUrl, 
			token
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
				const method = "GET"
				const ports_response = await openstackRequest({url:url, method:method, token, })
				if (ports_response.status_code === trunkUrlResponses.get.success_response.status_code) {
						setPorts(ports_response.data.ports)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			portsUrl, 
			selectedRow,
			token
	]);

	useEffect(() => {
			(async () => {

				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl,
			token
	]);

	useEffect(() => {
			if (trunksData.length > 0) {
					handleTrunksDataFormatting()
			} else {
				setTrunks([])
			}
	},[
			trunksData,
			handleTrunksDataFormatting
	])

	useEffect(() => {
			let networks_filter = []
			if (networks.length > 0) {
					networks_filter = networks.map(network => {
							return {keyword: network.name, value: network.id, default: false}
					})
			}
			
			let trunk_filter_menu = trunksFilterMenu.map(nt => {
					let new_item = {...nt}
					
					if (nt.value === "network_id") {
							new_item.items = [...networks_filter]
					}
					return new_item
			})

			setTrunksFilter(trunk_filter_menu)
	},[
			trunksData,
			networks
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const trunk_id = location.state ? location.state.trunk_id : null
							const trunk_index = trunksData.findIndex(v => v.id === trunk_id);
							if (trunk_index !== -1) {
									setTimeout(() => handleDetailCardOpen(trunk_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			trunksData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			if (Object.keys(trunkUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in trunkAdminStateUpdateForm) {
							if (
										trunkAdminStateUpdateForm[n].field_type === "string" || 
										trunkAdminStateUpdateForm[n].field_type === "select"
									) {
									new_form_data[trunkAdminStateUpdateForm[n].field_key] = ""
							} else if (trunkAdminStateUpdateForm[n].field_type === "bool") {
									new_form_data[trunkAdminStateUpdateForm[n].field_key] = trunkAdminStateUpdateForm[n].default_value ? 
									trunkAdminStateUpdateForm[n].default_value : 
									false
							}
					}
					setTrunkUpdateData(new_form_data)
			}
	},[
			trunkUpdateData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l2-networks')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedTrunkFilterValue('')
	}, [selectedTrunkFilter])

	const handleActionRun = () => {
		const actionsList = getTrunksActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedTrunks([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getTrunksActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<TrunkAddV20
					handleDataFetch={handleDataFetch}
					subnets={subnets}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedTrunkFilterValue}
							setCurrentValue={e => setSelectedTrunkFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedTrunkFilterValue}
							setCurrentValue={setSelectedTrunkFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							self_item_titles={false}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={trunksFilter} 
						currentValue={selectedTrunkFilter}
						setCurrentValue={setSelectedTrunkFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleTrunkFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleTrunkFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>

					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/l2-networks'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={trunksData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.trunksTabButtonText} ({selectedIds.length}/{trunksData.length})
					</Box>
				}	

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<TrunksTableV20 
				trunksData={trunks}
				setTrunksData={setTrunks}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getTrunksActionsList()}
				sortHandler={handleTrunksSorting}
				sortParams={trunksSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<TrunkDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedTrunk={setSelectedTrunk}
				handleDataFetch={handleDataFetch}
				handleDelete={onTrunkDeleteConfirm}
				handleAdminStateUpdate={onTrunkUpdateAdminState}
				ports={ports}
				subnets={subnets}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
						open={trunkDeleteConfirmDialogOpen}
						onClose={handleTrunkDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.trunkDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.trunkDeleteConfirmText}`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onTrunkDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				<CustomDialog
						open={trunkUpdateDialogOpen}
						onClose={handleTrunkUpdateDialogClose}
						dialogTitle={{
								title: defaultTexts.trunkAdminStateUpdateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onTrunkUpdate, 
								sx: {color: 'primary.main'}}]}
				>
								{getUpdateForm()}
				</CustomDialog>
				{error && <CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
				/>}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default TrunksWrapperV20