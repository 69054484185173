import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import CustomSelectField from './_form_fields/CustomSelectField'

const ServiceDoubleMenuMobile = (props) => {
  const { serviceMenu, serviceSubmenu, currentTab, onMenuClick, onSubmenuClick } = props

  const defaultTexts = useSelector(state => state.texts.langTexts)

  const getServiceMenuList = () => {
    let service_menu_list = []
    serviceMenu.forEach(item => service_menu_list.push({
      value: item.navigation,
      keyword: item.keyword,
    }))
    return service_menu_list
  }

  const getServiceSubmenuList = () => {
    let service_submenu_list = []
    serviceSubmenu.forEach(item => service_submenu_list.push({
      value: item.navigation,
      keyword: item.keyword,
    }))
    return service_submenu_list
  }

  const getServiceMenuSelectedItem = () => {
    return serviceMenu.filter(item => item.is_active)[0].navigation
  }

  const handleChangeMenuItem = (value) => {
    onMenuClick(value)
  }

  const handleChangeSubmenuItem = (value) => {
    onSubmenuClick(value)
  }

  return (
    <Grid container>
      <Grid xs={12} sm={6}>
        <CustomSelectField
          items={getServiceMenuList()} 
          currentValue={getServiceMenuSelectedItem()}
          setCurrentValue={handleChangeMenuItem}
          item_titles={defaultTexts}
          empty={true}
          size="small"
          sx={{ width: '240px', margin: '0px 10px 10px 0px' }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <CustomSelectField
          items={getServiceSubmenuList()} 
          currentValue={currentTab}
          setCurrentValue={handleChangeSubmenuItem}
          item_titles={defaultTexts}
          empty={true}
          size="small"
          sx={{ width: '240px' }}
        />
      </Grid>
    </Grid>
  )
}

export default ServiceDoubleMenuMobile