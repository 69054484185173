import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import SiteConnectionsTableV20 from './siteConnectionsTableV2.0';
import SiteConnectionDetailV20 from './siteConnectionDetailV2.0';
import { vpnSiteConnectionsFilterMenu, vpnSiteConnectionUpdateForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import SiteConnectionAddV20 from './siteConnectionAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const VPNSiteConnectionsWrapperV20 = (props) => {
	const theme = useTheme()
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const { navigate, location, currentTab, setCurrentTab, changeMenuActiveTab, serviceMenu, serviceSubmenu } = props
	const [vpnSiteConnectionsData, setVPNSiteConnectionsData] = useState([])
	const [vpnSiteConnections, setVPNSiteConnections] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedSiteConnection, setSelectedSiteConnection] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [vpnSiteConnectionDeleteConfirmDialogOpen, setVPNSiteConnectionDeleteConfirmDialogOpen] = useState(false);
	const [selectedVPNSiteConnections, setSelectedVPNSiteConnections] = useState([])
	
	const [vpnSiteConnectionFilterQueryParams, setVPNSiteConnectionFilterQueryParams] = useState("")
	const [selectedVPNSiteConnectionFilter, setSelectedVPNSiteConnectionFilter] = useState(vpnSiteConnectionsFilterMenu[0].value)
	const [selectedVPNSiteConnectionFilterValue, setSelectedVPNSiteConnectionFilterValue] = useState("")
	const [vpnSiteConnectionsFilter, setVPNSiteConnectionsFilter] = useState([...vpnSiteConnectionsFilterMenu])

	const [updateDataOptions, setUpdateDataOptions] = useState({});
	const [updateFormData, setUpdateFormData] = useState({});
	const [vpnSiteConnectionUpdateDialogOpen, setVPNSiteConnectionUpdateDialogOpen] = useState(false)
	const [ikePolicies, setIKEPolicies] = useState([]);
	const [ipsecPolicies, setIPsecPolicies] = useState([]);
	const [vpnServices, setVPNServices] = useState([]);
	const [endpointGroups, setEndpointGroups] = useState([]);

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const vpnSiteConnectionsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnSiteConnectionsUrl)[0].url)
	const vpnIKEPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnIKEPoliciesUrl)[0].url)
	const vpnIPsecPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnIPsecPoliciesUrl)[0].url)
	const vpnServicesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnServicesUrl)[0].url)
	const vpnEndpointGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnEndpointGroupsUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	// const handleSiteConnectionSelection = (selected_rows) => {
	// 		if (selected_rows.length > 1 && currentAction === "vpn_site_update") {
	// 				setCurrentAction("")
	// 		}
	// 		setSelectedSiteConnectionIds(selected_rows)
	// }

	const handleVPNSiteConnectionFilteredSearch = () => {
			if (selectedVPNSiteConnectionFilter && selectedVPNSiteConnectionFilterValue) {
					setVPNSiteConnectionFilterQueryParams(`${selectedVPNSiteConnectionFilter}=${selectedVPNSiteConnectionFilterValue}`)
			} else {
					setVPNSiteConnectionFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleVPNSiteConnectionsDataFormatting = useCallback(() => {
			const formatted_data = vpnSiteConnectionsData.map((item) => {
					let new_item = {...item}

					const ike_policy = ikePolicies.filter(p => p.id === item.ikepolicy_id)
					if (ike_policy.length > 0) {
							new_item.ikepolicy_id = ike_policy[0].name
					} else {
							new_item.ikepolicy_id = "-"
					}

					const ipsec_policy = ipsecPolicies.filter(p => p.id === item.ipsecpolicy_id)
					if (ipsec_policy.length > 0) {
							new_item.ipsecpolicy_id = ipsec_policy[0].name
					} else {
							new_item.ipsecpolicy_id = "-"
					}

					const vpn_service = vpnServices.filter(s => s.id === item.vpnservice_id)
					if (vpn_service.length > 0) {
							new_item.vpnservice_id = vpn_service[0].name
					} else {
							new_item.vpnservice_id = "-"
					}
					const local_eg = endpointGroups.filter(s => s.id === item.local_ep_group_id)
					if (local_eg.length > 0) {
							new_item.local_ep_group_id = local_eg[0].name
					} else {
							new_item.local_ep_group_id = "-"
					}
					const peer_eg = endpointGroups.filter(s => s.id === item.peer_ep_group_id)
					if (peer_eg.length > 0) {
							new_item.peer_ep_group_id = peer_eg[0].name
					} else {
							new_item.peer_ep_group_id = "-"
					}
					new_item.dpd = []
					new_item.dpd.push(`${defaultTexts.dpdActionFormFieldLabel}: ${item.dpd.action}`)
					new_item.dpd.push(`${defaultTexts.dpdIntervalFormFieldLabel}: ${item.dpd.interval}`)
					new_item.dpd.push(`${defaultTexts.dpdTimeoutFormFieldLabel}: ${item.dpd.timeout}`)

					new_item.peer_cidrs = item.peer_cidrs.length > 0 ? item.peer_cidrs : ["-"]
					return new_item
			})
			setVPNSiteConnections(formatted_data)
	},[
			vpnSiteConnectionsData,
			defaultTexts,
			endpointGroups,
			ikePolicies,
			ipsecPolicies,
			vpnServices
	])

	const handleVPNSiteConnectionFilterReset = () => {
			setSelectedVPNSiteConnectionFilter(vpnSiteConnectionsFilterMenu[0].value)
			setSelectedVPNSiteConnectionFilterValue("")
			setVPNSiteConnectionFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
		setSelectedSiteConnection(vpnSiteConnectionsData[index].id)
		setSelectedRow(vpnSiteConnectionsData[index])
	}, [vpnSiteConnectionsData])

	const handleDetailCardClose = () => {
		setSelectedRow(null)
		setSelectedSiteConnection(null)
		navigate(location.path, {})
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFormDataChange = (event,field_key) => {
			setUpdateDataOptions({})
			let new_form_data = {...updateFormData}
			if (vpnSiteConnectionUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (vpnSiteConnectionUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUpdateFormData(new_form_data)
	}

	const getDataForm = () => {
			let form = [...vpnSiteConnectionUpdateForm]
			return (
					<FormGroup>
							{form.map(field => {
									let project_id = null
									if (selectedVPNSiteConnections.length === 1) {
											project_id = selectedVPNSiteConnections[0].project_id
									}
									let form_field_options = {...updateDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									if (field.field_key === "ikepolicy_id") {
											const ike_policies_filter = ikePolicies.filter(r => r.project_id === project_id)
											const policy_list = ike_policies_filter.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...policy_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									} else if (field.field_key === "ipsecpolicy_id") {
											const ipsec_policies_filter = ipsecPolicies.filter(r => r.project_id === project_id)
											const policy_list = ipsec_policies_filter.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...policy_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									} else if (field.field_key === "vpnservice_id") {
											const vpn_service_filter = vpnServices.filter(r => r.project_id === project_id)
											const service_list = vpn_service_filter.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...service_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									} else if (field.field_key === "local_ep_group_id") {
											const endpoint_groups_filter = endpointGroups.filter(r => r.project_id === project_id)
											const eg_list = endpoint_groups_filter.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...eg_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									} else if (field.field_key === "peer_ep_group_id") {
											const endpoint_groups_filter = endpointGroups.filter(r => r.project_id === project_id)
											const eg_list = endpoint_groups_filter.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...eg_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									}
									return (
											getFormFieldComponent(
													field,
													updateFormData,
													handleFormDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleFormDataValidation = () => {
			let validation_faild = true
			let updatedDataFormOptions = {...updateDataOptions}
			for (let n in vpnSiteConnectionUpdateForm) {
					if (vpnSiteConnectionUpdateForm[n].required && !updateFormData[vpnSiteConnectionUpdateForm[n].field_key]) {
							validation_faild = false
							updatedDataFormOptions[vpnSiteConnectionUpdateForm[n].field_key] = {}
							updatedDataFormOptions[vpnSiteConnectionUpdateForm[n].field_key]["error"] = true
							updatedDataFormOptions[vpnSiteConnectionUpdateForm[n].field_key]["errorText"] = defaultTexts[vpnSiteConnectionUpdateForm[n].error_label]
					}
			}

			setUpdateDataOptions(updatedDataFormOptions)
			return validation_faild
	}

	const formatConnetionData = () => {
			let updated_data = {...updateFormData}
			console.log(updateFormData)
			updated_data["dpd"] = {}
			updated_data["dpd"]["interval"] = updateFormData.interval
			updated_data["dpd"]["timeout"] = updateFormData.timeout
			updated_data["dpd"]["action"] = updateFormData.action
			if (updateFormData.peer_cidrs.length > 0) {
					updated_data.peer_cidrs = updateFormData.peer_cidrs
			} else {
					delete updated_data.peer_cidrs
			}
			
			delete updated_data.interval
			delete updated_data.timeout
			delete updated_data.action
			
			return updated_data
	}

	const handleVPNSiteConnectionUpdate = async (eg_id) => {
			const data_is_valid = handleFormDataValidation()
			if (data_is_valid) {
					const formatted_data = formatConnetionData()
					handleVPNSiteConnectionUpdateDialogClose()
					
					const method = "PUT"
					const vpnGroup_response = await openstackRequest({
							url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnSiteConnectionsUrl}/${eg_id}`, 
							method: method, 
							data: {ipsec_site_connection: formatted_data},
							token,
					})
					if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
							return null
					} else {
							return vpnGroup_response.error
					}					
			}
	}

	const onVPNSiteConnectionUpdate = async () => {
			let err = []
			for (let n in selectedVPNSiteConnections) {
					const resp = await handleVPNSiteConnectionUpdate(selectedVPNSiteConnections[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			setCurrentAction("")
			setUpdateDataOptions({})
			setUpdateFormData({})
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onVPNSiteConnectionUpdateNameDialogOpen = useCallback((n_list) => {
			const selected_n_list = vpnSiteConnectionsData.filter(n => 
					n_list.includes(n.id))
			setSelectedVPNSiteConnections([...selected_n_list])
			setVPNSiteConnectionUpdateDialogOpen(true)
	},[vpnSiteConnectionsData])

const handleVPNSiteConnectionUpdateDialogClose = () => {
			setVPNSiteConnectionUpdateDialogOpen(false)
	}

	const handleVPNSiteConnectionUpdateDialogReset = () => {
			setCurrentAction("")
			setUpdateDataOptions({})
			setUpdateFormData({})
			setVPNSiteConnectionUpdateDialogOpen(false)
	}

	const handleVPNSiteConnectionDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnSiteConnectionsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onVPNSiteConnectionDelete = async () => {
			handleVPNSiteConnectionDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedVPNSiteConnections) {
					const resp = await handleVPNSiteConnectionDelete(selectedVPNSiteConnections[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}


	const onVPNSiteConnectionDeleteConfirm = useCallback((n_list) => {
			const selected_n_list = vpnSiteConnectionsData.filter(n => 
					n_list.includes(n.id))
			setSelectedVPNSiteConnections([...selected_n_list])
			setVPNSiteConnectionDeleteConfirmDialogOpen(true)
	},[vpnSiteConnectionsData])

	const handleVPNSiteConnectionDeleteConfirmDialogClose = () => {
			setVPNSiteConnectionDeleteConfirmDialogOpen(false)
	}

	const getVPNSiteConnectionsActionsList = useCallback(() => {
			let vpnSiteConnection_actions = []
			let new_action = {}
			if (selectedIds.length === 1) {
					new_action["value"] = "vpn_site_update"
					new_action["action"] = onVPNSiteConnectionUpdateNameDialogOpen
					new_action["keyword"] = "vpnSiteConnectionUpdateActionTitle"
					new_action["button_text"] = "applyButtonTitleText"
					vpnSiteConnection_actions.push({...new_action})
					new_action = {}
			}
			new_action["value"] = "vpnSiteConnection_delete"
			new_action["action"] = onVPNSiteConnectionDeleteConfirm
			new_action["keyword"] = "vpnSiteConnectionDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			vpnSiteConnection_actions.push({...new_action})
			
			return vpnSiteConnection_actions
	},[
			onVPNSiteConnectionDeleteConfirm,
			onVPNSiteConnectionUpdateNameDialogOpen,
			selectedIds
	])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedSiteConnection(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnSiteConnectionsUrl}?${vpnSiteConnectionFilterQueryParams}`
							const method = "GET"
							const vpnSiteConnection_response = await openstackRequest({url:url, method:method, token, })
							if (vpnSiteConnection_response.status_code === vpnUrlResponses.get.success_response.status_code) {
								setVPNSiteConnectionsData(vpnSiteConnection_response.data.ipsec_site_connections)
								if (selectedSiteConnection) {
									const selected_site_connection = vpnSiteConnection_response.data.ipsec_site_connections.filter(item => item.id === selectedSiteConnection)
									if (selected_site_connection.length > 0) {
											setSelectedRow(selected_site_connection[0])
									}
								}
							} else {
								setVPNSiteConnectionsData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnSiteConnectionsUrl, 
			vpnSiteConnectionFilterQueryParams,
			dataFetchingRequired,
			token,
			selectedSiteConnection
	])


	useEffect(() => {
			(async () => {			
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}`
				const method = "GET"
				const response = await openstackRequest({url:url, method:method, token, })
				if (response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setIKEPolicies(response.data.ikepolicies)
				}					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnIKEPoliciesUrl,
			token
	]);

	useEffect(() => {
			(async () => {
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}`
				const method = "GET"
				const response = await openstackRequest({url:url, method:method, token, })
				if (response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setIPsecPolicies(response.data.ipsecpolicies)
				}					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnIPsecPoliciesUrl,
			token
	]);

	useEffect(() => {
			(async () => {
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}`
				const method = "GET"
				const response = await openstackRequest({url:url, method:method, token, })
				if (response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setVPNServices(response.data.vpnservices)
				}					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnServicesUrl,
			token
	]);

	useEffect(() => {
			(async () => {
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnEndpointGroupsUrl}`
				const method = "GET"
				const response = await openstackRequest({url:url, method:method, token, })
				if (response.status_code === vpnUrlResponses.get.success_response.status_code) {
						setEndpointGroups(response.data.endpoint_groups)
				}					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnEndpointGroupsUrl,
			token
	]);

	useEffect(() => {
			if (vpnSiteConnectionsData.length > 0) {
				handleVPNSiteConnectionsDataFormatting()
			} else {
				setVPNSiteConnections([])
			}
	},[
			vpnSiteConnectionsData,
			handleVPNSiteConnectionsDataFormatting
	])

	useEffect(() => {
			let vpnSiteConnection_filter_menu = vpnSiteConnectionsFilterMenu.map(nt => {
					let new_item = {...nt}
					
					return new_item
			})

			setVPNSiteConnectionsFilter(vpnSiteConnection_filter_menu)
	},[
			vpnSiteConnectionsData,
	])

	useEffect(() => {
			if (selectedVPNSiteConnections.length === 1) {
					let new_update_data = {}
					for (let i in vpnSiteConnectionUpdateForm) {
							if (vpnSiteConnectionUpdateForm[i].field_key !== "dpd") {
									new_update_data[vpnSiteConnectionUpdateForm[i].field_key] = selectedVPNSiteConnections[0][vpnSiteConnectionUpdateForm[i].field_key]
							}
					}
					new_update_data["action"] = selectedVPNSiteConnections[0].dpd.action
					new_update_data["interval"] = selectedVPNSiteConnections[0].dpd.interval
					new_update_data["timeout"] = selectedVPNSiteConnections[0].dpd.timeout
					
					setUpdateFormData(new_update_data)
			}
	},[
			selectedVPNSiteConnections,
			selectedIds,
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-vpnaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedVPNSiteConnectionFilterValue('')
	}, [selectedVPNSiteConnectionFilter])

	const handleActionRun = () => {
		const actionsList = getVPNSiteConnectionsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedVPNSiteConnections([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getVPNSiteConnectionsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<SiteConnectionAddV20
					handleDataFetch={handleDataFetch}
					ikePolicies={ikePolicies}
					ipsecPolicies={ipsecPolicies}
					vpnServices={vpnServices}
					endpointGroups={endpointGroups}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						currentValue={selectedVPNSiteConnectionFilterValue}
						setCurrentValue={e => setSelectedVPNSiteConnectionFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={vpnSiteConnectionsFilter} 
						currentValue={selectedVPNSiteConnectionFilter}
						setCurrentValue={setSelectedVPNSiteConnectionFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleVPNSiteConnectionFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleVPNSiteConnectionFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>
					
					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/network-vpnaas'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={vpnSiteConnectionsData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}

				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.siteConnectionsTabButtonText} ({selectedIds.length}/{vpnSiteConnectionsData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<SiteConnectionsTableV20 
				vpnSiteConnectionsData={vpnSiteConnections}
				setVPNSiteConnectionsData={setVPNSiteConnections}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getVPNSiteConnectionsActionsList()}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<SiteConnectionDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedSiteConnection={setSelectedSiteConnection}
				handleDataFetch={handleDataFetch}
				handleDelete={onVPNSiteConnectionDeleteConfirm}
				handleEdit={onVPNSiteConnectionUpdateNameDialogOpen}
				vpnSiteConnections={vpnSiteConnectionsData}
				isCardLoading={isLoading}
				ikePolicies={ikePolicies}
				ipsecPolicies={ipsecPolicies}
				vpnServices={vpnServices}
				endpointGroups={endpointGroups}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }
				
				<CustomDialog
						open={vpnSiteConnectionDeleteConfirmDialogOpen}
						onClose={handleVPNSiteConnectionDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.vpnSiteConnectionDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.vpnSiteConnectionDeleteConfirmText}`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onVPNSiteConnectionDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				<CustomDialog
						open={vpnSiteConnectionUpdateDialogOpen}
						onClose={handleVPNSiteConnectionUpdateDialogReset}
						dialogTitle={{
								title: defaultTexts.vpnSiteConnectionUpdateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.submitButtonText, 
								onClick: onVPNSiteConnectionUpdate, 
								sx: {color: 'primary.main'}}]}
				>
						{getDataForm()}
				</CustomDialog>
				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default VPNSiteConnectionsWrapperV20