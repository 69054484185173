import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SubnetsWrapperV20 from './subnets/subnetsWrapperV2.0';
import FloatingIPsWrapperV20 from './floating_ips/floatingIPsWrapperV2.0';
import VirtualIPsWrapperV20 from './virtual_ips/virtualIPsWrapperV2.0';
import RoutersWrapperV20 from './routers/routersWrapperV2.0';

const L3NetworkingWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, selectedSubmenu, virtualIPsSupported } = props
	
	const getSelectedSubmenu = () => {
		const submenu = ['subnets', 'floating_ips', 'virtual_ips', 'routers']
		return submenu.includes(selectedSubmenu) ? selectedSubmenu : submenu[0]
	}

	const [currentTab, setCurrentTab] = useState(getSelectedSubmenu())

	return (
		<Box>
				{currentTab === "subnets" && 
						<SubnetsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "floating_ips" && 
						<FloatingIPsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{(currentTab === "virtual_ips" && virtualIPsSupported) &&
						<VirtualIPsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "routers" &&
						<RoutersWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default L3NetworkingWrapperV20