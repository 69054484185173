import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants, computeNovaConstants, } from '../../../../../../config/openStackConstants';
import FloatingIPsTableV20 from './floatingIPsTableV2.0';
import FloatingIPDetailV20 from './floatingIPDetailV2.0';
import { floatingIPsFilterMenu } from '../../../../../../_data/openstack/neutron/floating_ips/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { floatingIPsUrl as floatingIPUrlResponses } from '../../../../../../_api_responses/openstack/neutron/floating_ips/v2.0';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import FloatingIPAddV20 from './floatingIPAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import ServiceDoubleMenuMobile from '../../../../../_common/ServiceDoubleMenuMobile';
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService

const FloatingIPsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const menuMode = useSelector(state => state.settings.networkMenuMode)
	const { width } = useWindowDimensions()
	const {currentTab, setCurrentTab} = props
	const [floatingIPsData, setFloatingIPsData] = useState([])
	const [floatingIPs, setFloatingIPs] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedFloatingIP, setSelectedFloatingIP] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [floatingIPDeleteConfirmDialogOpen, setFloatingIPDeleteConfirmDialogOpen] = useState(false);
	const [selectedFloatingIPs, setSelectedFloatingIPs] = useState([])
	const [floatingIPsSortParams, setFloatingIPsSortParams] = useState("")
	
	const [floatingIPFilterQueryParams, setFloatingIPFilterQueryParams] = useState("")
	const [selectedFloatingIPFilter, setSelectedFloatingIPFilter] = useState(floatingIPsFilterMenu[0].value)
	const [selectedFloatingIPFilterValue, setSelectedFloatingIPFilterValue] = useState("")
	const [floatingIPsFilter, setFloatingIPsFilter] = useState([...floatingIPsFilterMenu])

	const [networks, setNetworks] = useState([])
	const [servers, setServers] = useState([])
	const [floatingIPPools,setFloatingIPPools] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const floatingIPsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
	const floatingIPPoolsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.floatingIPPoolsUrl)[0].url)
	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = floatingIPsFilterMenu.find(item => item.value === selectedFloatingIPFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		if (selectedFloatingIPFilter === 'floating_network_id') {
			let networks_filter = []
			networks.forEach(item => networks_filter.push({ keyword: item.name, value: item.id, default: false }))
			return networks_filter
		}

		const filterObject = floatingIPsFilterMenu.find(item => item.value === selectedFloatingIPFilter)
		return filterObject ? filterObject.items : []
	}

	const handleFloatingIPFilteredSearch = () => {
			if (selectedFloatingIPFilter && selectedFloatingIPFilterValue) {
					setFloatingIPFilterQueryParams(`${selectedFloatingIPFilter}=${selectedFloatingIPFilterValue}`)
			} else {
					setFloatingIPFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleFloatingIPsDataFormatting = useCallback(() => {
			const formatted_data = floatingIPsData.map((item) => {
					let new_item = {...item}
					
					const network = networks.filter(n => n.id === item.floating_network_id)
					if (network.length > 0) {
							new_item.floating_network_id = network[0].name
					} else {
							new_item.floating_network_id = "-"
					}
					if (item.port_details) {
							if (item.port_details.device_owner === networkNeutronConstants.computeNovaOwner) {
									const server = servers.filter(srv => srv.id === item.port_details.device_id)
									new_item.port_id = server.length > 0 ? server[0].name : item.port_id
							}
					}
					return new_item
			})

			setFloatingIPs(formatted_data)
	},[
			floatingIPsData,
			networks,
			servers
	])

	const handleFloatingIPFilterReset = () => {
			setSelectedFloatingIPFilter(floatingIPsFilterMenu[0].value)
			setSelectedFloatingIPFilterValue("")
			setFloatingIPFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToNetwork = (network_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			navigate("/networks",{state: {network_id: network_id}})
	}

	const handleNavigateToServer = (server_id) => {
			handleDetailCardClose()
			navigate("/instances",{state: {server_id: server_id}})
	}

	const handleNavigateToRouter = (router_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			navigate("/networks",{state: {router_id: router_id}})
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedFloatingIP(floatingIPsData[index].id)
			setSelectedRow(floatingIPsData[index])
	},[floatingIPsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedFloatingIP(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFloatingIPsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setFloatingIPsSortParams(sort_param)
			handleDataFetch()
	}

	const handleFloatingIPDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === floatingIPUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onFloatingIPDelete = async () => {
			handleFloatingIPDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedFloatingIPs) {
					const resp = await handleFloatingIPDelete(selectedFloatingIPs[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onFloatingIPDeleteConfirm = (f_list) => {
			const selected_f_list = floatingIPsData.filter(f => 
					f_list.includes(f.floating_ip_address))
			setSelectedFloatingIPs([...selected_f_list])
			setFloatingIPDeleteConfirmDialogOpen(true)
	}

	const handleFloatingIPDeleteConfirmDialogClose = () => {
			setFloatingIPDeleteConfirmDialogOpen(false)
	}

	const getFloatingIPsActionsList = () => {
			let floating_ip_actions = []
			let new_action = {}
			new_action["value"] = "floating_ip_delete"
			new_action["action"] = onFloatingIPDeleteConfirm
			new_action["keyword"] = "floatingIPDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			floating_ip_actions.push({...new_action})
			
			return floating_ip_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedFloatingIP(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}?${floatingIPFilterQueryParams}${floatingIPsSortParams}`
							const method = "GET"
							const fip_response = await openstackRequest({url:url, method:method, token, })
							if (fip_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
									setFloatingIPsData(fip_response.data.floatingips)
									if (selectedFloatingIP) {
											const selected_fip = fip_response.data.floatingips.filter(item => item.id === selectedFloatingIP)
											if (selected_fip.length > 0) {
													setSelectedRow(selected_fip[0])
											}
									}
							} else {
								setFloatingIPsData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			floatingIPsUrl, 
			floatingIPFilterQueryParams,
			dataFetchingRequired,
			floatingIPsSortParams,
			selectedFloatingIP,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl, 
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?`
				const method = "GET"
				const servers_response = await openstackRequest({url:url, method:method, token, })
				if (servers_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {						
					setServers(servers_response.data.servers)
				}
					
			})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			serversUrl,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPPoolsUrl}`
				const method = "GET"
				const fip_response = await openstackRequest({url:url, method:method, token, })
				if (fip_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
						setFloatingIPPools(fip_response.data.floatingip_pools)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			floatingIPPoolsUrl, 
			token,
	]);

	useEffect(() => {
		if (floatingIPsData.length > 0) {
			handleFloatingIPsDataFormatting()
		} else {
			setFloatingIPs([])
		}
	},[
		floatingIPsData,
		handleFloatingIPsDataFormatting
	])

	useEffect(() => {
			let networks_filter = []
			if (networks.length > 0) {
					const external_networks = networks.filter(net => net["router:external"])
					networks_filter = external_networks.map(network => {
							return {keyword: network.name, value: network.id, default: false}
					})
			}
			
			let fip_filter_menu = floatingIPsFilterMenu.map(nt => {
					let new_item = {...nt}
					
					if (nt.value === "floating_network_id") {
							new_item.items = [...networks_filter]
					}
					return new_item
			})

			setFloatingIPsFilter(fip_filter_menu)
	},[
			floatingIPsData,
			networks
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const fip_id = location.state ? location.state.floating_ip_id : null
							const fip_index = floatingIPsData.findIndex(v => v.id === fip_id);
							if (fip_index !== -1) {
									setTimeout(() => handleDetailCardOpen(fip_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			floatingIPsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l3-networks')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedFloatingIPFilterValue('')
	}, [selectedFloatingIPFilter])

	const handleActionRun = () => {
		const actionsList = getFloatingIPsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedFloatingIPs([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getFloatingIPsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<FloatingIPAddV20
					handleDataFetch={handleDataFetch}
					networks={networks}
					floatingIPPools={floatingIPPools}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedFloatingIPFilterValue}
							setCurrentValue={e => setSelectedFloatingIPFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedFloatingIPFilterValue}
							setCurrentValue={setSelectedFloatingIPFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							self_item_titles={selectedFloatingIPFilter === 'floating_network_id' ? true : false}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={floatingIPsFilter} 
						currentValue={selectedFloatingIPFilter}
						setCurrentValue={setSelectedFloatingIPFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFloatingIPFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFloatingIPFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box>
						{
							width >= 1200 ? (
								<ServiceDoubleMenu
									serviceMenu={serviceMenu}
									serviceSubmenu={serviceSubmenu}
									onClick={changeMenuActiveTab}
									setCurrentTab={setCurrentTab}
								/>
							) : (
								<ServiceDoubleMenuMobile
									serviceMenu={serviceMenu}
									serviceSubmenu={filteredSubmenu}
									currentTab={currentTab}
									onMenuClick={changeMenuActiveTab}
									onSubmenuClick={setCurrentTab}
								/>
							)
						}
					</Box>

					{
						width < 900 &&
						<CustomPopover
							type='menu'
							horizontalOrigin='right'
						>
							{getActionButtons()}
						</CustomPopover>
					}

					{/* <Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box> */}
				</Stack>
				
				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box>
							<ServiceDoubleSubmenu
								parent='/l3-networks'
								serviceMenu={filteredSubmenu}
								currentTab={currentTab}
								selectedItems={selectedIds.length}
								allItems={floatingIPsData.length}
								onClick={setCurrentTab}
							/>
						</Box>

						<Box>
							{getActionButtons()}
						</Box>
					</Stack>
				}
				
				{
					(width < 900 || menuMode === undefined || !menuMode['/l2-networks']) &&
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
							padding: '0px 20px 10px 20px',
						}}
					>
						{defaultTexts.floatingIPsTabButtonText} ({selectedIds.length}/{floatingIPsData.length})
					</Box>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<FloatingIPsTableV20 
				floatingIPs={floatingIPs}
				setFloatingIPs={setFloatingIPs}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getFloatingIPsActionsList()}
				sortHandler={handleFloatingIPsSorting}
				sortParams={floatingIPsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<FloatingIPDetailV20
				selectedRow={selectedRow}
				handleDataFetch={handleDataFetch}
				handleNavigateToNetwork={handleNavigateToNetwork}
				handleNavigateToServer={handleNavigateToServer}
				handleNavigateToRouter={handleNavigateToRouter}
				handleDelete={onFloatingIPDeleteConfirm}
				networks={networks}
				servers={servers}
				setSelectedRow={setSelectedRow}
				setSelectedFloatingIP={setSelectedFloatingIP}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
					open={floatingIPDeleteConfirmDialogOpen}
					onClose={handleFloatingIPDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.floatingIPDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.floatingIPDeleteConfirmText}: [${selectedFloatingIPs.map(v => v.floating_ip_address).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onFloatingIPDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default FloatingIPsWrapperV20