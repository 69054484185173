import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SiteConnectionSpecsV20 from './siteConnectionSpecsV2.0';
import { Grid }  from '@mui/material';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomDialog from '../../../../../_common/CustomDialog';

const SiteConnectionDetailV20 = (props) => {
  const theme = useTheme()
	const [error, setError] = useState();
	const { selectedRow, setSelectedRow, setSelectedSiteConnection } = props
	const { handleDelete, handleEdit, isCardLoading } = props
  const { ikePolicies, ipsecPolicies, vpnServices, endpointGroups } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const mode = useSelector(state => state.settings.uiMode)
		
	const [firewallRuleSubMenu, setFirewallRuleSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/site-connection-details", is_active: true}
	])

	const [currentTab, setCurrentTab] = useState("/site-connection-details")

	const getFormattedSiteConnectionData = useCallback((data) => {
			let formatted_data = {...data}
			
      const ikePolicy = ikePolicies.filter(item => item.id === data.ikepolicy_id)[0]
      formatted_data.ikepolicy_id = ikePolicy ? ikePolicy.name : data.ikepolicy_id

      const ipsecPolicy = ipsecPolicies.filter(item => item.id === data.ipsecpolicy_id)[0]
      formatted_data.ipsecpolicy_id = ipsecPolicy ? ipsecPolicy.name : data.ipsecpolicy_id

      const vpnService = vpnServices.filter(item => item.id === data.vpnservice_id)[0]
      formatted_data.vpnservice_id = vpnService ? vpnService.name : data.vpnservice_id

      const localEpGroup = endpointGroups.filter(item => item.id === data.local_ep_group_id)[0]
      formatted_data.local_ep_group_id = localEpGroup ? localEpGroup.name : data.local_ep_group_id

      const peerEpGroup = endpointGroups.filter(item => item.id === data.peer_ep_group_id)[0]
      formatted_data.peer_ep_group_id = peerEpGroup ? peerEpGroup.name : data.peer_ep_group_id

			return formatted_data
	},[ikePolicies, ipsecPolicies, vpnServices, endpointGroups])
  
	const handleFirewallRuleDetailTabChange = useCallback((navigation) => {
			let newFirewallRuleSubmenuData = firewallRuleSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setFirewallRuleSubMenu(newFirewallRuleSubmenuData)
			setCurrentTab(navigation)
	},[
			firewallRuleSubMenu
	])

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}			
	}

  const handleConfirmEditDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleEdit([selectedRow.id])
		}
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={firewallRuleSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleFirewallRuleDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedSiteConnection}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/site-connection-details" &&
					<SiteConnectionSpecsV20
						siteConnectionData={getFormattedSiteConnectionData(selectedRow)}
					/>
				}
			</Box>		
			
			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
            paddingRight: '12px',
					}} 
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="flex-end"
					>
							<Grid item>
                {
                  selectedRow !== null && 
                  <IconButton onClick={handleConfirmEditDialogOpen}>
                    <EditIcon color="warning"/>
                  </IconButton>
                }
              </Grid>
							<Grid item>
                {
                  selectedRow !== null && 
                  <IconButton onClick={handleConfirmDeleteDialogOpen}>
                    <DeleteIcon color="error"/>
                  </IconButton>
                }
							</Grid>
					</Grid>
				</Paper>
			}

			{
        error &&
        <CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
			  />
      }
		</>
  )
}

export default SiteConnectionDetailV20