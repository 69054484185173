import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FirewallGroupsWrapperV20 from './fw_groups/firewallGroupsWrapperV2.0';
import FirewallPoliciesWrapperV20 from './fw_policies/firewallPoliciesWrapperV2.0';
import FirewallRulesWrapperV20 from './fw_rules/firewallRulesWrapperV2.0';

const FirewallWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, selectedSubmenu } = props
	
	const getSelectedSubmenu = () => {
		const submenu = ['firewall_groups', 'firewall_policies', 'firewall_rules']
		return submenu.includes(selectedSubmenu) ? selectedSubmenu : submenu[0]
	}

	const [currentTab, setCurrentTab] = useState(getSelectedSubmenu())

	return (
		<Box>
				{currentTab === "firewall_groups" && 
						<FirewallGroupsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "firewall_policies" && 
						<FirewallPoliciesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{currentTab === "firewall_rules" &&
						<FirewallRulesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default FirewallWrapperV20