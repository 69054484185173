import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import BackupsTableV3 from './backupsTableV3';
import { backupsFilterMenu } from '../../../../../_data/openstack/cinder/backups/v3';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { backupsUrl as backupUrlResponses } from '../../../../../_api_responses/openstack/cinder/backups/v3';
import BackupDetailV3 from './backupDetailV3';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import ServiceMenu from '../../../../_common/ServiceMenu';
import BackupAdd from './BackupAdd';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../_common/WindowDimensions'
import CustomPopover from '../../../../_common/CustomPopover'
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.volumeService

const BackupsWrapperV3 = (props) => {
	const theme = useTheme()

	const { width } = useWindowDimensions()

	const { navigate, location, changeMenuActiveTab, serviceMenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const token = useSelector(state => state.profile.x_auth_token)
	const [backupsData, setBackupsData] = useState([])
	const [backups, setBackups] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedBackup, setSelectedBackup] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [backupDeleteConfirmDialogOpen, setBackupDeleteConfirmDialogOpen] = useState(false);
	const [selectedBackups, setSelectedBackups] = useState([])
	const [backupsSortParams, setBackupsSortParams] = useState("")
	
	const [backupFilterQueryParams, setBackupFilterQueryParams] = useState("")

	const [volumes, setVolumes] = useState([])

	const [selectedBackupIds, setSelectedBackupIds] = useState([])
	const [searchBy, setSearchBy] = useState(backupsFilterMenu.length > 0 ? backupsFilterMenu[0].value : '')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const backupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	
	const getSearchType = () => {
		const filterObject = backupsFilterMenu.find(item => item.value === searchBy)
		return filterObject ? filterObject.type : ''
	}
	
	const getSearchSelectItems = () => {
		if (searchBy === 'volume_id') {
			let select_items = []

			volumes.forEach(item => select_items.push({
				value: item.id,
				keyword: item.name + ' (' + item.id + ')',				
			}))

			return select_items
		} else {
			const filterObject = backupsFilterMenu.find(item => item.value === searchBy)
			return filterObject ? filterObject.items : []
		}
	}

	const isValidSelectValue = (value, list) => {
		const findObject = list.find(item => item.value === value)
		return findObject ? true : false
	}

	const handleFilteredSearch = () => {
		if (searchBy && searchKeyword) {
			setBackupFilterQueryParams(`?${searchBy}=${searchKeyword}`)
		} else {
			setBackupFilterQueryParams('')
		}

		handleDataFetch()
	}
	
	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleBackupsDataFormatting = useCallback(() => {
			const formatted_data = backupsData.map((item) => {
					let new_item = {...item}
					new_item.size = `${item.size}GB`
					new_item.bootable = Boolean(item.bootable === "true")
					
					const volume = volumes.filter(v => v.id === item.volume_id)
					if (volume.length > 0) {
							new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
					}
					return new_item
			})
			setBackups(formatted_data)
	},[
			backupsData,
			volumes,
	])

	const handleBackupFilterReset = () => {
		setSearchKeyword('')
		setSearchBy(backupsFilterMenu[0].value)
		setBackupFilterQueryParams("")
		handleDataFetch()
	}

	const handleNavigateToVolume = (volume_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			navigate("/storage", {state: {volume_id: volume_id}})
	}

	const handleDetailCardOpen = (index) => {
		setSelectedBackup(backupsData[index].id)
		setSelectedRow(backupsData[index])
	};

	const handleDetailCardClose = () => {
		setSelectedRow(null)
		setSelectedBackup(null)
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleBackupsSorting = (field,direction) => {
			const sort_param = `sort=${field}:${direction}`
			setBackupsSortParams(sort_param)
			handleDataFetch()
	}

	const handleBackupDelete = async (s_id) => {
			
		const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${s_id}/action`
		const method = "POST"
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				data: {"os-force_delete": {}},
				token,
		})
		
		if (vt_response.status_code === backupUrlResponses.post_async.success_response.status_code) {
				return null
		} else {
				return vt_response.error
		}
			
	};

	const onBackupDelete = async () => {
		handleBackupDeleteConfirmDialogClose()

		let err = []

		for (let s in selectedBackups) {
			const resp = await handleBackupDelete(selectedBackups[s].id)
			if (resp !== null) {
				err = [...err, resp]
			}
		}

		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const onBackupDeleteConfirm = (s_list) => {
			const selected_s_list = backupsData.filter(s => 
					s_list.includes(s.id))
			setSelectedBackups([...selected_s_list])
			setBackupDeleteConfirmDialogOpen(true)
	}

	const handleBackupDeleteConfirmDialogClose = () => {
			setBackupDeleteConfirmDialogOpen(false)
	}

	const getBackupsActionsList = () => {
			let backup_actions = []

			let new_action = {}
			new_action["value"] = "backup_delete"
			new_action["action"] = onBackupDeleteConfirm
			new_action["keyword"] = "backupDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			backup_actions.push({...new_action})

			// new_action = {}
			// new_action["value"] = "backup_status_update"
			// new_action["action"] = onBackupStatusUpdate
			// new_action["keyword"] = "backupStatusUpdateActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// backup_actions.push({...new_action})
			
			return backup_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedBackup(null)
		setSelectedRow(null)
	}, [defaultAdminProject, token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							const firstSymbol = backupFilterQueryParams ? '&&' : '?'
							const sort_params = backupsSortParams ? firstSymbol + backupsSortParams : ''
							
							let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail${backupFilterQueryParams}${sort_params}`
							const method = "GET"

							const backups_response = await volumeCinderRequest({url:url, method:method, token, })

							if (backups_response.status_code === backupUrlResponses.get.success_response.status_code) {
									setBackupsData(backups_response.data.backups)
									if (selectedBackup) {
											const selected_backup = backups_response.data.backups.filter(item => item.id === selectedBackup)
											if (selected_backup.length > 0) {
													setSelectedRow(selected_backup[0])
											}
									}
							} else {
								setBackupsData([])
							}

							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			backupsUrl, 
			backupFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			backupsSortParams,
			selectedBackup,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?`
				const method = "GET"

				const volume_response = await volumeCinderRequest({url:url, method:method, token, })
				if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
						setVolumes(volume_response.data.volumes)
				}
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			volumesUrl,
			token,
	]);

	useEffect(() => {
		if (backupsData.length > 0) {
			handleBackupsDataFormatting()
		} else {
			setBackups([])
		}
	},[
		backupsData,
		handleBackupsDataFormatting
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		if (location.state && location.state.backup_id && backups.length > 0) {
			const findBackup = backups.find(item => item.id === location.state.backup_id)
			setSelectedBackup(findBackup ? findBackup : null)
			setSelectedRow(findBackup ? findBackup : null)
			navigate(location.path, {})
		}
	}, [location, navigate, backups])

	const handleActionRun = () => {
		const selected_backup_list = backupsData.filter(item => selectedBackupIds.includes(item.id))
		setSelectedBackups([...selected_backup_list])
		
		if (currentAction === 'backup_delete') {
			setBackupDeleteConfirmDialogOpen(true)
		}
	}

	const handleDeselectAll = () => {
		setSelectedBackupIds([])
		setSelectedBackups([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedBackupIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getBackupsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<BackupAdd
					handleDataFetch={handleDataFetch}
					volumes={volumes}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={searchKeyword}
							setCurrentValue={e => setSearchKeyword(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={ isValidSelectValue(searchKeyword, getSearchSelectItems()) ? searchKeyword : '' }
							setCurrentValue={setSearchKeyword}
							item_titles={defaultTexts}
							self_item_titles={ searchBy === 'volume_id' ? true : false }
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={backupsFilterMenu} 
						currentValue={searchBy}
						setCurrentValue={setSearchBy}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>

				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleBackupFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getBackupsHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
					<Box>
						<ServiceMenu
							parent='volumes'
							serviceMenu={serviceMenu}
							selectedItems={selectedBackupIds.length}
							allItems={backupsData.length}
							onClick={changeMenuActiveTab}
						/>
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Box
					sx={{
						fontSize: '17px',
						color: theme.palette.primary.main,
						padding: '0px 20px 10px 20px',
					}}
				>
					{defaultTexts.submenuBackups} ({selectedBackupIds.length}/{backupsData.length})
				</Box>

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedBackupIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getBackupsTable = () => {
		return (
			<BackupsTableV3 
				data={backups}
				setData={setBackups}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getBackupsActionsList()}
				sortHandler={handleBackupsSorting}
				sortParams={backupsSortParams}
				setSelectedBackupIds={setSelectedBackupIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getBackupsContent = () => {
		return (
			<>
				{ getBackupsHeader() }
				{ getBackupsTable() }
			</>
		)
	}

	const getBackupDetails = () => {
		return (
			<BackupDetailV3
				selectedRow={selectedRow}
				selectedBackup={backups.filter(s => s.id === selectedRow.id)[0]}
				handleFetchData={handleDataFetch}
				handleNavigateToVolume={handleNavigateToVolume}
				handleDelete={onBackupDeleteConfirm}
				setSelectedRow={setSelectedRow}
				setSelectedBackup={setSelectedBackup}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getBackupsContent()} contentBottom={getBackupDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getBackupsHeader() }

				{ (selectedRow === null && !isLoading) && getBackupsTable() }

				<CustomDialog
					open={backupDeleteConfirmDialogOpen}
					onClose={handleBackupDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.backupDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.backupDeleteConfirmText}: [${selectedBackups.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onBackupDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default BackupsWrapperV3